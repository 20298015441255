@import '~@core/styles/vars';

.Param {
  color: var(--slate);
  display: flex;
  padding: $sm;

  &-input {
    width: 100%;
  }
}
