@import '@core/styles/vars';

.Discussion {
  &-main {
    color: var(--gray30);
    font-weight: var(--font-weight);
    margin: 0;
  }

  &-badge {
    align-items: center;
    background-color: rgba(black, 0.05);
    border-radius: 15px;
    color: var(--gray40);
    display: inline-flex;
    font-size: 11px;
    font-weight: var(--font-weight-bold);
    line-height: 1.6;
    padding: 2px $xs;
    transform: translateX(5px);
    white-space: nowrap;
  }

  &-answered {
    align-items: center;
    color: var(--gray40);
    display: flex;
    font-size: 13px;
    gap: $xs;
    white-space: nowrap;

    :global {
      .icon {
        font-size: 16px;

        &_success {
          color: var(--green);
        }

        &:last-child {
          opacity: 0.5;
        }
      }
    }
  }

  &-footer {
    width: 100%;
  }
}
