.ItemDrop {
  display: none;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  &_active {
    display: block;
  }

  &-before,
  &-after {
    border: 0 solid transparent;
    box-sizing: border-box;
    display: none;
    height: calc(50% + 2px);
    pointer-events: auto;
    position: absolute;
    width: 100%;

    &_active {
      display: block;
    }

    &_over {
      border-color: var(--blue);
    }
  }

  &-before {
    border-top-width: 2px;
    top: -2px;
  }

  &-after {
    border-bottom-width: 2px;
    bottom: -2px;
  }
}
