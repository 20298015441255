@import '~@core/styles/vars';

.LogsTable {
  table-layout: fixed;
  width: 100%;

  &-row {
    &_selected {
      background: rgba($green, 0.08);
    }

    &_featured {
      border-color: rgba($chalk, 0.4);
      border-top: 20px solid;
    }
  }

  thead th,
  tbody td {
    white-space: nowrap;
  }

  thead th {
    color: var(--APILogsTable-header-color);
  }

  tbody td {
    color: var(--APILogsTable-cell-color);
  }

  &:not(.LogsTable_empty) tbody tr {
    cursor: pointer;

    &:hover,
    &:active,
    &:focus {
      &:not(.LogsTable-row_selected) {
        background: var(--Table-stripe-bg);
      }

      .LogsTable-chevron:not(.LogsTable-chevron_selected) {
        opacity: 1;
      }
    }
  }

  &-user {
    color: var(--color-text-minimum);
    display: flex;
    font-size: 11px;
  }

  &-chevron {
    align-items: center;
    color: var(--color-text-minimum);
    display: inline-flex;
    font-size: var(--icon-md);
    justify-content: center;
    opacity: 0.25;

    &_selected {
      color: var(--green);
      opacity: 1;
    }
  }
}
