@import '~@core/styles/vars';

.FancyCard {
  border-radius: 0.3rem;
  font-family: var(--font-brand);
  isolation: isolate;
  overflow: hidden;
  position: relative;

  &-content {
    position: relative;
    z-index: 2;
  }

  &_embossed {
    box-shadow: var(--box-shadow-3);
  }

  &_overflow-visible {
    overflow: visible;
  }

  &_orbs {
    pointer-events: none;
    position: absolute;
    z-index: 1;

    &-cloud {
      // Wait 10s to avoid layout shifting on page load/reload, namely for Chrome.
      animation: rotate 20s infinite linear 10s;
      height: 100%;
      width: 100%;

      > * {
        left: 22%;
        position: absolute;
        top: -18%;

        &:first-child {
          left: -18%;
          top: -18%;
        }

        &:last-child {
          left: 0;
          top: 22%;
        }
      }

      @keyframes rotate {
        from {
          transform: rotateZ(0);
        }

        to {
          transform: rotateZ(360deg);
        }
      }

      @media (prefers-reduced-motion: reduce) {
        animation: none;
      }
    }
  }

  @media (max-width: $ipad-mini-portrait) {
    border-radius: 0 !important;
  }
}
