.Paragraph {
  position: relative;

  :global([data-slate-editor='true']) &_Placeholder::after {
    content: 'Start typing markdown or type / to insert a block';
    opacity: 0.333;
    position: absolute;
    top: 0.125em; // coming from RDMD
  }

  :global([data-slate-editor='true']) li > &_Placeholder::after {
    top: 0;
  }

  &_PlaceholderIcon {
    color: var(--color-text-minimum-icon);
    position: absolute;
    top: 3px;
  }
}
