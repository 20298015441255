.CustomBlocksMenu {
  &-menu {
    overflow: hidden auto;
    width: 250px;

    &-results {
      &-header {
        box-sizing: border-box; // prevents horizontal scrollbar
        display: flex;
        margin: 0;

        &:first-of-type {
          margin-top: 0;
        }
      }

      &-name {
        overflow: hidden;
        padding-right: var(--xs);
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &_empty {
        color: var(--color-text-minimum);
        font-size: 12px;
        font-weight: 600;
        margin: var(--sm);
      }
    }
  }
}
