.Icon {
  $icon-color: var(--icon-color, inherit);
  $icon-size: var(--icon-size, inherit);
  $icon-stroke-width: var(--icon-stroke-width, 2px);

  box-sizing: border-box;
  color: $icon-color;
  font-size: $icon-size;

  &-wrapper {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }

  &-empty,
  &-svg {
    display: inline-block;
    height: 1em;
    width: 1em;
  }

  &-svg {
    :global(.icon-stroke-width) {
      stroke-width: $icon-stroke-width;
    }
  }
}
