@import '@core/styles/mixins/dark-mode';

.MockPlayground {
    $root: &;

    width: 100%;

    // When we display MockPlayground in SuperHub, we need to override the data-color-mode=dark styles manually
    // with theme.scss base values
    &_light-theme {
        --color-text-muted: var(--gray30);
        --color-text-minimum: var(--gray40);
        --color-input-background: var(--white);
        --color-input-border: #e5e5e5;
        --color-input-border-hover: #ccc;
        --color-input-border-active: var(--blue);
        --color-border-default: #{rgba(black, 0.1)}
    }

    &-auth {
        width: 100%;

        button {
            background-color: var(--gray100) !important;
            color: var(--color-text-muted);
            cursor: default;
            font-size: 12px;
            font-weight: var(--font-weight-bold);
            width: fit-content;

            &:hover {
                color: var(--color-text-muted) !important;
            }
        }

        input {
            background-color: var(--gray100) !important;
            color: var(--color-text-muted);
            font-size: 12px;
        }
    }

    &-code-snippet {
        background: var(--gray15);
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: var(--border-radius-lg);
        box-shadow: var(--box-shadow-request, 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 5px rgba(0, 0, 0, 0.02));
        margin-top: var(--sm);
        width: 100%;
    
        &-title {
          color: var(--gray80);
          font-family: var(--font-family);
          font-size: 12px;
          font-weight: var(--font-weight-bold);
          text-transform: uppercase;
        }
    
        &-header,
        &-code,
        &-footer {
          box-sizing: border-box;
          padding: var(--sm);
          width: 100%;
        }
    
        &-header,
        &-code {
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);
          box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
        }
    
        &-code-inner {
          color: #bebcd7;
          font-family: var(--font-family-mono);
          font-size: 12px;
          -webkit-font-smoothing: antialiased;
          font-weight: var(--font-weight-normal);
        }
    
        &-code-line-number {
          color: #bebcd7;
          margin-right: 10px;
          text-align: right;
          width: 20px;
        }
    
        &-copy {
          border: 0;
          color: var(--chalk);
        }
    }

    &-response-tryit {
        background: var(--gray100);
        border: var(--border-width) solid rgba(0, 0, 0, 0.1);
        border-radius: var(--border-radius-lg);
        display: flex;
        flex: 1;
        flex-direction: column;
        font-size: 14px;
        margin-top: 10px;
        width: 100%;
        
        &-header {
            border-bottom: 1px solid rgba(255, 255, 255, 0.05);
            box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
            color: var(--color-text-minimum);
            font-size: 12px;
            font-weight: var(--font-weight-bold);
            overflow: hidden;
            padding: var(--sm);
            text-transform: uppercase;
        }

        &-empty {
            color: var(--color-text-minimum);
            padding: 30px;
            
            span {
                text-align: center;
                width: 100%;
            }

            &-text {
                background: rgba(0, 0, 0, 0.05);
                border: 1px solid var(--color-border-default);
                border-radius: var(--border-radius);
                color: var(--color-text-muted);
                font-family: var(--font-family);
                font-weight: var(--font-weight-bold);
                padding: 1px 3px;
            }
        }
    }

    &-tooltip {
        background-color: var(--gray10);
        border-radius: var(--border-radius-lg);
        box-shadow: var(--box-shadow-menu-light);


        &-eyebrow {
            color: var(--green50);
            margin-bottom: var(--xs);
            width: fit-content;

            &-icon {
                opacity: 0.5;
            }
        }
          

        &-container {
            display: flex;
            flex-direction: column;
            gap: var(--xs);
            padding: var(--xs);
        }

        &-title {
            font-size: 14px;
            font-weight: var(--font-weight-bold);
        }

        &-content {
            font-size: 12px;
            opacity: 0.5;   
        }

        &-progress {
            background: var(--green50);
            border-radius: 2px;
            height: 2px;
            margin-top: var(--sm);
            overflow: hidden;
            width: 100%;
        }

        &-progress-bar {
            animation: progress linear forwards;
            animation-play-state: running;
            background: var(--gray20);
            height: 100%;
            transform-origin: right;
            width: 100%;
        }
    }

    @keyframes progress {
        0% {
            transform: scaleX(1);
        }

        100% {
            transform: scaleX(0);
        }
    }

    &-tooltip-highlight {
        position: relative;
        width: 100%;
        
        &::after {
            animation: glow 2s infinite;
            border: 1px solid var(--green50);
            border-radius: var(--border-radius-lg);
            box-shadow: 0 0 0 2px rgba(var(--green50-rgb), 0.9);
            content: '';
            inset: 5px -7px -7px;
            pointer-events: none;
            position: absolute;
            z-index: -1;
        }
    }

    @keyframes glow {
        0% {
            opacity: 0.9;
        }

        50% {
            opacity: 0.5;
        }

        100% {
            opacity: 0.9;
        }
    }

    &:not(#{$root}_light-theme) &-response-tryit {
        @include dark-mode {
            background: var(--gray15);
        }
    }

    &:not(#{$root}_light-theme) &-auth input,
    &:not(#{$root}_light-theme) &-auth button {
        @include dark-mode {
            background-color: var(--color-input-background) !important;
        }
    }
}

