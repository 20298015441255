.DisableOverlay {
  position: relative;

  &-overlay {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all var(--transition-slow);
    width: 100%;
    z-index: 2;

    &_hidden {
      opacity: 0;
      visibility: hidden;
    }
  }

  &-button {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);
    margin-top: 40px;
    position: sticky;
    top: 40px;

    &:hover {
      background-color: var(--color-bg-page);
    }
  }

  &-content {
    transition: all var(--transition-slow);

    &_disabled {
      opacity: 0.5;
      pointer-events: none;
      user-select: none;
    }
  }
}
