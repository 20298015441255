/* stylelint-disable no-descending-specificity */
@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Input {
  --Input-background: var(--color-input-background);
  --Input-color: var(--color-input-text);
  --Input-border: var(--color-input-border);
  --Input-border-hover: var(--color-input-border-hover);
  --Input-border-active: var(--color-input-border-active);
  --Input-border-focus: var(--color-input-border-focus);
  --Input-disabled-bg: var(--lightGray);
  --Input-disabled-color: var(--minimumGray);
  --Input-placeholder: var(--gray60);
  --Input-height: var(--button-md);

  @include dark-mode {
    --Input-disabled-bg: var(--gray30);
    --Input-disabled-color: var(--gray60);

    color-scheme: light dark;
  }

  background: var(--Input-background);
  border: var(--border-width) solid var(--Input-border);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  color: var(--Input-color);
  font-family: inherit; // this is needed for date types in Chrome
  font-weight: var(--font-weight-normal);
  height: var(--Input-height);
  transition: box-shadow var(--transition-slow) var(--transition-timing);

  &:disabled {
    background: var(--Input-disabled-bg);
    color: var(--Input-disabled-color);
    cursor: not-allowed;
    -webkit-text-fill-color: var(--Input-disabled-color); // fix color in Safari
  }

  &[readonly] {
    color: var(--color-text-minimum);
  }

  &:not(:disabled) {
    &:hover {
      border-color: var(--Input-border-hover);
    }

    &:active,
    &:focus {
      border-color: var(--Input-border-active);
      box-shadow: 0 0 0 3px var(--Input-border-focus);
      outline: none;
    }
  }

  .FormGroup_error &:not(:disabled),
  .FormGroup_error &:not(:disabled):focus,
  .FormGroup_error &:not(:disabled):hover,
  .FormGroup_error &:not(:disabled):active,
  &_dirty:invalid,
  // Quick fix to prevent over-eager error styling for the invalid state
  // Should be fully addressed in https://linear.app/readme-io/issue/RM-5125/refactor-input-to-use-inline-error-messaging
  &_touched:not(:placeholder-shown):invalid {
    border-color: $red;

    &:focus {
      border-color: $red;
      box-shadow: 0 0 0 3px rgba($red, 0.4);
    }
  }

  &::placeholder {
    color: var(--Input-placeholder);
  }

  &[type='search'] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(147, 158, 174)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-search'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: $icon-md;

    // Remove extra padding in Safari—looks like they left room for an icon, but no icon appears
    &::-webkit-search-decoration {
      appearance: none;
    }

    &.Input_sm {
      background-position: $xs center;
      padding-left: $xs + $icon-md + $xs;
    }

    &.Input_md {
      background-position: $sm center;
      padding-left: $sm + $icon-md + $xs;
    }

    &.Input_lg {
      background-position: 15px center;
      background-size: $icon-lg;
      padding-left: $md + $icon-md + $xs;
    }
  }

  &_xs {
    --Input-height: calc(var(--button-xs) + var(--xs)); // this needs to be a touch bigger than the min button size

    font-size: 13px;
    padding-left: var(--xs);
    padding-right: var(--xs);
  }

  &_sm {
    --Input-height: var(--button-sm);

    font-size: 14px;
    padding-left: $sm;
    padding-right: $sm;
  }

  &_md {
    --Input-height: var(--button-md);

    font-size: 16px;
    padding-left: 15px;
    padding-right: 15px;
  }

  &_lg {
    --Input-height: var(--button-lg);

    font-size: 18px;
    padding-left: $md;
    padding-right: $md;
  }

  &_minimal {
    --Input-background: var(--ivory);
    --Input-bg-focus-minimal: white;
    --Input-border-active: #{$blue};

    @include dark-mode {
      --Input-background: var(--gray20);
      --Input-bg-focus-minimal: var(--gray10);
    }

    border-color: transparent;

    .ThemeContext_dark & {
      --Input-background: var(--slate);
      --Input-bg-focus-minimal: var(--graphite);
    }

    &:hover {
      border-color: transparent;
    }

    &:active {
      border-color: var(--Input-border-active);
    }

    &:focus {
      background: var(--Input-bg-focus-minimal);

      &:hover {
        border-color: var(--Input-border-active);
      }
    }
  }
}

.InputParent {
  --InputParent-height: var(--button-md);
  --Input-prefix-width: var(--InputParent-height);
  --Input-suffix-width: var(--InputParent-height);

  font-size: 16px;
  position: relative;
  width: 100%;

  .Input {
    width: 100%;
  }

  &_sm {
    --InputParent-height: var(--button-sm);

    font-size: 14px;
  }

  &_lg {
    --InputParent-height: var(--button-lg);

    font-size: 18px;
  }

  &-prefix,
  &-suffix {
    align-items: center;
    box-sizing: border-box;
    color: var(--color-text-muted);
    display: flex;
    height: 100%;
    justify-content: center;
    position: absolute;
    top: 0;
  }

  &-prefix {
    left: 0;
    width: var(--Input-prefix-width);
  }

  &-suffix {
    right: 0;
    width: var(--Input-suffix-width);
  }

  &-clearBtn {
    background: var(--color-input-background);
    cursor: pointer;
    padding: 0;

    &:not(:disabled):hover {
      background: var(--color-input-background);
    }

    i {
      padding: 0 5px;
    }

    &:active {
      border-color: transparent !important;
      box-shadow: unset !important;
    }
  }

  &_isPrefixed .Input {
    padding-left: var(--Input-prefix-width);
  }

  &_isSuffixed .Input {
    padding-right: var(--Input-suffix-width);
  }

  // When both a suffix and clear button appear, both elements are placed in the suffix slot
  &_isClearable.InputParent_isSuffixed {
    .Input {
      padding-right: calc(var(--InputParent-height) + 1.5em);
    }

    .InputParent-clearBtn {
      right: var(--InputParent-height);
    }

    .InputParent-suffix {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;

      // We're using a flex row that starts at row-end, so we need to calculate the right-hand empty space
      // of the suffix slot as if it had only one centered child, and apply it as padding so everything lines up
      padding-right: calc(calc(var(--InputParent-height) / 2) - 0.5em);
      width: calc(var(--InputParent-height) + 1em);
    }
  }
}
