@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Table {
  --Table-border-color: var(--color-border-default);
  --Table-loading-minheight: 120px;
  --Table-spinner-size: var(--icon-md);
  --Table-spinner-color: var(--color-text-minimum);
  --Table-spinner-bg: var(--color-bg-page);
  --Table-stripe-bg: #{rgba(black, 0.02)};
  --Table-th-color: var(--color-text-minimum);

  @include dark-mode {
    --Table-stripe-bg: #{rgba(white, 0.05)};
  }

  border-collapse: separate;
  border-spacing: 0;
  margin: auto;
  position: relative;
  text-align: left;

  th,
  td {
    font-weight: initial;
    height: $button-sm;
  }

  thead {
    &.Table-thead_sticky {
      position: sticky;
      top: -1px;
    }

    th {
      color: var(--Table-th-color);
      font-family: var(--font-family);
      font-size: 12px;
      padding: 0.2em 0.6em;
      text-transform: uppercase;
    }

    tr {
      th {
        box-shadow: inset 0 -1px 0 var(--Table-border-color); // prefer shadow in case we want to use position: sticky

        :not(.Box) > & {
          padding-top: 0;
        }
      }

      &:first-child th,
      &:only-child th {
        font-weight: var(--font-weight-bold);
      }
    }
  }

  tbody:not(.Table_spinner) {
    td {
      box-sizing: content-box;
      font-size: 15px;
      padding: 0.4em 0.6em;
    }
  }

  &_bordered tbody tr + tr td {
    border-top: 1px solid var(--Table-border-color);
  }

  &_striped tbody tr:nth-child(odd) {
    background: var(--Table-stripe-bg);
  }

  &_condensed tbody td {
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    padding: 0 $sm;
  }

  &_dataGrid tbody td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
    word-wrap: break-word;
  }

  &-row {
    &_separator > td {
      background: var(--color-border-default);
      height: 4px !important;
    }

    &_hover > td {
      cursor: pointer;
    }
  }

  &_loading {
    min-height: var(--Table-loading-minheight);
  }

  &-spinner {
    align-items: center;
    animation: fadein $transition-fast;
    animation-delay: 0.1s; // reduces flicker when load times are negligable
    animation-fill-mode: forwards;
    background-color: var(--Table-spinner-bg);
    color: var(--Table-spinner-color);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @keyframes fadein {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    &-element {
      font-size: var(--Table-spinner-size);
    }
  }
}
