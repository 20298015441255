@import '@core/styles/vars';

// match hub styles
h1.Heading {
  color: var(--color-text-default);
  font-size: 26.25px;
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 26.25px;
}

h2.Heading {
  color: var(--color-text-default);
  font-size: 22.5px;
  margin-bottom: 21px;
  margin-top: 10px;
}

h3.Heading {
  color: var(--color-text-default);
  font-size: 18.75px;
  margin-bottom: 17.5px;
  margin-top: 10px;
}

h4.Heading {
  color: var(--color-text-default);
  font-size: 15px;
  margin-bottom: 14px;
  margin-top: 10px;
}

h5.Heading {
  color: var(--color-text-default);
  font-size: 13.5px;
  margin-bottom: 13.5px;
  margin-top: 15px;
}

h6.Heading {
  font-size: 13.5px;
  margin-bottom: 13.5px;
  margin-top: 15px;
}
