@import '@core/styles/mixins/dark-mode';

.Alert {
  @include dark-mode {
    border: 1px solid var(--color-border-default);

    &_info {
      background: rgba(var(--blue50-rgb), 0.2);
      color: rgba(var(--blue60-rgb), 1);
    }

    &_warning {
      background: rgba(var(--yellow50-rgb), 0.2);
      color: rgba(var(--yellow60-rgb), 1);
    }

    &_danger {
      background: rgba(var(--red50-rgb), 0.2);
      color: rgba(var(--red60-rgb), 1);
    }

    &_success {
      background: rgba(var(--green50-rgb), 0.2);
      color: rgba(var(--green60-rgb), 1);
    }

    &_neutral {
      background: rgba(var(--gray50-rgb), 0.2);
      color: var(--gray60-rgb, 1);
    }

    &-title {
      font-weight: var(--font-weight-bold);
    }
  }

  border-radius: var(--border-radius);
  box-sizing: border-box;
  font-size: 13px;
  margin-bottom: var(--sm);
  overflow: auto;
  padding: var(--sm);
  width: 100%;

  &_info {
    background: var(--blue100);
    color: rgba(var(--blue20-rgb), 1);
  }

  &_warning {
    background: var(--yellow100);
    color: rgba(var(--yellow20-rgb), 1);
  }

  &_danger {
    background: rgba(var(--red-rgb), 0.1);
    color: var(--red40);
  }

  &_success {
    background: var(--green100);
    color: rgba(var(--green20-rgb), 1);
  }

  &_neutral {
      background: var(--gray100);
      color: var(--gray20-rgb, 1);
    }

  &-title {
    font-weight: var(--font-weight-bold);
  }

  &-icon {
    &_info {
      color: var(--blue40);
    }

    &_warning {
      color: var(--yellow40);
    }

    &_danger {
      color: var(--red40);
    }

    &_success {
      color: var(--green40);
    }

    &-title {
      font-weight: var(--font-weight-bold);
    }
  }

  &-subtitle {
    opacity: 0.8;
  }
}
