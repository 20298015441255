.UserPicker {
  $block: &;

  background: var(--off-white);

  &-menu {
    &-main {
      overflow: auto;
    }

    &-header {
      font-size: 1rem;
    }

    &-footer {
      background: var(--off-white);
      bottom: 0;
      position: fixed;

      &-divider {
        margin-top: 0;
      }

      &-content {
        color: var(--gray40);
        font-size: 1rem;
        font-weight: var(--font-weight-normal);
        margin: 0.8rem 0;
        padding: 0 1.2rem;
        text-align: left;
      }
    }
  }

  &-input {
    flex: 1 1 auto;
    margin: 0.5rem 0.75rem;
    text-indent: 1.4rem;

    &-wrapper {
      background: var(--off-white);
      position: sticky;
      top: 0;
    }

    &-icon {
      color: var(--gray60);
      font-weight: var(--font-weight-bold);
      left: 1.5rem;
      position: absolute;
    }
  }

  &-row {
    display: grid;
    grid-template-columns: auto auto;
  }

  &-check {
    color: var(--gray60);
    font-size: 1rem;
    opacity: 0;

    &_checked {
      opacity: 1;
    }
  }

  &-user {
    all: unset;
    cursor: pointer;
    padding: var(--sm);

    &-info {
      overflow: hidden;
    }

    &-name {
      color: var(--gray40);
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    &-email {
      color: var(--gray40);
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  &-empty-state {
    color: var(--gray40);
    height: calc(40px + var(--md));
  }

  &_with-modal {
    max-width: stretch;

    #{$block} {
      &-header {
        font-size: 14px;
      }

      &-check {
        font-size: 14px;
      }

      &-user-name {
        font-size: 14px;
      }

      &-user-email {
        font-size: 14px;
      }

      &-emptyState {
        color: var(--gray40);
        font-size: 14px;
      }
    }
  }
}
