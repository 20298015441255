@import '~@core/styles/vars';

.APIRequestGraph {
  --APIRequestsGraph-height: 90px;

  border-bottom: 1px solid var(--color-border-default);
  box-sizing: border-box;
  color: var(--color-text-minimum);
  font-size: 10px;
  font-weight: var(--font-weight-normal);
  margin: 0 0 1rem;
  margin-left: auto !important;
  padding: 0 0 1rem;

  &-graph {
    margin: var(--md) 0;
  }

  &-loading, &-empty {
    height: var(--APIRequestsGraph-height);
  }

  &-empty {
    padding: 0;

    &-ctn {
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      height: stretch;
      margin-bottom: 1rem;
      padding: var(--md);
      width: 100%;
    }
  }
}
