@import '~@core/styles/vars.compat';

.Delta {
  color: currentcolor;
  font-family: var(--font-family);
  font-weight: var(--font-weight);

  &-number {
    padding: 0 0.05em;
  }

  &_positive {
    color: var(--green);
  }

  &_negative {
    color: var(--red);
  }
}
