@import '~@core/styles/vars';

.SAMLMapItem {
  background: white;
  border: 1px solid rgba(black, 0.1);
  border-radius: var(--border-radius);
  margin-bottom: 10px;
  width: auto;

  + .SAMLMapItem {
    border-top: 1px solid rgba(black, 0.1);
  }

  &-header {
    color: var(--gray30);
    font-size: 14px;
    font-weight: var(--font-weight);
    padding: $sm;
  }

  &-expanded {
    border-top: 1px solid rgba(black, 0.1);
    padding: $sm;
  }

  &-label {
    display: block;

    + .SAMLMapItem-label {
      margin-top: $md;
    }

    &-text {
      color: var(--gray30);
      font-size: 14px;
      font-weight: var(--font-weight);
      margin-bottom: $sm;
    }

    &-input,
    &-select {
      width: 100%;
    }
  }

  &-projects {
    background: var(--white);
    border: 1px solid rgba(black, 0.1);
    border-radius: var(--border-radius-lg);
    padding-top: $sm;

    :global(.Table) {
      width: 100%;
    }

    :global(tr + tr td) {
      border-top: 1px solid rgba(black, 0.1);
    }

    :global(th) {
      text-align: center;
    }

    :global(td),
    :global(th) {
      &:last-child {
        padding-right: $sm;
      }
    }
  }

  &-delete {
    margin-top: $sm;
  }
}
