@import '~@core/styles/vars.compat';

.Table {
  border: 1px solid var(--table-edges, #dfe2e5);
  margin: 0 !important;

  &-Add {
    border-radius: 0;
    border: 1px solid var(--table-edges, #dfe2e5);
    border-top: none;
    justify-content: left;
    width: 100%;

    & span {
      color: var(--gray0);
      opacity: 0.333;
    }

    &:hover {
      border: 1px solid var(--table-edges, #dfe2e5);
      border-top: none;
    }
  }
}
