@import '@core/styles/vars';

.APIKeyDropdown {
  &-details {
    color: var(--gray60);
    font-size: 11px;

    :global(.DateLine) {
      color: inherit;
    }
  }

  &-keys {
    max-height: calc(40px * 3);
    overflow-y: auto;
  }

  &-link {
    text-decoration: none !important;

    :global(.IconWrapper) {
      font-size: 20px;
    }
  }
}
