@import '~@core/styles/vars';

.APISectionHeader {
  align-items: center;
  display: flex;
  height: $lg;
  justify-content: space-between;

  &:not(:first-child) {
    margin-top: var(--sm);
  }

  &-heading {
    color: var(--color-text-muted);
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }
}
