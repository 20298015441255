.InfiniteLoaderList {
  height: 100%;
  min-height: 0;
  width: 100%;

  &-content_loading {
    display: grid;
    height: var(--InfiniteLoaderList-intial-height);
  }

  &-item {
    list-style: none;
    padding: 0;

    &_loading {
      display: grid;
      height: 100%;
      place-items: center;
    }
  }
}

.PaddedInnerElement {
  list-style: none;
  margin: 0;
  padding: 0;
}
