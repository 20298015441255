.PageNavCreateNew {
  font-size: 12px;
  text-transform: uppercase;
  transition: color var(--transition-fast) var(--transition-timing);

  &#{&}:not(:disabled) {
    &:hover {
      border-color: var(--color-input-border-hover);
    }

    &:active,
    &:focus {
      border-color: var(--color-input-border-active);
    }
  }

}
