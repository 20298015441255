@import '~@core/styles/vars.compat';

.VariableMenu {
  margin-left: 6px;
  margin-top: 0.5em;
  padding: 0;

  &-Container {
    border-radius: 6px !important;
    padding: 0 !important;
  }

  &-Menu {
    border: 0;
    box-shadow: none;

    &:global(.Menu) {
      max-height: calc(30px * 5);
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;
      width: 285px;
    }
  }
}

.VariableMenuItem {
  padding: 0 $xs !important;
  user-select: none;

  &-Container {
    display: flex;
    justify-content: space-between;

    &-name {
      margin: $xs;
      overflow: hidden;
      padding-right: $sm;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 130px;
    }

    &-type {
      color: var(--gray50);
      font-weight: 600;
      margin: $xs;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    background: rgba($blue, 0.15) !important;
  }
}
