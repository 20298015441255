@import '~@core/styles/mixins/dark-mode';

.ObfuscatedAPIKey {
  display: flex;

  &-text {
    font-family: var(--font-family-mono);
    font-size: 13px;
    word-break: break-all;
  }

  &-button {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    font-size: var(--icon-md);
    justify-content: center;
    padding: 0;
  }

  &-button,
  &-clipboard {
    @include dark-mode {
      &:active,
      &:not(:disabled):hover,
      &:not(:disabled):focus-visible {
        color: var(--color-text-default);
      }
    }

    color: var(--color-text-minimum);
    flex: 0 0 auto;
    height: 20px;

    &:active,
    &:not(:disabled):hover,
    &:not(:disabled):focus-visible {
      color: var(--color-text-default);
    }
  }

  &-rotate {
    color: var(--yellow);
    flex: 0 0 auto;
    font-size: 16px;
    margin-left: 0;
    padding-top: 3px;
  }
}
