@import '@core/styles/mixins/dark-mode';

.ProgressBar {
  --ProgressBar-background: var(--gray90);
  --ProgressBar-value: var(--blue);
  --ProgressBar-value-percentage-over: var(--red);

  @include dark-mode {
    --ProgressBar-background: var(--gray20);
  }

  border-radius: var(--sm);
  display: flex;
  height: var(--sm);
  overflow: clip;
  position: relative;
  width: 100%;

  [data-color-mode='dark'] &_light,
  [data-color-mode='system'] &_light {
    --ProgressBar-background: var(--gray90);
  }

  &_dark {
    --ProgressBar-background: var(--gray20);
  }


  &::-webkit-progress-bar {
    background-color: var(--ProgressBar-background);
  }

  &::-webkit-progress-value {
    background-color: var(--ProgressBar-value);
  }

  &::-moz-progress-bar {
    background-color: var(--ProgressBar-value);
  }

  &_percentageOver {
    &::-webkit-progress-value {
      background-color: var(--ProgressBar-value-percentage-over);
    }
  
    &::-moz-progress-bar {
      background-color: var(--ProgressBar-value-percentage-over);
    }
  }
}
