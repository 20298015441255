.UserBubble {
  $name-size: var(--UserBubble-name-size, 16px);
  $email-size: var(--UserBubble-email-size, 12px);

  background-color: var(--gray90);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: content-box;
  color: rgba(0, 0, 0, 0.3);

  &-info {
    padding: var(--xs) var(--sm);
    text-align: center;

    &-name {
      font-size: $name-size;
      font-weight: bold;
      margin: 0;
      margin-bottom: var(--xs);
    }

    &-email {
      font-size: $email-size;
      margin: 0;
      opacity: 0.6;
    }
  }
}
