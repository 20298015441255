@import '~@core/styles/vars';

.APILogsTable {
  background: var(--APILogsTable-bg);
  border: var(--border-width) solid var(--color-border-default);
  border-radius: var(--border-radius-lg);
  overflow: hidden;
  position: relative;

  &_hidden {
    visibility: hidden;
  }

  &_empty {
    color: var(--color-text-minimum);
    cursor: default;
    font-size: 14px;
    text-align: center;
  }

  :global {
    .Table-spinner {
      border-radius: var(--border-radius-lg);
    }
  }

  // override font styles
  tbody:not(.Table_spinner) td {
    font-size: 13px;
    font-weight: var(--font-weight-normal);
  }

  &-pagecount {
    font-size: 12px;
    left: 0;
    pointer-events: none;

    // This ensures it’s properly centered in the footer
    position: absolute;
    text-align: center;
    width: 100%;
  }

  &-footer {
    align-items: center;
    border-top: 1px solid var(--color-border-default);
    color: var(--APILogsTable-footer-color, $shale);
    display: flex;
    height: 34px;
    justify-content: space-between;
    padding: 0 $sm;
    position: relative;

    &-buttonGroup {
      align-items: center;
      display: flex;
    }

    &-button {
      align-items: center;
      background: none;
      border: var(--border-width) solid var(--color-input-border);
      border-radius: var(--border-radius);
      color: inherit;
      display: inline-flex;
      font-family: var(--font-family);
      font-size: 12px;
      font-weight: var(--font-weight-bold);
      height: $md;
      padding: 0 $xs;
      text-transform: uppercase;
      transition: box-shadow var(--transition-fast) var(--transition-timing);

      + .APILogsTable-footer-button {
        margin-left: $xs;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      &:hover {
        border-color: var(--color-input-border-hover);
        cursor: pointer;
      }

      &:active,
      &:focus {
        border-color: var(--color-input-border-active);
        box-shadow: 0 0 0 3px var(--color-input-border-focus);
        outline: 0;
      }

      &-icon {
        font-size: $icon-md;
      }
    }
  }

  &_loading {
    background: inherit;
    padding: var(--xs);
    position: absolute;
    right: var(--xs);
    top: var(--xs);
  }
}
