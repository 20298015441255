@import '@core/styles/vars';

.BasicHeaderReveal {
  --toggle-background: #{rgba(black, 0.05)};
  --toggle-background-hover: #{rgba(black, 0.08)};
  --toggle-border: #{rgba(black, 0.1)};
  --toggle-border-hover: #{rgba(black, 0.15)};

  &_dark {
    --toggle-background: #{rgba(white, 0.05)};
    --toggle-background-hover: #{rgba(white, 0.08)};
    --toggle-border: #{rgba(white, 0.1)};
    --toggle-border-hover: #{rgba(white, 0.15)};
  }

  &-toggle {
    background-color: var(--toggle-background);
    border: 1px solid var(--toggle-border);
    border-radius: var(--border-radius);
    color: currentcolor;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0.05em 0.25em;
    position: relative;
    transition: background-color var(--transition-fast), border var(--transition-fast);

    &:hover {
      background-color: var(--toggle-background-hover);
      border: 1px solid var(--toggle-border-hover);
    }
  }

  &-link {
    color: #fff;
    text-decoration: underline;

    &:hover{
      color: $gray85;
      text-decoration: none;
    }
  }
}
