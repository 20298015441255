/* stylelint-disable-next-line scss/at-import-partial-extension */
@import '~@core/styles/vars.compat';

.SlashMenu {
  margin-top: 0.5em;
  padding: 0;

  // Reset box padding
  &-Container {
    padding: 0;
  }

  &-Menu {
    max-height: 324px; // 27px * 12 to show ~11 items

    &:global(.Menu) {
      max-width: initial;
      min-width: 250px;
      overflow-y: auto;
    }
  }
}

.SlashMenuItem {
  user-select: none;

  &_component {
    font-family: var(--font-family-mono);
  }

  &-shortcut {
    color: var(--color-text-minimum);
    float: right;
    font-size: 10px;
    line-height: 20px;
  }
}

.SlashMenuTooltip {
  &:global(.Menu) {
    display: none;
    left: calc(100% + 10px);
    max-width: 260px;
    min-width: 160px;
    position: absolute;
    top: 0;
  }

  &-img {
    border-radius: var(--border-radius);
    height: 140px;
    width: 140px;
  }

  &-kbd {
    font-family: var(--font-family-mono);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    letter-spacing: 0.5px;
  }
}

.SlashMenu:hover .SlashMenuTooltip {
  display: inherit;
}
