.ParentDrop {
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  // This overlay is layered above any "before/after" drop targets in order to
  // expose a parent drop target.
  &-center-target {
    height: 40%;
    position: absolute;
    top: 30%;
    width: 100%;
    z-index: 2;
  }

  &_active {
    display: block;
  }

  &_over {
    background: rgba(var(--blue-rgb), 0.1);
  }

  &_toggleable#{&}_over {
    @keyframes visual-action {
      70% {
        background: rgba(0, 0, 0, 0.05);
      }

      73% {
        background: rgba(var(--blue-rgb), 0.2);
      }

      75% {
        background: rgba(var(--blue-rgb), 0.1);
      }
    }

    animation: visual-action linear;
  }
}
