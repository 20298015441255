@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.CodeTabs {
  background-color: var(--color-skeleton);
  display: flex;
  overflow-x: auto;

  :global(.CodeSnippet) {
    margin-bottom: 0;
  }

  &_container {
    /* match hub tab states */
    --CodeTabs-bg-hover: #{rgba(black, 0.1)};
    --CodeTabs-bg-active: #f6f8fa;

    @include dark-mode {
      --CodeTabs-bg-hover: #{rgba(white, 0.075)};
      --CodeTabs-bg-active: var(--gray15);
    }

    background: var(--CodeTabs-bg-active);
    border-radius: 3px; /* match hub tab states */
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    min-height: 0;
    overflow: hidden;
  }

  &-active {
    &:global(.Button) {
      background-color: var(--CodeTabs-bg-active);
    }
  }

  &-button {
    &:global(.Button) {
      border-radius: 0;
      font-size: 12px;
      font-weight: 400;
      min-width: fit-content;
      user-select: none;

      &:hover {
        background-color: var(--CodeTabs-bg-hover);
      }

      &:active,
      &:focus-within {
        background-color: var(--CodeTabs-bg-active);
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  &-add {
    margin: var(--xs);
  }

  &-dragging {
    opacity: 0;
  }
}
