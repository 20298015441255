@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.FormGroup {
  --FormGroup-label-size: 14px;
  --FormGroup-label-height: var(--button-md);
  --FormGroup-label-gap: var(--md);
  --FormGroup-description-gap: 7px;
  --FormGroup-link-color: var(--blue50-rgb);

  display: flex;

  + .FormGroup {
    margin-top: $md;
  }

  &_sm {
    --FormGroup-label-size: 13px;
    --FormGroup-label-height: var(--button-sm);
    --FormGroup-label-gap: var(--sm);
  }

  &_lg {
    --FormGroup-label-size: 15px;
    --FormGroup-label-height: var(--button-lg);
  }

  &-label {
    align-items: center;
    color: var(--color-text-default);
    display: flex;
    font-size: var(--FormGroup-label-size);
    font-weight: var(--font-weight);
    height: var(--FormGroup-label-height);
    justify-content: flex-end;
    margin-right: var(--FormGroup-label-gap);

    a {
      border-bottom: 1px solid currentColor;
      color: currentColor;
      text-decoration: none;

      &:hover {
        border-bottom-color: rgba(var(--FormGroup-link-color), 0.75);
      }
    }
  }

  &-inputs {
    flex: 1 1 auto;
  }

  &-input {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: min-content;
  }

  &-messages {
    display: flex;
    flex-direction: column;
    gap: var(--xs);
    margin-top: var(--FormGroup-description-gap);
  }

  &-description,
  &-error,
  &-warning {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: var(--font-weight);
    gap: var(--xs);
    line-height: 1.4;

    p {
      margin: 0;
    }

    a {
      border-bottom: 1px solid currentColor;
      color: currentColor;
      display: inline-flex;
      text-decoration: none;
      transition: border-color var(--transition-fast);

      &:hover {
        border-bottom-color: rgba(var(--FormGroup-link-color), 0.75);
      }
    }
  }

  &-description{
    color: var(--color-text-muted);
  }

  &-error {
    --FormGroup-link-color: var(--red50-rgb);

    color: var(--red);

    &-tooltip {
      background-color: var(--red);
      color: white;
      font-size: 12px;
      padding: 0;

      > .tippy-content {
        font-size: 11px;
      }
    }
  }

  &-warning {
    @include dark-mode {
      --FormGroup-link-color: var(--yellow50-rgb);

      color: var(--yellow);
    }

    align-items: baseline;
    color: var(--color-text-minimum);
    display: flex;
    flex-direction: row;
    gap: var(--sm);
    margin-top: -4px;
    padding-right: var(--xs);

    &-icon {
      color: var(--yellow);
      font-size: 16px;
      transform: translateY(4px);
    }
  }

  &_vertical {
    --FormGroup-label-gap: 7px;

    flex-direction: column;

    &.FormGroup_sm {
      --FormGroup-label-gap: var(--xs);
    }

    > .FormGroup-label {
      height: auto;
      justify-content: space-between;
      margin-bottom: var(--FormGroup-label-gap);
      margin-right: unset;
    }
  }
}
