.InfoBert {
  color: var(--color-text-minimum);
  display: grid;
  font-size: 12px;
  gap: var(--sm);
  grid-template-columns: auto 1fr;
  line-height: 1.4;
  padding: var(--sm);

  &-avatar {
    height: 25px;
    position: relative;
    width: 25px;

    img {
      border-radius: var(--border-radius);
      width: 100%;
    }
  }

  &-icon {
    align-items: center;
    background-color: var(--color-text-minimum);
    border: 1px solid #fff;
    border-radius: 100%;
    bottom: -5px;
    color: var(--white);
    display: flex;
    font-size: 14px;
    height: 14px;
    justify-content: center;
    position: absolute;
    right: -5px;
    width: 14px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    gap: var(--md);

    > * {
      margin-bottom: 0;
      margin-top: 0;
      position: relative;
    }

    > * + *::before {
      background-color: var(--color-border-default);
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      top: calc(var(--sm) * -1);
      width: 100%;
    }

    a {
      color: var(--color-text-minimum);
      text-decoration: underline;
    }
  }
}
