@import '~@core/styles/vars.compat';

.SpeechBubble {
  background-color: var(--ivory);
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0 -1px 0 rgba(black, 0.1);
  color: var(--graphite);
  display: inline-block;
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: var(--font-weight-bold);
  height: auto;
  min-height: 1em;
  min-width: 1em;
  overflow-wrap: anywhere;
  padding: $xs $sm;
  position: relative;
  text-align: left;
  width: fit-content;
  word-break: break-word; // deprecated but needed for safari

  &-wrapper {
    position: relative;
    width: 100%;
  }

  &:focus {
    outline: 0;
  }

  // Seen/Mark as Seen button
  &-seen {
    color: var(--minimumGray, var(--minimum-gray));
    font-size: 12px;
    height: auto;
    margin-top: $xs;
    padding: 0;
    position: relative;
    z-index: 1; // Fixes an issue where the outline is shown behind the tail

    &:hover,
    &:active,
    &:focus {
      background: none;
      box-shadow: none;

      .SpeechBubble-main-icon {
        opacity: 1;
      }
    }
  }

  &-tippy {
    margin-bottom: 0;
  }

  &-tail {
    // Adds a visual "tail" to the div connoting a speach bubble
    bottom: -2px;
    fill: var(--ivory);
    height: 18px;
    left: -10px;
    position: absolute;
    width: 23px;

    &_outgoing {
      fill: var(--blue);
      left: auto;
      right: -10px;
      transform: rotateY(180deg);
    }
  }

  &-main {
    &-icon {
      opacity: 0.5;

      &_seen {
        color: var(--green);
        opacity: 1;
      }
    }

    &_expanded {
      bottom: 0;
      left: 0;
      position: absolute;
      z-index: 2;

      .SpeechBubble-tail {
        display: none;
      }
    }

    &_outgoing {
      background: var(--blue);
      color: var(--white);
    }
  }

  &-subtext {
    font-size: 10px;
    margin: 0.25em 0;
    opacity: 0.8;
  }

  &_collapsed {
    color: var(--graphite);
    max-width: fit-content;
    width: 100%;

    &-content {
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      z-index: 1;
    }
  }

  &-Avatar {
    border: 1px solid var(--chalk);
    text-transform: uppercase;
  }
}
