@import '@core/styles/vars';

.SignIn {
  color: $shale;
  position: relative;

  img {
    margin-top: 30px;
    max-width: 60%;
  }

  &-error {
    color: $red !important;
  }

  &-container {
    border: $border-width solid #eee;
    border-radius: var(--border-radius);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-top: $md;
    padding: $lg;
  }

  &-form {
    // forgive me for I have sinned
    width: 100% !important;

    &-group {
      margin-top: 15px !important;

      &-info {
        background: none;
        border: 0;
        padding: 0;
      }
    }

    label:global(.FormGroup-label) {
      color: $slate;
      font-size: 16px;
      font-weight: 500;
      justify-content: start !important;
      margin-bottom: 3px;

      i {
        margin-left: 5px;
      }
    }

    input {
      height: $button-md;
    }

    // Only show validation error styles onBlur
    // TODO: potentially refactor all inputs to have this behavior with a debounce
    &#{&} input:not(:placeholder-shown):invalid:focus {
      border-color: $blue;
      box-shadow: 0 0 0 3px rgba($blue, 0.25);
    }

    &#{&} input:not(:placeholder-shown):invalid {
      border-color: $red;
      box-shadow: 0 0 0 3px rgba($red, 0.25);
    }

    button[type='submit'] {
      background-color: var(--color-login-link);
      color: var(--color-login-link-text);
      margin-top: 10px;
      width: 100%;

      &:hover {
        background-color: var(--color-login-link-darken-10);
        color: var(--color-link-text);
      }

      &:disabled {
        background-color: var(--color-login-link-primary-alpha-50);
      }

      &:focus {
        box-shadow: 0 0 0 3px var(--color-link-primary-alpha-25);
      }
    }
  }

  &-preview {
    color: var(--minimumGray, var(--minimum-gray));
    font-weight: var(--font-weight);
    left: -25px;
    position: absolute;
    top: -25px;
  }

  &-preview-overlay {
    background-color: transparent;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &-readme {
    font-size: 14px;
    font-weight: 500;
  }

  &-link {
    margin-top: 20px;
  }

  p {
    font-weight: 500;
    text-align: center;

    a {
      color: var(--color-text-minimum);

      &:focus {
        box-shadow: 0 0 0 3px var(--color-link-primary-alpha-25);
      }
    }
  }
}
