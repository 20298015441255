.AuditLog {
  cursor: default;
  padding: var(--xs) var(--sm);
  width: auto;

  &-info-wrapper {
    min-width: 300px;
  }

  &-info {
    flex: 0 0 auto;
    font-size: 12px;
  }

  &-icon {
    color: var(--gray60);

    &_create {
      color: var(--green);
    }

    &_edit {
      color: var(--yellow);
    }

    &_delete {
      color: var(--red);
    }
  }

  &-title {
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    overflow: hidden;
    padding-left: var(--xs);
    text-overflow: ellipsis;
    white-space: nowrap;

    &_link {
      color: currentcolor;
      text-decoration: none;

      &:hover,
      &:focus-visible {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &:not(:last-of-type) {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  }

  &-authors {
    *:not(:first-child) {
      margin-left: -7px;
    }
  }

  &-user-bubble {
    --UserBubble-name-size: 14px;
  }
}
