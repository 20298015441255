@import '@core/styles/vars';

.Suggestion {
  &-main {
    color: var(--gray30);
    font-weight: var(--font-weight);
    margin: 0;
  }

  &-title {
    color: var(--blue);
    font-size: 12px;
    font-weight: var(--font-weight);
    margin: 0;
  }

  &-description {
    color: var(--gray30);
    margin: $xs 0 0;
  }

  &-footer {
    color: var(--gray40);
    width: 100%;

    :global {
      .icon {
        color: var(--gray60);
        font-size: 16px;
      }
    }
  }
}
