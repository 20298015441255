@import '@core/styles/mixins/dark-mode';

.Segment {
  --Segment-background: #{rgba(black, 0.05)};
  --Segment-border: transparent;
  --Segment-border-radius: var(--border-radius);
  --Segment-height: 40px;
  --Segment-font-size: 16px;
  --Segment-highlight-background: white;
  --Segment-highlight-border: var(--color-border-default);
  --Segment-underline-width: 3px;
  --Segment-x-padding: calc(var(--Segment-height) / 3);

  // Set from JS inline styles
  --Segment-gap: 0;
  --Segment-highlight-left: 0;
  --Segment-highlight-width: 0;

  @include dark-mode {
    --Segment-background: var(--gray10);
    --Segment-highlight-background: var(--gray20);
    --Segment-highlight-border: transparent;
  }

  $root: &;

  background-color: var(--Segment-background);
  border-radius: var(--Segment-border-radius);
  box-shadow: 0 0 0 1px var(--Segment-border) inset;
  box-sizing: border-box;
  display: inline-flex;
  gap: var(--Segment-gap);
  height: var(--Segment-height);
  position: relative;

  &_primary {
    --Segment-background: var(--color-bg-page);
    --Segment-border: var(--color-border-default);
    --Segment-highlight-background: var(--color-primary);
    --Segment-highlight-border: var(--color-primary);

    @include dark-mode {
      --Segment-border: transparent;
      --Segment-highlight-background: var(--color-primary);
      --Segment-highlight-border: var(--color-primary);
    }
  }

  &_circular {
    --Segment-border-radius: calc(var(--Segment-height) / 2);
  }

  &_fullWidth {
    display: flex;
    width: 100%;
  }

  &_ghost {
    --Segment-border: transparent;
    --Segment-highlight-background: #{rgba(black, 0.05)};
    --Segment-highlight-border: transparent;

    @include dark-mode {
      --Segment-highlight-background: #{rgba(white, 0.15)};
      --Segment-highlight-border: var(--color-border-default);
    }

    background: transparent;

    &#{$root}_primary {
      --Segment-border: transparent;
      --Segment-highlight-background: rgba(var(--blue-rgb), 0.10);
      --Segment-highlight-border: transparent;

      @include dark-mode {
        --Segment-highlight-background: rgba(var(--blue-rgb), 0.20);
        --Segment-highlight-border: rgba(var(--blue-rgb), 0.50);
      }
    }
  }

  &_underline {
    --Segment-border: transparent;
    --Segment-highlight-background: transparent;
    --Segment-highlight-border: rgba(0, 0, 0, 0.33);

    @include dark-mode {
      --Segment-highlight-border: var(--white);
    }

    background: transparent;

    &#{$root}_primary {
      --Segment-highlight-border: var(--blue);

      @include dark-mode {
        --Segment-highlight-border: var(--blue);
      }
    }
  }

  &_xs {
    --Segment-height: 24px;
    --Segment-font-size: 13px;
  }

  &_sm {
    --Segment-height: 30px;
    --Segment-font-size: 14px;
  }

  &_lg {
    --Segment-height: 50px;
    --Segment-font-size: 18px;
  }

  &Item {
    align-items: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: var(--Segment-border-radius);
    box-sizing: border-box;
    color: var(--color-text-default);
    display: grid;
    font-size: var(--Segment-font-size);
    font-weight: var(--font-weight-bold);
    grid-template-columns: 1fr auto;
    height: var(--Segment-height);
    justify-content: center;
    line-height: 1.4;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: color var(--transition-fast);
    white-space: nowrap;
    z-index: 2; // Ensure the button is over the active highlight
    
    &:not(#{$root}Item_active) {
      color: var(--color-text-minimum);
      cursor: pointer;

      &:not(:disabled):hover,
      &:not(:disabled)[aria-expanded="true"] {
        color: var(--color-text-default);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }

    &:has(#{$root}Item-triggerElement:focus-visible) {
      border-color: var(--color-primary);
      box-shadow: 0 0 0 3px var(--color-focus-ring);
    }

    &:global(.Dropdown-toggle) {
      cursor: pointer;
    }

    &-unset {
      all: unset;
    }

    // triggerElement styles need to come after &-unset to override the unset styles
    &-triggerElement {
      align-items: center;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      gap: var(--xs);
      height: 100%;
      justify-content: center;
      padding: 0 var(--Segment-x-padding);

      &:hover {
        color: inherit;
      }

      &:has(+ #{$root}Item-afterSlot) {
        padding-right: var(--xs);
      }
    }

    &-afterSlot {
      padding-right: var(--Segment-x-padding);
    }

    #{$root}_underline & {
      background: transparent;
      border-bottom: var(--Segment-underline-width) solid transparent;
      height: calc(var(--Segment-height) + var(--Segment-underline-width));
      padding-bottom: var(--Segment-underline-width);

      &:not(#{$root}Item_active) {
        color: var(--color-text-default);

        &:hover {
          color: var(--color-text-muted);
        }
      }
    }

    // Apply the active highlight directly to the button if the component is not animating the highlight
    // This will always be the case on the first SSR paint before the app has mounted
    #{$root}:not(#{$root}_animateHighlight) &_active {
      background-color: var(--Segment-highlight-background);
      border: 1px solid var(--Segment-highlight-border);
    }

    #{$root}_underline:not(#{$root}_animateHighlight) &_active {
      border-bottom-color: var(--Segment-highlight-border);
    }

    #{$root}_primary:not(#{$root}_underline) &_active {
      color: white;
    }

    #{$root}_primary#{$root}_ghost &_active {
      color: var(--blue);
    }

    #{$root}_fullWidth & {
      flex: 1;
    }

    &-chevron {
      opacity: 0.75;
      transition: opacity var(--transition-fast);
    }

    #{$root}:not(:disabled):not(#{$root}Item_active):hover &-chevron {
      opacity: 1;
    }

    :global(.IconWrapper:first-child) {
      opacity: 0.75;
    }
  }

  // After the component mounts, the --Segment-highlight-width and --Segment-highlight-left
  // variables are set via JS to animate the active state, so we apply the highlight with an animated pseudo element
  &_animateHighlight::after {
    background-color: var(--Segment-highlight-background);
    border: 1px solid var(--Segment-highlight-border);
    border-radius: var(--Segment-border-radius);
    box-sizing: border-box;
    content: '';
    height: var(--Segment-height);
    position: absolute;
    top: 0;
    transform: translateX(var(--Segment-highlight-left));
    transition: transform var(--transition-fast), width var(--transition-fast), background-color var(--transition-fast);
    width: var(--Segment-highlight-width);
    z-index: 1; // Ensure the active highlight is under the button
  }

  &_animateHighlight#{$root}_underline::after {
    background: transparent;
    border: 0;
    border-bottom: var(--Segment-underline-width) solid var(--Segment-highlight-border);
    border-radius: 0;
    height: calc(var(--Segment-height) + var(--Segment-underline-width));
  }
}
