@import '~@core/styles/vars';

$basic: $graphite;
$cnnct: darken($blue, 10%);
$delete: darken($red, 20%);
$deprecated: $gray60;
$descr: $graphite;
$error: darken($red, 20%);
$fn: #cc258f;
$get: darken($green, 10%);
$head: $slate;
$link: #06758c;
$optns: darken($yellow, 15%);
$patch: #df7d03;
$post: darken($blue, 10%);
$put: darken($purple, 10%);
$rspns: $graphite;
$rqbdy: $graphite;
$trace: $minimum-gray;
$wbhk: darken($blue, 10%);

$colorNames: basic cnnct delete deprecated descr error fn head get link optns patch post put rqbdy rspns trace wbhk;
$colorCodes: $basic, $cnnct, $delete, $deprecated, $descr, $error, $fn, $head, $get, $link, $optns, $patch, $post, $put,
  $rqbdy, $rspns, $trace, $wbhk;

.APIMethod {
  border-radius: var(--border-radius-lg);
  box-shadow: var(--box-shadow-pill);
  box-sizing: border-box;
  color: #fff;
  display: inline-flex;
  font-family: var(--font-family);
  font-size: 8px;
  font-weight: var(--font-weight-bold);
  height: $icon-md;
  justify-content: center;
  line-height: $icon-md;
  padding-left: $xs;
  padding-right: $xs;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;

  &_fixedWidth {
    padding-left: 0;
    padding-right: 0;
    width: 40px;
  }

  @for $i from 1 to 19 {
    &_#{nth($colorNames, $i)} {
      background: nth($colorCodes, $i);
      text-shadow:
        1px 1px 0 darken(nth($colorCodes, $i), 3%),
        0 1px 0 darken(nth($colorCodes, $i), 3%),
        1px 0 0 darken(nth($colorCodes, $i), 3%);
    }
  }
}
