@import '~@core/styles/vars.compat';

.ErrorBoundary {
  margin-top: $md;
  text-align: center;

  h1 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: $md;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }

  img {
    height: 200px;
  }
}
