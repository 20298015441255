@import '~@core/styles/mixins/dark-mode';

@mixin grid($col: 10) {
  display: flex;
  @for $i from 1 through (100 / $col) {
    &-#{$i*10} {
      width: $i * 10%;
    }
  }
}

@mixin toc {
  .content-toc {
    & {
      position: sticky;
      top: 0;
      max-height: calc(100vh - var(--SuperHub-top-nav-height, 0px));
      overflow: auto;
      display: block;
      float: none !important;
      font-size: 13px;
    }
    .tocHeader {
      padding-left: 2px;
      margin-bottom: 8px;
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 700;
      color: var(--color-text-minimum);
      &:hover {
        background: transparent;
        color: #000;
      }
      .icon {
        width: 23px;
        font-size: 16px;
        display: inline-block;
        color: var(--color-text-minimum-icon);
        position: relative;
        top: 2px;
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }
    .toc-list {
      > li:not(.toc-children) :first-child {
        position: sticky;
        top: 0;
        z-index: 2;
        background: var(--color-bg-page);
        padding-left: 0;
        pointer-events: none;
        > i {
          &:first-child {
            text-align: center;
          }
        }
      }
    }
    li {
      > span {
        padding: 4px 16px;
        display: block;
      }
    }
    ul ul ul li {
      padding-left: 10px;
    }
    li.toc-H1 {
      a {
        padding-left: 16px;
      }
    }
    li.toc-H2 {
      a {
        padding-left: 24px;
      }
    }
    li.toc-H3 {
      a {
        padding-left: 32px;
      }
    }
    li.toc-H4 {
      a {
        padding-left: 40px;
      }
    }
    li.toc-H5 {
      a {
        padding-left: 48px;
      }
    }
    li.toc-H6 {
      a {
        padding-left: 56px;
      }
    }
    a {
      color: var(--color-text-minimum);
      font-size: 13px;
      text-decoration: none;
      border-radius: 2px;
      padding: 4px 5px 4px 18px;
      display: block;
      &:hover {
        color: var(--color-text-minimum-hover);
      }
    }
    a.active {
      color: #555;
      strong {
        color: #222;
      }
    }
    @media (max-width: 1025px) {
      position: absolute;
      opacity: 0;
      pointer-events: none;
    }
  }
}

@mixin updated-at {
  .UpdatedAt {
    padding-top: $md;
    p {
      font-weight: 500;
      font-size: 13px;
    }
  }
}

@mixin rm-btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.428571429;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  user-select: none;
  &.btn-default {
    background-color: var(--color-input-background);
    border: 1px solid var(--color-input-border);
    color: var(--color-input-text);
  }
  &.active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.13);
  }
  &.btn-primary {
    color: white;
    background-color: #1f93ff;
  }
  &.btn-primary:hover {
    background-color: #005fb8;
  }
}

@mixin filepicker {
  .filepicker {
    .fp {
      border: 1px solid #ccc;
      border-radius: 5px;
      background-color: #fff;
      max-width: 300px;
      line-height: 1em;
    }
    a.fp {
      background: -webkit-linear-gradient(#fff, #f3f3f3);
      background: -moz-linear-gradient(#fff, #f3f3f3);
      background: -o-linear-gradient(#fff, #f3f3f3);
      background: -ms-linear-gradient(#fff, #f3f3f3);
      background: linear-gradient(#fff, #f3f3f3);
      display: block;
      &:hover {
        border: 1px solid #bbb;
        background: -webkit-linear-gradient(#fff, #eee);
        background: -moz-linear-gradient(#fff, #eee);
        background: -o-linear-gradient(#fff, #eee);
        background: -ms-linear-gradient(#fff, #eee);
        background: linear-gradient(#fff, #eee);
      }
      &:active {
        border: 1px solid #bbb;
        background: -webkit-linear-gradient(#eee, #fff);
        background: -moz-linear-gradient(#eee, #fff);
        background: -o-linear-gradient(#eee, #fff);
        background: -ms-linear-gradient(#eee, #fff);
        background: linear-gradient(#eee, #fff);
      }
    }
    > * {
      vertical-align: middle;
    }
    .imgbox {
      padding: 12px;
      min-width: 60px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      min-height: 51px;
      text-align: center;
      .fa-refresh {
        display: none;
      }
      .fa-cloud-upload {
        width: 100%;
        height: 100%;
        font-size: 30px;
        text-align: center;
        display: block;
        padding-top: 10px;
        position: absolute;
        top: 1px;
        left: 0;
        color: #555;
        z-index: 5;
        &:hover {
          color: #000;
        }
      }
      .icon-upload-cloud {
        width: 100%;
        height: 100%;
        font-size: 30px;
        text-align: center;
        display: block;
        padding-top: 10px;
        position: absolute;
        top: 1px;
        left: 0;
        color: #555;
        z-index: 5;
        &:hover {
          color: #000;
        }
      }
      img {
        max-height: 25px;
        max-width: 69px;
      }
    }
    .imgbox.hasImage {
      .fa-cloud-upload {
        opacity: 0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        filter: alpha(opacity=0);
        -webkit-transition: opacity 0.3s ease-out;
        -moz-transition: opacity 0.3s ease-out;
        -o-transition: opacity 0.3s ease-out;
        -ms-transition: opacity 0.3s ease-out;
        transition: opacity 0.3s ease-out;
      }
      &:hover {
        img {
          opacity: 0.2;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=20)';
          filter: alpha(opacity=20);
        }
        .fa-cloud-upload {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
      }
    }
    .loading {
      .icon-upload-cloud {
        opacity: 0 !important;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)' !important;
        filter: alpha(opacity=0) !important;
        pointer-events: none;
      }
      img {
        opacity: 0 !important;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)' !important;
        filter: alpha(opacity=0) !important;
        pointer-events: none;
        opacity: 0 !important;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)' !important;
        filter: alpha(opacity=0) !important;
        pointer-events: none;
      }
      .fa-cloud-upload {
        opacity: 0 !important;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)' !important;
        filter: alpha(opacity=0) !important;
        pointer-events: none;
      }
      .fa-refresh {
        -webkit-animation: spin 2s linear infinite;
        -moz-animation: spin 2s linear infinite;
        -o-animation: spin 2s linear infinite;
        -ms-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
        width: 100%;
        height: 100%;
        font-size: 30px;
        text-align: center;
        display: block;
        padding-top: 10px;
        position: absolute;
        top: 0;
        left: 0;
        color: #888;
        z-index: 5;
      }
    }
    .info {
      font-size: 13px;
      display: inline-block;
      vertical-align: middle;
      span {
        padding: 0 4px;
      }
      .options {
        font-size: 11px;
        color: #ccc;
        opacity: 0.5;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
        filter: alpha(opacity=50);
        a {
          color: #aaa;
          &:hover {
            color: #018ef5;
          }
        }
        a.remove {
          &:hover {
            color: #c00;
          }
        }
      }
      strong {
        display: block;
        overflow: hidden;
        font-weight: normal;
        width: 128px;
        white-space: nowrap;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
    }
    .info.placeholder {
      color: #888;
    }
    &:hover {
      .info {
        .options {
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        strong {
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -o-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
        }
      }
    }
    .has-error & {
      .fp {
        border: 1px solid #b94a48;
      }
      .imgbox {
        .fa-cloud-upload {
          color: #b94a48;
        }
        .icon-upload-cloud {
          color: #b94a48;
        }
      }
      .info {
        span {
          color: #b94a48;
        }
      }
    }
  }
}

@mixin magictext {
  @keyframes shake {
    0%,
    100% {
      transform: translate3d(0, 0, 0);
    }
    30% {
      transform: translate3d(-30px, 0, 0);
    }
    60% {
      transform: translate3d(-15px, 0, 0);
    }
    40%,
    80% {
      transform: translate3d(5px, 0, 0);
    }
  }
  .magictextarea {
    position: relative;
    padding-right: 200px;
    min-height: 500px;
    .block.section {
      > div {
        > div {
          padding: 15px 0;
        }
      }
    }
    .block.section.type-textarea {
      > div {
        > div {
          padding-bottom: 0;
        }
      }
      + {
        .type-textarea {
          > div {
            > div {
              padding-top: 0;
            }
          }
        }
      }
    }
    .block.section.type-api-header {
      > div {
        > div {
          padding-top: 10px;
        }
      }
    }
    .blocks-parent {
      position: relative;
      > div {
        margin-bottom: 0em;
        padding-left: 74px;
      }
    }
    .dragging {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .droppable {
        height: 5px;
        width: 100%;
        position: absolute;
        .line {
          border-top: 2px solid #555;
          width: 100%;
          position: absolute;
          &:before {
            width: 0;
            height: 0;
            content: ' ';
            display: block;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 10px solid #555;
            top: -8px;
            position: absolute;
            left: -7px;
            z-index: 200;
          }
        }
      }
      .droppable.ui-state-active {
        .line {
          border-top: 2px solid #248fb2;
          &:before {
            border-left-color: #248fb2;
          }
        }
      }
    }
    .magictextarea-click {
      height: 200px;
      display: block;
      cursor: text;
    }
    &.condensed {
      dragbox {
        display: none;
      }
    }
    &.form-control {
      height: auto;
      padding-right: 12px;
    }
    &.magic-form-control {
      .blocks-parent {
        border: 1px solid #ccc;
        padding: 20px 60px 20px 0;
        border-radius: 5px;
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) inset;
      }
      .magictextarea-click {
        display: none;
      }
    }
    &.noPaddingBelow {
      min-height: 0;
      .magictextarea-click {
        height: 50px;
      }
    }
    &.content {
      dragbox {
        width: 154px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1001;
        .ui-sortable-helper {
          transform: rotate(-10deg);
          box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
        }
        .ui-sortable-placeholder {
          background-color: var(--gray90);
          border: 0;

          @include dark-mode {
            background-color: var(--gray15);
          }
        }
        &:hover {
          .show-extra-link {
            opacity: 1;
            -ms-filter: none;
            filter: none;
          }
        }
        .show-extra {
          ul {
            li.extra {
              display: inline-block;
            }
          }
        }
        ul {
          li.extra {
            display: none;
          }
          margin: 0;
          padding: 0;
          list-style: none;
          padding: 0;
          font-size: 0;
          li {
            background-color: var(--gray100);
            border-radius: 5px;
            display: inline-block;
            font-size: 12px;
            height: 69px;
            margin: 5px 0 0 5px;
            vertical-align: top;
            width: 74px;
            z-index: 100;

            @include dark-mode {
              background-color: var(--gray20);
            }

            &:nth-child(2n + 1) {
              margin: 5px 0 0 0;
            }
            a {
              display: block;
              width: 100%;
              height: 100%;
              border-radius: 3px;
              border: 1px solid var(--color-input-border);
              background-color: var(--color-input-background);
              cursor: move;
              color: var(--color-text-minimum);
              text-align: center;
              font-size: 10px;
              width: 74px;
              height: 69px;
              .fa {
                font-size: 30px;
                padding: 12px 0 3px;
              }
              .icon {
                padding-top: 12px;
                font-size: 25px;
                display: inline-block;
                padding-bottom: 8px;
              }
              span {
                white-space: nowrap;
                display: block;
              }
              &:hover {
                border: 1px solid #018ef5;
                color: #018ef5;
              }
            }
            a.disabled {
              color: #ddd;
              cursor: default;
              &:hover {
                color: #bbb;
                border-color: #ccc;
              }
            }
            a.shake {
              animation: shake 0.4s ease-out;
            }
            a.ui-draggable-dragging {
              box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
              z-index: 10000;
              margin-left: -2px;
              margin-top: -2px;
              border: 1px solid #018ef5;
              color: #018ef5;
            }
          }
          li.ui-draggable-parent {
            z-index: 1000;
            a {
              color: #eee;
              border-color: #eee;
            }
          }
        }
        .show-extra-link {
          display: block;
          text-align: center;
          color: #ccc;
          margin-top: 9px;
          font-size: 11px;
          opacity: 0.8;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=80)';
          filter: alpha(opacity=80);
          &:hover {
            color: #018ef5;
            text-decoration: none;
          }
        }
      }
    }
  }
  .magic-templates {
    margin-top: -180px;
    text-align: center;
    padding: 25px 10px 15px;
    background-color: #333;
    border-radius: 3px;
    color: #ddd;
    position: relative;
    margin-bottom: 50px;
    .fa-times {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #aaa;
      padding: 4px 5px;
      border-radius: 5px;
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
    &:after {
      bottom: 100%;
      left: 35px;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(0, 0, 0, 0);
      border-bottom-color: #333;
      border-width: 10px;
    }
    strong {
      color: #fff;
    }
    table {
      margin-top: 15px;
      width: 100%;
      td {
        width: 20%;
      }
      a {
        margin: 5px;
        display: block;
        text-align: center;
        padding: 13px 0;
        border-radius: 5px;
        &:hover {
          background-color: rgba(0, 0, 0, 0.2);
          .fa {
            color: #fff;
          }
          span {
            color: #fff;
          }
        }
        .fa {
          display: block;
          font-size: 30px;
          color: #777;
          padding-bottom: 10px;
        }
        span {
          color: #eee;
          font-size: 12px;
        }
      }
    }
    .api-tutorial {
      text-align: left;
      ol {
        padding-left: 20px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  }
  .blocks-parent {
    &.is-dragging {
      .block.section {
        opacity: 0.5;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
        filter: alpha(opacity=50);
      }
      .block.section.ui-sortable-helper {
        opacity: 1;
        -ms-filter: none;
        filter: none;
      }
    }
    .ui-sortable-placeholder {
      height: 40px;
      border: 2px dashed var(--color-input-border-hover);
      display: block;
      border-radius: 5px;
      margin: 0 0 0 74px;
      list-style: none;
    }
    .block-edit-callout {
      border-left: 4px solid #5bc0de;
      background-color: #f4f8fa;
      padding: 15px;

      @include dark-mode {
        background: rgba(var(--blue-rgb), 0.05);
      }

      input {
        color: #5bc0de;
        border: 0 none;
        background-color: transparent;
        font-weight: bold;
        margin: 10px 0;
        width: 100%;
        &:focus {
          outline: 0;
        }
      }

      textarea {
        color: var(--color-input-text);
      }

      .block-callout-icons {
        .fa {
          color: rgba(0, 0, 0, 0.3);
          padding-right: 5px;
          opacity: 0.5;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
          filter: alpha(opacity=50);
        }
        .fa.on {
          color: #5bc0de;
          opacity: 1;
          -ms-filter: none;
          filter: none;
        }
        .fa.fa-exclamation-circle.on {
          color: #f0ad4e;
        }
        .fa.fa-exclamation-triangle.on {
          color: #d9534f;
        }
        .fa.fa-check-square.on {
          color: #50af51;
        }
      }
    }
    .block-edit-callout.type-warning {
      background-color: #fcf8f2;
      border-color: #f0ad4e;

      @include dark-mode {
        background: rgba(var(--yellow-rgb), 0.05);
      }

      input {
        color: #f0ad4e;
      }
    }
    .block-edit-callout.type-danger {
      background-color: #fdf7f7;
      border-color: #d9534f;

      @include dark-mode {
        background: rgba(var(--red-rgb), 0.05);
      }

      input {
        color: #d9534f;
      }
    }
    .block-edit-callout.type-success {
      background-color: #f3f8f3;
      border-color: #50af51;

      @include dark-mode {
        background: rgba(var(--green-rgb), 0.05);
      }

      input {
        color: #50af51;
      }
    }

    .block-edit-parameters {
      position: relative;
      .table-control {
        display: none;
        width: 50px;
        height: 20px;
        left: -50px;
        text-align: right;
        position: absolute;
        padding-top: 4px;
        padding-right: 7px;
        a {
          color: #ccc;
          &:hover {
            color: #018ef5;
          }
        }
      }
      &:hover {
        .table-control {
          display: block;
        }
      }
      table {
        background-color: var(--color-bg-page);
        border-collapse: collapse;
        width: 100%;
      }
      .table-overflow {
        overflow: auto;
        overflow-x: auto;
        overflow-y: hidden;
        border: 1px solid var(--color-input-border);
      }
      .size {
        background-color: var(--gray100);
        border-left: 1px solid var(--color-input-border);
        border-right: 1px solid var(--color-input-border);
        border-top: 1px solid var(--color-input-border);
        padding: 5px;
        text-align: right;

        @include dark-mode {
          background-color: var(--gray15);
        }

        .btn-group {
          display: inline-block;
        }

        input {
          background: var(--color-input-background);
          border: 1px solid var(--color-input-border);
          border-radius: var(--border-radius);
          color: var(--color-input-text);
          width: 50px;

          &:hover {
            border-color: var(--color-input-border-hover);
          }

          &:active,
          &:focus {
            border-color: var(--color-input-border-active);
            box-shadow: 0 0 0 3px var(--color-input-border-focus);
            outline: 0;
          }

          @include dark-mode {
            color-scheme: light dark;
          }
        }
      }
      th {
        background-color: var(--gray90);
        border-left: 1px solid var(--color-input-border);
        padding: 5px;

        @include dark-mode {
          background-color: var(--gray20);
        }

        &:first-child {
          border-left: 0 none;
        }

        input {
          border: 0 none;
          background-color: transparent;
          color: var(--color-input-text);
          font-weight: bold;

          &:focus {
            outline: 0;
          }
        }
      }
      td {
        border-left: 1px solid var(--color-input-border);
        border-top: 1px solid var(--color-input-border);
        padding: 5px;
        vertical-align: top;

        &:first-child {
          border-left: 0;
        }
      }
    }
    .block-edit-api-header {
      border-bottom: 1px solid var(--color-border-default);
      padding-bottom: 1em;
      padding-top: 0;
      .picker-offset {
        position: absolute;
        left: 73px;
        z-index: 1000;
      }
      input.api-header {
        background-color: transparent;
        border: 0;
        color: var(--color-text-default);
        font-size: 18px;
        font-weight: bold;
        outline: 0;
        padding: 0 0 6px;
        width: 100%;
      }
    }
    .block-edit-image {
      margin-bottom: 30px;
      figure {
        position: relative;
        img {
          max-height: 300px;
          max-width: 100%;
          margin: 0 auto 10px;
          display: block;
        }
      }

      .well {
        /* replicating some bootstrap */
        .row {
          display: flex;
        }

        .col-sm-6 {
          flex: 1 1 50%;
        }

        /* mega-broken button, so we’ll just hide the icons */
        .imgbox {
          display: none;
        }
      }

      .image-size {
        position: absolute;
        top: 10px;
        right: 10px;
        .btn.btn-default {
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
        }
        .dropdown-menu {
          hr {
            margin-top: 10px;
            margin-bottom: 10px;
          }
          strong {
            padding: 0 10px 5px;
            display: block;
          }
          label {
            padding-left: 20px;
            color: #888;
            &:hover {
              cursor: pointer;
              color: #000;
            }
          }
          label.nohover {
            cursor: default;
            color: #888;
          }
        }
      }
    }
  }
  .type-textarea {
    .outline {
      border: 1px solid var(--color-input-border);
      bottom: 12px;
      left: 0;
      margin-top: 4px;
      margin-left: -1.5%;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 5px;
      width: 103%;
      -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
      filter: alpha(opacity=0);
    }
    textarea {
      &:focus {
        + {
          div {
            + {
              .outline {
                opacity: 1;
                -ms-filter: none;
                filter: none;
              }
            }
          }
        }
      }
    }
  }
  .toolbar {
    background-color: #111;
    color: #fff;
    border-radius: 2px;
    font-size: 0;
    display: inline-block;
    position: absolute;
    top: -38px;
    opacity: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    filter: alpha(opacity=0);
    width: 160px;
    text-align: center;
    pointer-events: none;
    z-index: 10000;
    transition: transform 0.3s ease-out;
    transform: translateY(10px);
    background: #47525d;
    a {
      font-size: 14px;
      color: #ccc !important;
      padding: 7px 9px;
      cursor: pointer;
      &:hover {
        color: #fff !important;
        background-color: #444;
      }
    }
    &.on {
      opacity: 1;
      -ms-filter: none;
      filter: none;
      pointer-events: auto;
      transform: translateY(0);
    }
  }
  .block.section {
    position: relative;
    .marker {
      border-top: 1px solid #fa533d;
      width: 100%;
      position: absolute;
      left: 0;
    }
    .well {
      background-color: var(--gray100);
      border: 1px solid var(--color-border-default);
      border-radius: 4px;
      margin-bottom: 20px;
      min-height: 20px;
      padding: 19px; /* lol who does this */

      @include dark-mode {
        background-color: var(--gray15);
      }

      .form-control {
        background: var(--color-input-background);
        border: 1px solid var(--color-input-border) !important; /* needed to override suggested edit focus */
        border-radius: var(--border-radius);
        color: var(--color-input-text);

        &:hover {
          border-color: var(--color-input-border-hover) !important;
        }

        &:active,
        &:focus {
          border-color: var(--color-input-border-active) !important;
          box-shadow: 0 0 0 3px var(--color-input-border-focus);
          outline: 0;
        }
      }
    }
    .block-options {
      position: absolute;
      top: 13px;
      left: 0;
      width: 60px;
      font-size: 0;
      text-align: right;

      .tooltip {
        white-space: nowrap;
      }

      > .block-option {
        background-color: var(--gray80);
        display: none;
        color: var(--white);
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        padding: 2px;
        text-align: center;
        vertical-align: top;
        width: 20px;

        @include dark-mode {
          background-color: var(--gray15);
        }

        &:hover {
          background-color: var(--gray70);

          @include dark-mode {
            background-color: var(--gray20);
          }
        }
      }
      > .block-option.fa-times {
        &:hover {
          background-color: var(--red);
        }
      }
      > .block-option.fa-arrows {
        font-size: 12px;
        cursor: move;
      }
      > .block-option.moveToSidebar {
        padding: 3px 0px 0 0px;
        border-radius: 2px;
      }
      > .block-option.fa-sign-out {
        padding-left: 2px;
      }
      a.moveToSidebar {
        span {
          transition: 0.3s ease-out;
        }
      }
      a.moveToSidebar.is_sidebar {
        display: inline-block;
        background-color: #6bbd5b;
      }
    }
    &:hover {
      .block-options {
        > * {
          display: inline-block;
          &:first-child {
            border-radius: 2px 0 0 2px;
          }
          &:last-child {
            border-radius: 0 2px 2px 0;
            &:first-child {
              border-radius: 2px;
            }
          }
        }
      }
    }
    &.ui-sortable-helper {
      .block-options {
        > * {
          display: inline-block;
          &:first-child {
            border-radius: 2px 0 0 2px;
          }
          &:last-child {
            border-radius: 0 2px 2px 0;
            &:first-child {
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .sliver {
    width: 100%;
    background-color: #fcfcfc;
    border: 0 none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  textarea.expand {
    box-sizing: content-box;
    overflow: hidden;
    resize: none;
    width: 100%;
    padding: 0 0 0 5px;
    margin-left: -5px;
    border: 0 none;
    background-color: transparent;
    outline: 0 none;
    position: relative;
    border-left: 0 none;
    font-size: 14px;
    line-height: 1.5em;
    color: var(--color-input-text);
    &.raw {
      background-color: var(--color-bg-page);
      border: 1px solid var(--color-input-border);
      box-sizing: border-box;
      font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter',
        'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New',
        Courier, monospace;
      padding: 20px;
    }
  }
  .block {
    &-edit-html {
      .body {
        background-color: var(--color-input-background);
        border: 1px solid var(--color-border-default);
        border-radius: 4px;

        @include dark-mode {
          background-color: var(--gray20);
        }

        textarea {
          background: transparent;
          color: var(--color-input-text);
          resize: vertical;

          &:focus {
            outline: 0;
          }
        }
      }
      .header {
        background-color: var(--gray100);
        border-bottom: 1px solid var(--color-border-default);
        color: var(--color-text-default);
        padding: 6px 13px;

        @include dark-mode {
          background-color: var(--gray15);
        }

        em {
          font-size: 0.8em;
          color: var(--color-text-minimum);
        }
      }
      .CodeMirror {
        height: auto;
      }
      .CodeMirror-scroll {
        overflow: auto;
        min-height: 30px;
      }
    }
    &-edit-embed {
      .input-group {
        .btn {
          padding: 7px 30px;
          margin: 0;
        }
      }
      .favicon {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
    &-display-embed {
      iframe.embedly-embed {
        width: 100%;
      }
      iframe.media-iframe {
        border: 0 none;
        margin: auto;
        display: block;
      }
    }
    &-edit-code {
      .status-icon {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 10px;
        background-color: #5bb349;
        margin-right: 5px;
        margin-bottom: 1px;
      }
      .status-icon.status-icon-error {
        background-color: #d96367;
      }
    }
    &-show-code {
      .status-icon {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 10px;
        background-color: #5bb349;
        margin-right: 5px;
        margin-bottom: 1px;
      }
      .status-icon.status-icon-error {
        background-color: #d96367;
      }
      .tab {
        em {
          font-style: normal;
        }
        .status-icon {
          margin-right: 2px;
        }
      }
    }
    &-edit-tutorial-tile {
      .row {
        height: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        display: -ms-flexbox;
        display: box;
        display: flex;
        -o-box-pack: justify;
        justify-content: space-between;
        label {
          display: block;
          &:last-child {
            margin-bottom: 0;
          }
        }
        select {
          background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff 50%, #fff);
          border: 1px solid transparent;
          border-radius: 3px;
        }
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
        .TutorialTile {
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.05);
          border-radius: 6px;
          display: -ms-inline-flexbox;
          display: inline-box;
          display: inline-flex;
          -o-box-flex: 1;
          box-flex: 1;
          flex: 0 0 auto;
          -o-box-orient: vertical;
          flex-direction: column;
          font-family: inherit;
          max-width: 350px;
          min-width: 340px;
          overflow: hidden;
          position: relative;
          transition: box-shadow 0.3s cubic-bezier(0.16, 1, 0.3, 1);
        }
        .TutorialTile-Body {
          display: -ms-flexbox;
          display: box;
          display: flex;
          padding: 20px;
        }
        .TutorialTile-Body-Avatar {
          color: #000;
          font-family: 'Apple Color Emoji', 'Segoe UI Emoji';
          font-size: 26px;
          height: 40px;
          width: 40px;
          border-radius: 3px;
          background-color: #018ef5;
          display: -ms-inline-flexbox;
          display: inline-box;
          display: inline-flex;
          -o-box-align: center;
          align-items: center;
          -o-box-pack: center;
          justify-content: center;
        }
        .TutorialTile-Body-Text {
          -o-box-align: start;
          align-items: flex-start;
          display: -ms-flexbox;
          display: box;
          display: flex;
          -o-box-orient: vertical;
          flex-direction: column;
          margin-left: 15px;
        }
        .TutorialTile-Body-Text-Action {
          color: #939eae;
          font-size: 14px;
          margin-top: 2px;
        }
        .TutorialTile-Body-Text-Action-Arrow {
          font-size: 11px;
          margin-left: 5px;
          opacity: 0.5;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
          filter: alpha(opacity=50);
          position: relative;
          top: -1px;
        }
        .TutorialTile-Body-Text-Title {
          color: #384248;
          display: -ms-flexbox;
          display: box;
          display: flex;
          font-size: 18px;
          font-weight: 600;
        }
        .select-parent {
          -o-box-flex: 1;
          box-flex: 1;
          flex: 1 1 auto;
          margin-left: 20px;
          select {
            padding: 5px;
            font-style: normal;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
    &-display-tutorial-tile {
      border: 0 none;
      margin: auto;
      display: block;
    }
    &-edit-code.ace-editor {
      background: var(--color-bg-page);
      border: 1px solid var(--color-input-border);
      border-radius: 3px;
      .block-code-status {
        width: 53px;
      }
      .CodeMirror {
        height: auto;
        font-size: 12px;
      }
      .CodeMirror-scroll {
        overflow: auto;
        min-height: 30px;
      }
      .ace-header {
        background-color: var(--gray100);
        border-bottom: 1px solid var(--color-input-border);
        border-radius: 4px 4px 0 0;
        padding: 3px 5px 0;
        text-align: left;

        @include dark-mode {
          background-color: var(--gray15);
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          li {
            display: inline-block;
            margin-right: 10px;
          }
        }
        .ui-sortable {
          position: relative;
          display: inline-block;
        }
        .tab {
          border: 1px solid transparent;
          border-bottom: 0 none;
          border-radius: 4px 4px 0 0;
          color: var(--color-text-minimum);
          display: inline-block;
          font-size: 11px;
          margin-right: 5px;
          padding: 4px 8px;
          position: relative;
          top: 1px;
          vertical-align: bottom;

          em {
            color: var(--color-text-minimum);
            font-style: normal;
            margin-right: 2px;
          }
        }
        .tab.ui-sortable-placeholder {
          height: auto;
          margin: auto;
        }
        .tab.on {
          border-color: var(--color-input-border);
          background-color: var(--white);
          color: var(--color-text-default);

          @include dark-mode {
            background-color: var(--gray20);
          }
        }
        .pull-right {
          display: inline-block;
        }
        a.fa {
          color: var(--color-text-minimum);
          padding: 0 3px;

          &:hover {
            color: #018ef5;
          }
        }
      }
      .editor-container.ng-hide {
        &:not(.ng-hide-animate) {
          display: block !important;
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
      }

      .options,
      .body,
      .code-init {
        background-color: var(--white);

        @include dark-mode {
          background-color: var(--gray20);
        }
      }

      .options {
        border-bottom: 1px solid var(--color-input-border);
        font-size: 13px;
        padding: 5px 10px;

        a {
          color: var(--color-text-minimum);
        }

        select.full-width {
          background: var(--color-input-background);
          border: 1px solid var(--color-input-border);
          color: var(--color-input-text);
          width: 100%;
        }
      }

      .body textarea {
        background: none;
        color: var(--color-input-text);
        margin-top: 0 !important; /* override inline style */
        resize: vertical;

        &:focus {
          outline: 0;
        }
      }

      .code-init {
        border-top: 1px solid var(--color-input-border);
        text-align: right;

        a {
          color: var(--color-text-minimum);
          display: inline-block;
          font-size: 12px;
          padding: 3px 9px;

          .fa {
            color: var(--color-text-minimum-icon);
            font-size: 14px;
            padding-right: 2px;
            position: relative;
            top: 1px;
          }

          &:hover {
            color: #018ef5;

            .fa {
              color: #018ef5;
            }
          }
        }
      }
    }
  }
  .magic {
    &-block {
      &-embed {
        iframe.embedly-embed {
          width: 100%;
        }
        iframe.media-iframe {
          border: 0 none;
          margin: auto;
          display: block;
        }
      }
      &-code {
        .status-icon {
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 10px;
          background-color: #5bb349;
          margin-right: 5px;
          margin-bottom: 1px;
        }
        .status-icon.status-icon-error {
          background-color: #d96367;
        }
        .tab {
          em {
            font-style: normal;
          }
          .status-icon {
            margin-right: 2px;
          }
        }
      }
      &-tutorial-tile {
        border: 0 none;
        margin: auto;
        display: block;
      }
    }
    &-more {
      border-top: 1px solid #f3f3f3;
      padding-top: 10px;
      margin-top: 15px;
      a {
        display: block;
        padding: 5px;
        font-size: 13px;
        color: #aba9a9;
        &:before {
          margin-right: 8px;
          color: #999;
        }
        &:hover {
          color: #018ef5;
          &:before {
            color: #018ef5;
          }
          .label {
            opacity: 1;
            -ms-filter: none;
            filter: none;
          }
        }
        .label {
          padding: 0.2em 0.6em;
          margin-left: 3px;
          background: #26c79f;
          opacity: 0.5;
          -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
          filter: alpha(opacity=50);
          position: relative;
          top: -1px;
        }
        .label.label-default {
          background: #ddd;
        }
      }
    }
  }
}

@mixin glossary-tooltip {
  $root: if(type_of(&) == 'null', '.glossary-tooltip', '');
  &#{$root} {
    position: relative;
    display: inline-block;
    cursor: pointer;
    .glossary-item {
      border-bottom: 1px solid #737c83;
      border-style: dotted;
      border-top: none;
      border-left: none;
      border-right: none;
    }
    .tooltip-content {
      visibility: hidden;
      width: 350px;
      text-align: left;
      border-radius: 5px;
      position: absolute;
      z-index: 9000;
      left: 0px;
      margin-left: -5px;
      background-color: #fff;
      top: 27px;
      color: #000;
      border: 1px solid #e2e3e3;
      box-shadow: 0 1px 3px -1px #e2e3e3;
      opacity: 0;
      transition: opacity 0.3s;
      .tooltip-content-header {
        text-align: left;
        display: block;
        font-size: 20px;
        border-bottom: 1px solid #e2e3e3;
        padding: 15px;
      }
      .tooltip-content-body {
        display: block;
        padding: 15px;
        .term {
          font-style: italic;
        }
      }
    }
    &:hover {
      .tooltip-content {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
