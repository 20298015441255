@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.Filters {
  border-right: 1px solid var(--color-border-default);
  height: 100%;
  padding: var(--sm) var(--md) var(--sm) 0;

  // Sticky here must consider header height
  position: sticky;
  top: 70px;
  z-index: 2;

  &-top {
    margin-bottom: var(--sm);
  }

  &_loading {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 900px;
  }

  &-search {
    margin-bottom: var(--sm);

    &_disabled {
      opacity: 0.5;
    }
  }
}

.Filter {
  $block: &;

  &-count {
    color: var(--color-text-minimum);
    font-size: 10px;
    font-weight: var(--font-weight-normal);
    margin-left: auto;
  }

  &-listItem {
    font-weight: var(--font-weight);
    height: 25px;
    width: 100%;

    &_greyed {
      #{$block}-name {
        opacity: 0.5;
      }
    }
  }

  &-listItemToggle {
    margin-bottom: 0;
  }

  &-name {
    color: var(--color-text-default);
    flex: 1 1 auto;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    :global(.IconWrapper) {
      margin-right: 0 !important;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &-APIMethod {
    margin-right: var(--sm);
  }
}

.FilterGroup {
  &-header {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight);
    padding-bottom: var(--xs);
    padding-top: var(--xs);
  }

  &-list {
    border-bottom: 1px solid var(--color-border-default);
    list-style: none;
    margin: 0 0 1rem;
    padding: 0 0 1rem;
    width: 100%;

    &:last-child {
      border-bottom: 0;
    }
  }

  &-more {
    align-items: center;
    display: flex;
    font-size: 13px;
    height: 30px;
    justify-content: flex-start;

    // Margin here accounts for checkbox width + gap
    padding-left: calc(16px + var(--md));
    padding-right: 0;
    width: 100%;

    > svg {
      opacity: 1 !important;
    }
  }

  &-moreIcon {
    background: var(--gray60);
    border: 0;
    border-radius: var(--border-radius);
    color: white;
    margin-left: var(--sm);
    width: 20px;
  }

  &-moreDropdownMenu {
    max-height: 300px;
    overflow-y: auto;
    width: 275px;

    .Filter-listItem {
      height: auto;
      margin-bottom: 0;
      padding: var(--xs) var(--sm);

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-moreMenuItem {
    padding: 0;


  }

  &-moreCount {
    color: var(--color-text-minimum);
    font-size: 10px;
    font-weight: var(--font-weight-normal);
    margin-left: auto !important;
  }

  &-moreSearchNoResults {
    text-align: center;
  }
}
