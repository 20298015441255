@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.KeyUsageDropdown {
  margin-left: auto;

  &-menu {
    margin-left: auto;
    max-height: 420px; // nice; srsly it's a magic number so that some of the api key items appear
    overflow-y: auto;
  }

  &-menuIcon {
    margin-top: 2px;
  }

  &-meta-label {
    color: var(--color-text-minimum);
    font-size: 12px;
  }

  &-keysHeader {
    white-space: break-spaces;
  }
}
