@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Textarea {
  --Textarea-background: var(--color-input-background);
  --Textarea-color: var(--color-input-text);
  --Textarea-border: var(--color-input-border);
  --Textarea-border-hover: var(--color-input-border-hover);
  --Textarea-border-active: var(--color-input-border-active);
  --Textarea-border-focus: var(--color-input-border-focus);
  --Textarea-disabled-bg: var(--color-input-disabled-bg);
  --Textarea-disabled-color: var(--color-input-disabled-color);
  --Textarea-placeholder: var(--color-input-placeholder);

  @include dark-mode {
    --Textarea-disabled-bg: var(--gray30);
    --Textarea-disabled-color: var(--gray60);

    color-scheme: light dark;
  }

  background: var(--Textarea-background);
  border: var(--border-width) solid var(--Textarea-border);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  color: var(--Textarea-color);
  font-family: inherit;
  font-weight: var(--font-weight-normal);
  line-height: 1.4;
  max-width: 100%;
  transition: box-shadow var(--transition-slow) var(--transition-timing);
  width: 100%;

  &:hover {
    border-color: var(--Textarea-border-hover);
  }

  &:focus {
    border-color: var(--Textarea-border-active);
    box-shadow: 0 0 0 3px var(--Textarea-border-focus);
    outline: none;
  }

  &:disabled {
    background: var(--Textarea-disabled-bg);
    color: var(--Textarea-disabled-color);
    cursor: not-allowed;
    -webkit-text-fill-color: var(--Textarea-disabled-color); // fix color in Safari
  }

  // uncontrolled
  &:not(.Textarea_clean):invalid:focus:focus(.Textarea_dirty) {
    border-color: $red;
    box-shadow: 0 0 0 3px rgba($red, 0.4);
  }

  // controlled
  &_dirty:invalid {
    border-color: $red;

    &:focus {
      box-shadow: 0 0 0 3px rgba($red, 0.4);
    }
  }

  &::placeholder {
    color: var(--Textarea-placeholder);
  }

  &_both {
    resize: both;
  }

  &_vertical {
    resize: vertical;
  }

  &_horizontal {
    resize: horizontal;
  }

  &_none {
    resize: none;
  }

  &_sm {
    font-size: 14px;
    padding: $xs;
  }

  &_md {
    font-size: 16px;
    padding: $sm;
  }

  &_lg {
    font-size: 18px;
    padding: 15px;
  }

  &_minimal {
    --Textarea-background: var(--ivory);
    --Textarea-bg-focus-minimal: white;
    --Textarea-border-active: #{$blue};

    @include dark-mode {
      --Textarea-background: var(--gray20);
      --Textarea-bg-focus-minimal: var(--gray10);
    }

    background: var(--Textarea-background);
    border-color: transparent;

    &:hover {
      border-color: transparent;
    }

    &:active {
      border-color: var(--Textarea-border-active);
    }

    &:focus {
      background: var(--Textarea-bg-focus-minimal);

      &:hover {
        border-color: var(--Textarea-border-active);
      }
    }
  }
}
