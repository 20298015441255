@import '@core/styles/vars';

.SAMLSetupModal {
  &-textarea {
    height: 200px;
  }

  &-label {
    padding-bottom: 10px;
  }
}

.Modal {
  font-family: var(--font-family);

  &-header {
    &-icon {
      color: var(--gray60);
      font-size: var(--icon-md);
      margin-right: $sm;
    }

    &-help {
      color: var(--gray60);
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: var(--blue);
        outline: 0;
      }
    }
  }

  form {
    width: 100%;
  }

  label {
    display: block;

    &:not(:first-child) {
      margin-top: $md;
    }

    > div,
    > span {
      color: var(--gray30);
      font-size: 14px;
      font-weight: var(--font-weight);
      margin-bottom: $sm;
    }
  }

  select {
    width: 100%;
  }

  table {
    background: var(--white);
    border: 1px solid rgba(black, 0.1);
    border-radius: var(--border-radius-lg);
    padding-top: $sm;
    width: 100%;

    :global(.Table) {
      width: 100%;
    }

    :global(tr + tr td) {
      border-top: 1px solid rgba(black, 0.1);
    }

    :global(th) {
      text-align: center;
    }

    :global(td),
    :global(th) {
      &:last-child {
        padding-right: $sm;
      }
    }
  }
}
