@import '~@readme/react/src/core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

// Response docs start as white, since the background is already light-gray
form.response .rm-ParamContainer {
  background: var(--ParamContainer-bg, var(--white));
}

.rm-ParamContainer {
  --ParamContainer-bg: var(--gray100);
  --ParamContainer-bg-alt: var(--white);
  --ParamContainer-border: var(--color-border-default);
  --ParamContainer-box-shadow: inset 0 1px 0 #{rgba($white, 0.1)};
  --ParamContainer-color: var(--color-text-default);

  @include dark-mode {
    --ParamContainer-bg: var(--gray15);
    --ParamContainer-bg-alt: var(--gray10);
    --ParamContainer-box-shadow: none;
  }

  background: var(--ParamContainer-bg);
  border: var(--border-width) solid var(--ParamContainer-border);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--ParamContainer-box-shadow);
  flex: 1 1 auto;
  margin: 0;
  padding: 0;

  .rm-ParamContainer {
    background: inherit;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  // This odd/even stuff is so fragile. Here's some details.
  // Currently we apply the odd or even flag to a section. Each parallell
  //    section needs to have the same odd/even flag applied.
  // I tried applying even/odd to the fieldset, and then selecting
  //    sections within that fieldset, but it led to arrays and objects having
  //    different selector logic, and **different colors**. Yes, even arrays
  //    asked for one color, and even objects asked for the other.
  //
  section.even {
    background: var(--ParamContainer-bg);
  }

  section.odd {
    background: var(--ParamContainer-bg-alt);
  }

  legend {
    display: none;
  }

  // where the heck are these tags coming from
  p,
  ol,
  ul {
    color: var(--ParamContainer-color);
    font-size: 13px !important;
    line-height: 1.4 !important;
    margin: 0;
  }

  // overriding some components
  // todo: update our regular input/textarea components
  .Input,
  .Textarea {
    background: var(--color-input-background);
    border: var(--border-width) solid var(--color-input-border);
    border-radius: var(--border-radius);
    color: var(--color-input-text);
    font-size: 14px;
    font-weight: var(--font-weight);
    padding-left: $sm;
    padding-right: $sm;
    transition: box-shadow var(--transition-fast) var(--transition-timing);
    width: 100%;

    &:focus:not(.invalid) {
      border-color: var(--color-input-border-active);
      border-width: var(--border-width);
    }

    &.Input_touched.invalid {
      border-color: var(--red);
      border-width: var(--border-width);

      &:focus {
        box-shadow: 0 0 0 3px rgba($red, 0.4);
      }
    }
  }

  .Input {
    word-break: normal;
  }

  .Select {
    // making these consistent with inputs in params
    border-radius: var(--border-radius);
    color: var(--color-input-text);
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: var(--font-weight);
    padding-bottom: 0;
    padding-left: $sm;
    padding-top: 0;
    word-break: normal;

    &[multiple] {
      background-image: none;
      height: auto;
      margin-top: var(--xs);
      padding: 2px;

      option {
        border-radius: calc(var(--border-radius) - 2px);
      }
    }

    &_placeholderShown {
      color: var(--color-input-placeholder);
    }
  }
}

.rm-APISchema {
  border-bottom: 1px solid var(--color-border-default);
  margin-bottom: var(--sm);
  padding-bottom: 30px;
}

div.markdown-body.schema-description {
  color: var(--color-text-minimum);
  font-size: 12px;
  margin-bottom: var(--sm);
}
