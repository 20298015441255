@import '~@core/styles/vars';

.ReviewLayout {
  $block: &;

  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  padding: 0 30px;

  &-divider {
    background: rgba(black, 0.1);
    border: 0;
    height: 1px;
    margin: $sm 0 $md;
  }

  &-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  &-title {
    color: $gray20;
    font-size: 24px;
    line-height: calc(5 / 3);
    margin: 0;
  }

  &-nav {
    align-items: center;
    display: flex;
  }

  &-main {
    display: flex;
    padding-bottom: var(--md);
  }

  &-content {
    flex: 1 1 auto;
    min-width: 0;
  }

  &-sidebar {
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    margin-left: $md;
    overflow: hidden;
  }

  &_debug {
    #{$block} {
      // Show borders around slots to help visualize the template.
      &-title,
      &-nav,
      &-content,
      &-sidebar {
        box-shadow: 0 0 0 1px $gray80;
      }
    }
  }
}
