@import '@core/styles/vars';

.BlockMenu {
  --BlockMenu-margin: calc(3 * var(--xs));

  box-sizing: border-box;
  height: calc(100% + var(--BlockMenu-margin));
  left: -30px;
  position: absolute;
  top: 0;
  width: 30px;

  &_Hoverpad {
    position: relative;

    // I'd prefer that this logic existed in the component, so these styles
    // weren't coupled with the drop logic. We render the "Hoverboard" either
    // before or after a block, to match where the dropped block will get
    // inserted. If the dropped block is coming from later in the doc, it gets
    // inserted before the targeted block. Conversely, if it is dropped later in
    // the doc, it is inserted after the targeted block.
    transition: box-shadow var(--transition-slow) ease-in-out;

    &--dragging {
      opacity: 0;
    }

    &--hover {
      box-shadow: 0 -2px 0 var(--blue);
    }

    &--dragging ~ &--hover {
      box-shadow: 0 2px 0 var(--blue);
    }

    &::before {
      content: '';
      height: var(--BlockMenu-margin);
      position: absolute;
      top: var(--blockmenu-margin);
      user-select: none;
      width: 100%;
    }

    &::after {
      bottom: var(--blockmenu-margin);
      content: '';
      height: var(--BlockMenu-margin);
      position: absolute;
      user-select: none;
      width: 100%;
    }

    &--float {
      z-index: 1;
    }
  }

  &_Container {
    position: relative;
  }

  &_Tooltip {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity var(--transition-slow) var(--transition-timing);
    z-index: 10;

    &--open {
      opacity: 1;
      pointer-events: initial;
    }

    &--dragging {
      opacity: 0;
      transition: none;
    }
  }

  &_Handle {
    cursor: grab;

    &-reusableContent {
      margin-right: var(--sm);
    }
  }
}

.DragLayer {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10005;

  &_Item {
    position: relative;

    :global(.markdown-body) {
      font-size: 15px;
      font-weight: var(--font-weight-normal);
      opacity: 0.5;
    }

    :global(.markdown-body) > * > h1 {
      margin-top: 0;
    }
  }

  &_Handle {
    position: absolute;
    right: 100%;
    top: 0;

    &-reusableContent {
      margin-right: var(--sm);
    }
  }

  &--dragging * {
    cursor: grabbing;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  .BlockMenu_Hoverpad--dragging {
    opacity: 1;
  }
}
