.ComboBox {
  &-wrapper {
    position: relative;
    width: 100%;

    :global([data-tippy-root]) {
      width: 100%;
    }
  }

  &-input {
    width: 100%;
  }

  &-menu,
  &-tippy {
    max-width: unset !important;
  }
}
