@import '../../../../../core/styles/vars.compat';

.TableCell {
  border-bottom: none !important;
  border-right: none !important;
  position: relative;
  overflow: none;

  &:first-child {
    border-left: none;
  }

  &_Header {
    border-top: none !important;
  }

  &_Placeholder::after {
    content: 'Column Title';
    position: absolute;
    opacity: 0.333;
    pointer-events: none;
    user-select: none;
    text-wrap: nowrap;
    transform: translateY(-100%);
  }

  &_Placeholder {
    &-left::after {
    }

    &-center::after {
      transform: translate(-50%, -100%);
    }

    &-right::after {
      right: calc(1.5 * var(--sm));
    }
  }
}
