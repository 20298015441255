@import '~@core/styles/vars.compat';

.Panel {
  border: 1px solid rgba(black, 0.1);
  border-radius: var(--border-radius-lg);
  position: relative;

  &-header {
    align-content: center;
    align-items: center;
    color: var(--slate);
    display: flex;
    justify-content: space-between;
    padding: 15px;

    &-helpUrl {
      padding: 10px 15px;
    }
  }

  &-heading {
    font-size: 18px;
    font-weight: var(--font-weight-bold);
    margin-bottom: 0;
    margin-top: 0;
  }

  &-body {
    background-color: var(--gray100);
    border-top: 1px solid rgba($black, 0.1);
    padding: 10px 0 0;

    input {
      background-color: $white;
    }
  }

  &-footer {
    background-color: var(--gray100);
    border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
    border-top: 1px solid rgba(black, 0.1);
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
}
