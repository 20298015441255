@import '~@core/styles/mixins/dark-mode';

.Skeleton {
  --Skeleton-bg: var(--color-skeleton);
  --Skeleton-height: 1em;
  --Skeleton-width: 100%;

  background: var(--Skeleton-bg);
  border-radius: calc(var(--Skeleton-height) / 2);
  height: var(--Skeleton-height);
  max-width: var(--Skeleton-width);
  width: 100%;

  &_box {
    border-radius: var(--border-radius-lg);
  }

  &_circle {
    --Skeleton-height: 1em;
    --Skeleton-width: 1em;

    border-radius: 50%;
  }
}
