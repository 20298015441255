@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.EmptyBlock {
  --EmptyBlock-background: var(--gray100);
  --EmptyBlock-settings-button-background: var(--white);

  @include dark-mode {
    --EmptyBlock-background: var(--gray15);
    --EmptyBlock-settings-button-background: var(--gray20);
  }

  align-items: center;
  background-color: var(--EmptyBlock-background);
  color: var(--color-text-minimum);
  cursor: pointer;
  display: flex;
  gap: var(--md);
  justify-content: space-between;
  padding: var(--xs) var(--sm) var(--xs) var(--xs);
  transition: box-shadow var(--transition-slow) var(--transition-timing);
  width: 100%;

  &:hover {
    box-shadow:
      0 1px 2px #{rgba(black, 0.05)},
      0 2px 5px #{rgba(black, 0.02)}; /* same as Box card */
  }

  &_message {
    align-items: center;
    display: flex;
    user-select: none;

    i {
      color: var(--gray70);
      font-size: var(--icon-md);
      margin-left: var(--sm);
    }
  }

  &-settings-button {
    &,
    &:hover {
      background-color: var(--EmptyBlock-settings-button-background);
    }
  }
}
