@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

// See the tooltip component style.scss for themeing examples
.rm-InputTooltip {
  .icon-help-circle {
    color: var(--gray70);

    @include dark-mode {
      color: var(--gray50);
    }
  }

  .icon-x-circle {
    color: $red;
  }

  .icon-check-circle {
    color: $green;
  }
}
