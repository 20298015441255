@import '~@core/styles/vars';

.Accordion {
  --Accordion-icon-color: var(--color-text-minimum);
  --Accordion-gap: var(--md);
  --Accordion-text-color: var(--color-text-default);

  color: var(--Accordion-text-color);
  display: flex;
  flex-direction: column;
  gap: var(--Accordion-gap);
  height: 100%;
}

.AccordionPanel {
  display: grid;
  flex: 0 0 auto;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr;
  min-height: 0;
  position: relative;

  &_active {
    flex: 1;
  }
}

.AccordionToggle {
  border-radius: var(--border-radius);
  font-size: inherit;
  margin: 0;

  &:focus-within {
    box-shadow: 0 0 0 3px rgba($blue, 0.25);
  }

  button {
    align-items: center;
    background: transparent;
    border: 0;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    padding: 0;
    text-align: left;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &-icon-slot {
    align-items: center;
    color: var(--Accordion-icon-color);
    display: flex;
    justify-content: center;
  }

  &-icon {
    transition: transform var(--transition-slow);
  }

  &_active .AccordionToggle-icon {
    transform: rotate(90deg);
  }
}

.AccordionContent {
  overflow: auto;
  padding: 0;

  [hidden] {
    display: none;
  }
}
