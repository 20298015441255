@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.ServerVars {
  --ServerVars-var-bg: #{rgba(black, 0.025)};

  @include dark-mode {
    --ServerVars-var-bg: #{rgba(white, 0.025)};
  }

  &-input {
    width: 100%;
  }

  &-url {
    color: var(--color-text-minimum);
    overflow: auto;

    @include dark-mode {
      color-scheme: dark;
    }

    span {
      flex: 0 0 auto;
    }

    &-wrapper {
      overflow-x: auto;
    }

    &-var {
      background: var(--ServerVars-var-bg);
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      color: var(--color-text-default);
      padding: 1px 2px;

      &_highlight {
        border-color: var(--blue);
      }

      &_invalid {
        border-color: var(--red);
        box-shadow: 0 0 0 3px rgba($red, 0.25);
      }
    }
  }

  &-form-reset {
    appearance: none;
    background: unset;
    border: unset;
    color: var(--blue);
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: var(--font-weight);
    padding: unset;

    .icon {
      height: $icon-sm;
      width: $icon-sm;
    }
  }

  .ServerText-endpoint {
    color: var(--shale);
  }

  .Menu {
    --spacing: 0.4rem;

    &-popover {
      max-width: unset;
    }

    &_hidden {
      visibility: hidden;
    }
  }

  // the icon
  .InputGroup-button:last-child {
    gap: var(--xs);
    white-space: nowrap;

    .icon-chevron-down {
      opacity: 0.5;
    }
  }

  // label at 100% width for reset button
  .FormGroup-label {
    width: 100%;
  }
}
