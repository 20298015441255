@import '@core/styles/vars.compat';
@import '@core/styles/mixins/dark-mode';

.Html {
  --Html-toolbar-background: var(--gray90);

  @include dark-mode {
    --Html-toolbar-background: var(--gray20);
  }

  padding: 0;

  &Toolbar {
    background-color: var(--Html-toolbar-background);
    display: flex;
    font-size: 12px;
    font-weight: 400;
    padding: $xs $sm;
    user-select: none;

    &-Tab {
      color: var(--color-text-muted);
      margin-right: $xs;
    }

    &-Note {
      color: var(--color-text-minimum);
    }
  }
}
