@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.rm-Tooltip {
  --Tooltip-bg: var(--gray20);
  --Tooltip-color: var(--white);

  @include dark-mode {
    --Tooltip-bg: var(--gray0);
  }

  $tippy-arrow-size: 8px;

  background: var(--Tooltip-bg);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius);
  color: var(--Tooltip-color);
  max-width: calc(100vw - 10px);
  outline: 0;
  padding: $xs;
  position: relative;
  transition-duration: var(--transition-fast);
  transition-property: opacity, transform, visibility;
  transition-timing-function: var(--transition-timing);

  .tippy-arrow {
    color: var(--Tooltip-bg);
    height: var(--icon-md);
    width: var(--icon-md);

    &::before {
      border-color: transparent;
      border-style: solid;
      content: '';
      position: absolute;
    }
  }

  &[data-placement^='top'] > .tippy-arrow {
    bottom: 0;
  }

  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: initial;
    border-width: $tippy-arrow-size $tippy-arrow-size 0;
    bottom: -$tippy-arrow-size + 1px;
    left: 0;
    transform-origin: center top;
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    top: 0;
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: initial;
    border-width: 0 $tippy-arrow-size $tippy-arrow-size;
    left: 0;
    top: -$tippy-arrow-size + 1px;
    transform-origin: center bottom;
  }

  &[data-placement^='left'] > .tippy-arrow {
    right: 0;
  }

  &[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: initial;
    border-width: $tippy-arrow-size 0 $tippy-arrow-size $tippy-arrow-size;
    right: -$tippy-arrow-size + 1px;
    transform-origin: center left;
  }

  &[data-placement^='right'] > .tippy-arrow {
    left: 0;
  }

  &[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: initial;
    border-width: $tippy-arrow-size $tippy-arrow-size $tippy-arrow-size 0;
    left: -$tippy-arrow-size + 1px;
    transform-origin: center right;
  }

  &[data-inertia][data-state='visible'] {
    transition-timing-function: var(--transition-timing);
  }

  .tippy-content {
    font-family: var(--font-family);
    font-size: 13px;
    font-weight: var(--font-weight);
    overflow-wrap: break-word;
    padding: 2px $xs;
    position: relative;
    z-index: 1;

    .markdown-body {
      pre,
      code {
        --md-code-background: var(--graphite, black);
      }

      a {
        color: var(--white, white);
        text-decoration: underline;

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  &_title .tippy-content {
    font-size: 11px;
    font-weight: var(--font-weight-normal);
    padding: 0 2px;
  }
}
