.NumberedWrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  color: var(--gray40);

  &-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: 600;
    padding: var(--sm);
  }

  &-badge {
    background-color: var(--gray90);
    border-radius: var(--border-radius);
    font-size: 12px;
    margin-left: var(--xs);
    padding: 2px var(--xs);
  }
}
