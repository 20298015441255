@import '@core/styles/mixins/dark-mode';

.ReusableContent {
  --ReusableContent-highlight: var(--gray80);
  --animation: none;

  @include dark-mode {
    --ReusableContent-highlight: var(--gray30);
  }

  $root: #{&};

  display: grid;
  min-width: 100px;
  position: relative;
  user-select: none;

  &::before {
    border: 2px solid var(--ReusableContent-highlight);
    border-radius: var(--border-radius-lg);
    content: '';
    display: block;
    inset: 0 calc(var(--sm) * -1);
    pointer-events: none;
    position: absolute;
    transition: border-color 0.1s 0.1s;
    z-index: 1;
  }

  &_interactive:not(#{$root}_read-only):hover,
  &_interactive:not(#{$root}_selected):not(#{$root}_read-only)#{$root}_active {
    --ReusableContent-highlight: var(--green);
    --animation: spin 0.8s ease-out;

    cursor: pointer;

    &:not(#{$root}_selected)::before {
      background-color: rgba(var(--blue-rgb), 0.03);
    }
  }

  &_selected {
    &::before {
      background-color: rgba(var(--blue-rgb), 0.2);
      z-index: 3;
    }
  }

  &_loading {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: var(--lg) 0;
  }

  &-controls {
    display: flex;
    justify-content: space-between;
    margin-left: -10px;
    position: relative;
    width: calc(100% + 20px);
    z-index: 4;

    &-right {
      position: absolute;
      right: 0;
      top: 0;
    }

    :global(.Menu) {
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &-config-menu-button {
    align-items: center;
    background-color: var(--ReusableContent-highlight);
    border: 0;
    border-radius: var(--border-radius-lg) 0 var(--border-radius-lg) 0;
    color: var(--white);
    display: grid;
    font-size: 12px;
    font-weight: bold;
    grid-template-columns: auto 1fr auto;
    height: auto;
    max-width: calc(100% - 125px);
    overflow: hidden;
    padding: 2px;
    position: absolute;
    transition: background-color 0.1s 0.1s;
    width: auto;

    &:not(button) {
      gap: var(--xs);
    }

    #{$root}_read-only & {
      &:hover {
        @include dark-mode {
          background-color: var(--gray40);
        }

        background-color: var(--gray70);
      }
    }

    &-label {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-icon {
      animation: var(--animation);

      @keyframes spin {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(1turn);
        }
      }
    }

    :global(.IconWrapper:not(:only-child)) {
      opacity: 1;

      &:first-child {
        margin-left: var(--xs);
      }

      &:last-child {
        margin-right: var(--xs);
        opacity: 0.5;
      }
    }
  }

  &-preview-button {
    all: unset;
    padding: 30px 0 var(--sm);
    position: relative;
    width: 100%;
    z-index: 2;

    &_blocked:hover {
      filter: blur(var(--xs));
      opacity: 0.5;
      transition:
        filter 0.2s ease-in-out 0.5s,
        opacity 0.2s ease-in-out 0.5s;
    }
  }

  &-preview-container {
    align-items: flex-start;
    display: grid;
    min-width: 0;

    > * {
      grid-area: 1 / 1 / 2 / 2;
      min-width: 0;
    }
  }

  &-permissions-warning {
    box-sizing: border-box;
    height: 0;
    max-width: 300px;
    opacity: 0;
    pointer-events: none;
    text-align: center;

    p#{$root}-message {
      font-size: 14px;
    }

    &-container {
      background-color: white;
      border-radius: var(--border-radius-lg);
      box-shadow:
        0 5px 10px #{rgba(black, 0.05)},
        0 2px 6px #{rgba(black, 0.025)},
        0 1px 3px #{rgba(black, 0.025)};
      box-sizing: border-box;
      height: 0;
      margin-bottom: var(--md);
      margin-top: var(--lg);
      padding: var(--sm) var(--md);
    }
  }

  &-preview-button_blocked:hover + &-permissions-warning {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: auto;
    opacity: 1;
    transition:
      opacity 0.3s 0.5s,
      height 0.3s ease-in-out 0.3s;
    z-index: 3;

    #{$root}-permissions-warning-container {
      height: 175px;
      transition: height 0.3s ease-in-out 0.3s;
    }
  }

  &_read-only #{$root}-preview-button {
    cursor: default;
  }

  &-preview {
    padding: 0;

    &#{$root}-preview {
      margin: 0;
    }

    :global {
      .markdown-body {
        // Match the markdown vars set on the Hub in packages/react/src/Hub/root.scss
        --markdown-font: var(--font-family);
        --markdown-font-size: 15px;
        --markdown-line-height: 1.5;
        --markdown-title-font: var(--font-family);
        --markdown-title-weight: var(--font-weight-bold);

        white-space: initial;

        .callout.callout {
          // On the hub, callouts rendered in the new editor seem to gain
          // extra padding around the edges; this override fixes it.
          padding: 1.33em;

          // Match editor/blocks/Blockquote override
          > * {
            margin-left: 2.5rem !important;
          }

          .callout-icon {
            margin-left: -1.9em !important;
          }
        }

        // Override some global .MarkdownEditor styles
        .callout-heading > p {
          font-size: unset;
          font-weight: var(--markdown-title-weight);
        }

        .embed_hasImg:only-child {
          margin-right: 15px;
        }
      }
    }
  }

  &-error {
    padding-bottom: var(--lg);
  }
}
