.Avatar {
  align-items: center;
  border-radius: var(--border-radius);
  color: var(--gray60);
  display: inline-flex;
  font-weight: 600;
  justify-content: center;
  overflow: hidden;
  user-select: none;

  &_xs,
  &_sm {
    // center emoji under 26px font-size in Chrome on retina displays
    // https://bugs.chromium.org/p/chromium/issues/detail?id=551420
    &.Avatar_emoji .Avatar-Text {
      font-size: 2.6em;
      text-transform: uppercase;
      transform: scale(0.5);
    }
  }

  &_xs {
    font-size: 9px;
    height: 20px;
    width: 20px;

    &.Avatar_circular {
      border-radius: 20px;
    }
  }

  &_sm {
    font-size: 14px;
    height: 30px;
    width: 30px;

    &.Avatar_circular {
      border-radius: 30px;
    }
  }

  &_md,
  &_lg,
  &_xl {
    &.Avatar_emoji .Avatar-Text {
      font-size: 1.5em;
    }
  }

  &_md {
    font-size: 16px;
    height: 40px;
    width: 40px;

    &.Avatar_circular {
      border-radius: 40px;
    }
  }

  &_lg {
    font-size: 20px;
    height: 50px;
    width: 50px;

    &.Avatar_circular {
      border-radius: 50px;
    }
  }

  &_xl {
    font-size: 24px;
    height: 60px;
    width: 60px;

    &.Avatar_circular {
      border-radius: 60px;
    }
  }

  &_emoji {
    color: rgba(0, 0, 0, 1);
    font-family: 'Apple Color Emoji', 'Segoe UI Emoji';
  }

  &-Img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}
