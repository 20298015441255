@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/contrast-dark-mode';

.Notification {
  $block: &;

  align-items: center;
  border-radius: 15px;
  color: var(--color-text-default);
  display: flex;
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  gap: var(--sm);
  min-width: 225px;
  padding: var(--sm);
  transform-origin: 50%;
  transition:
    opacity var(--transition-fast) var(--transition-timing),
    transform var(--transition-fast) var(--transition-timing);
  z-index: 1000;

  &_dark {
    background: rgba(black, 0.75);
    color: var(--color-text-default);

    @include contrast-dark-mode {
      background: var(--color-bg-popover);
    }
  }

  &_open {
    opacity: 1;
    transform: scale(1);
  }

  &_close {
    opacity: 0;
    pointer-events: none;
    transform: scale(0.95);
  }

  #{$block}-icon {
    background: var(--blue);
  }

  &_success {
    #{$block}-icon {
      background: var(--green);
    }
  }

  &_destructive,
  &_error {
    #{$block}-icon {
      background: var(--red);
    }
  }

  &_warning {
    #{$block}-icon {
      background: var(--yellow);
      color: var(--yellow20);
    }
  }

  &_secondary {
    #{$block}-icon {
      background: var(--gray15);
    }
  }

  &-icon {
    align-items: center;
    border-radius: var(--border-radius-lg);
    color: white;
    display: flex;
    justify-content: center;
    padding: var(--xs);
  }

  &-description {
    color: var(--color-text-muted);
    font-size: 12px;
    font-weight: var(--font-weight-normal);

    a {
      &, &:hover {
        color: currentColor;
        text-decoration: underline;
      }
    }
  }

  &-dismissBtn {
    margin-left: auto;

    :global(.IconWrapper) {
      color: var(--color-text-minimum);
    }

    &:hover,
    &:active,
    &:focus {
      border-color: transparent !important;
      opacity: 1;
    }
  }
}
