.AuditLogGroup {
  $block: &;

  background: var(--gray100);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  font-family: var(--font-family);
  overflow: hidden;

  &-header {
    background: var(--gray90);
    border: 0;
    box-sizing: border-box;
    color: currentcolor;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    height: 30px;
    padding: 0 var(--sm);
    transition: var(--transition-fast) color var(--transition-timing);

    &:hover {
      color: var(--blue);
    }
  }

  &-header-primary {
    flex: 1 1 auto;
    min-width: 300px;
  }

  &-header-secondary {
    flex: 0 0 auto;
  }

  &-toggle-icon {
    flex: 0 0 auto;
    margin-right: var(--sm);
    transform: rotate(90deg);
    transition: var(--transition-fast) transform linear;

    &_collapsed {
      transform: rotate(0deg);
    }
  }

  &-label {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &-list {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    list-style: none;
    margin: 0;
    padding: 0;
    visibility: visible;
  }

  &-item {
    box-sizing: border-box;
    margin: 0;
  }

  &-item-more-wrapper {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  &-item-more {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
  }

  &_not-collapsible {
    #{$block} {
      &-header {
        cursor: default;

        &:hover {
          color: currentcolor;
        }
      }
    }
  }

  &_empty {
    #{$block} {
      &-toggle-icon {
        opacity: 0.5;
        transform: rotate(0deg);
      }
    }
  }
}
