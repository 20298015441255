@import '~@core/styles/vars.compat';

.DashForm {
  margin: 0 auto;
  width: fit-content;

  &-hr {
    background: rgba(black, 0.1);
    border: 0;
    height: 1px;
    left: $lg;
    margin: 0;
    position: absolute;
    right: $lg;
  }

  fieldset {
    border: 0;
  }

  p {
    color: var(--minimumGray, var(--minimum-gray));
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    margin: $sm 0 0;
  }

  &-warning p {
    color: #da8800;
  }

  &-error p {
    color: #e95f6a;
  }

  &-textarea-label {
    align-self: flex-start;
    padding: 5px 0 0;
  }

  &-one-column,
  &-two-column {
    margin: auto;
    padding: $md 0;
  }

  &-two-column {
    align-items: baseline;
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    grid-template-columns: max-content 300px;
    justify-content: end;

    label,
    &#{&} &-label {
      color: var(--slate);
      font-size: 14px;
      font-weight: var(--font-weight);
      text-align: right;
    }
  }

  &-col {
    display: flex;
    flex-direction: column;

    &-link {
      color: inherit;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: var(--gray10);
        text-decoration: underline;
      }
    }
  }

  // only for screen readers
  legend {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    top: auto;
    width: 1px;
  }
}
