@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.WhatsNext {
  $root: #{&};

  &-wrapper {
    --WhatsNext-color: var(--color-link-primary);

    padding: $md 0;

    :global(.ThemeContext_dark) & {
      @include dark-mode {
        --WhatsNext-color: var(--color-link-text);
      }
    }

    h3 {
      font-size: 30px;
      font-weight: 300;
      margin: 0 0 10px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        align-items: center;
        color: var(--WhatsNext-color);
        display: flex;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 7px;

        a {
          color: var(--WhatsNext-color);
          font-size: 16px;
          text-decoration: none;
        }

        #{$root}-editIcons {
          display: flex;
          opacity: 0;
          padding-left: 1em;
          transform: translateX(-50%);
          transition: all 0.2s ease-in-out;

          button:hover {
            svg {
              opacity: 1;
            }
          }
        }

        &:hover,
        &:focus-within {
          #{$root}-editIcons {
            opacity: 1;
            transform: translateX(0);
          }
        }

        svg {
          margin-left: 5px;
          opacity: 0.5;
        }

        &#{&}-link {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &-heading strong {
    font-size: 14px;
  }

  &-input {
    background: none;
    margin: 0 -5px;
    padding: 0.25em;
  }

  &-description {
    font-family: var(--font-family);
    font-size: 14px;
    margin-bottom: 10px;

    &_empty {
      display: none;
    }
  }

  &-submitBtn {
    align-self: center;
    margin: 0.75em 0 0.25em;
    width: 92%;
  }

  &-menus {
    padding-top: 1em;
  }

  &-pageMenu {
    &-input {
      margin: var(--xs) var(--sm);
    }

    &-results {
      border: 0;
      border-radius: 0;
      box-shadow: none;

      [role='menuitem'] {
        padding: 0 var(--sm) !important;
      }

      > * {
        border-radius: 0;
        padding: 0;
      }

      &_empty {
        color: var(--color-text-default);
        font-family: var(--font-family);
        margin: 0.5em;
      }
    }
  }
}
