@import '~@core/styles/vars';
@import '~@core/styles/mixins/rainbow.scss';

.PricingTooltip {
  &-header {
    :global(.IconWrapper) {
      margin-right: var(--xs);
    }
  }

  &-description {
    color: var(--gray80);
    font-size: 12px;
  }

  &-requests {
    text-align: right;

    &-progress {
      background-color: white;
      width: inherit;

      [class*='-indicator'] {
        fill: var(--red);
      }
    }
  }
}

.PricingText {
  font-size: 12px;
  font-weight: 700;

  @include rainbow();
}
