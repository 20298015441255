@import '~@core/styles/vars.compat';

.Link {
  color: var(--blue, blue);
  cursor: pointer;
  position: relative;

  &_Menu {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity var(--transition-slow) ease-in-out;
    z-index: 9000;
  }

  &:focus-within &_Menu {
    opacity: 1;
    pointer-events: initial;
  }

  &_Link {
    max-width: 100%;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
  }
}
