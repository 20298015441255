@import '~@core/styles/vars.compat';
@import '@core/styles/mixins/dark-mode';

.HorizontalRule {
  height: 31px;

  &-selected {
    @include dark-mode {
      background-color: scale-color(get(color 'blue'), $saturation: -70%, $lightness: 20%) !important;
    }

    background-color: scale-color(get(color 'blue'), $saturation: -30%, $lightness: 80%) !important;
  }

  // Setting the hr height to half of the wrapper div height centers the hr in the middle of the div.
  // Otherwise, it's pushed to the top of the wrapper because it has margin: 15px and height: 0.
  hr {
    @include dark-mode {
      border-bottom: 1px solid var(--color-border-default);
    }

    height: 15px !important;
  }
}
