@import '@core/styles/mixins/dark-mode';

.ReusableContentModal {
  --ReusableContentModal-background: var(--gray100);

  @include dark-mode {
    --ReusableContentModal-background: var(--gray10);
  }

  height: calc(100vh - var(--xl));
  max-height: 800px;
  min-width: 560px;

  &-content {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
  }

  &-header,
  &-body {
    background: var(--ReusableContentModal-background);
  }

  &-header {
    border-bottom: 0;
    padding: 15px var(--md);

    &-title {
      font-size: 16px !important; // override for some interfering global hub styles
      font-weight: 600;
      margin: 0;
    }

    &-infopop,
    &-icon {
      font-size: 16px;
    }

    &-infopop {
      margin-left: 0;
    }
  }

  &-body {
    min-height: 0;
    padding: 0 var(--md) var(--md);
  }

  &-form-wrapper {
    height: 100%;
    padding: 0;
  }
}
