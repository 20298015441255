@import '@core/styles/vars.compat';

.EventFeed {
  display: grid;
  font-family: var(--font-family);
  grid-template-rows: 1fr auto;
  height: 0;
  min-height: 100%;
  padding: 0;

  &-main {
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden scroll;
    padding: 0 15px;
  }

  &-content {
    min-width: 0;
  }

  &-footer {
    background: white;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
    padding: 1em;

    svg {
      margin: 0 2px;
    }

    a {
      align-items: center;
      color: var(--gray30);
      display: grid;
      grid-template-columns: auto 1fr auto;
      justify-content: space-between;
    }

    p {
      -webkit-box-orient: vertical;
      display: inline-block;
      display: flex;
      font-size: 14px;
      -webkit-line-clamp: 2;
      margin: 0 0 0 10px;
      overflow: hidden;
    }

    i {
      color: var(--minimumGray, var(--minimum-gray));
      display: inline-block;
      font-size: $icon-md;
      transition: all 0.2s;
    }

    a:hover,
    a:hover i,
    a:focus i {
      color: var(--gray10);
    }

    a:hover i,
    a:focus i {
      transform: translate(2px, 0);
    }
  }

  &-link {
    text-decoration: none;
  }

  &-loading {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  &-projectLink {
    color: var(--blue);
    margin-top: 1em;
  }

  &-title {
    margin: $md 0;
  }

  &-subtitle {
    color: var(--minimumGray, var(--minimum-gray));
    font-size: 14px;
    margin: 0;
    padding-bottom: var(--xs);
  }

  &-group {
    margin-bottom: 30px;

    :global(.Dropdown) {
      margin: 0 var(--xs);
    }

    :global(.Dropdown) + :global(.Dropdown) {
      margin-top: var(--xs);
    }
  }

  &-event {
    height: unset;
    padding: var(--xs) var(--sm);
    width: 100%;
    width: stretch;

    :global(.Dropdown-content) {
      margin: 0;
      padding: 0;
    }

    & > div {
      max-width: unset;
    }

    &,
    &-menu {
      margin-left: calc(var(--sm) * -1);
      margin-right: calc(var(--sm) * -1);
    }

    &-menu {
      max-width: unset;
      width: 100%;
      width: stretch;
    }

    &-userphoto {
      flex: 0;

      i {
        background: var(--ivory);
        border-radius: 1em;
        color: var(--minimumGray, var(--minimum-gray));
        padding: 4px;
      }
    }

    &-info {
      flex: 1;
      overflow: hidden;

      &-user {
        color: var(--graphite);
        font-size: 14px;
        font-weight: var(--font-weight-bold);
        overflow: hidden;
        padding-bottom: 0.2em;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-details {
        align-items: center;
        color: var(--minimumGray, var(--minimum-gray));
        display: flex;
        font-size: 12px;

        &-elapsed {
          font-weight: var(--font-weight-bold);
        }

        &-status {
          font-size: 12px;
          padding: 0;
          position: relative;
          top: 1px;

          :global(.HTTPStatus-chit) {
            position: relative;
            top: -1.5px;
          }
        }
      }

      &-divider {
        background: var(--minimumGray, var(--minimum-gray));
        border-radius: 1em;
        display: inline-block;
        height: 2px;
        margin: 0 0.3em;
        width: 2px;
      }
    }
  }
}
