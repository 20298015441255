@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.CodeEditor {
  --background-color: var(--gray100);

  @include dark-mode {
    --background-color: var(--gray15);
  }

  background-color: var(--background-color);
  display: grid;
  height: 100%;
  min-height: 0;
  min-width: 0;
  width: 100%;

  :global {
    .CodeSnippet {
      background-color: inherit;
      border: 0;
      margin-bottom: 0;
      max-height: 85vh;

      .CodeMirror-gutters,
      .CodeMirror-scrollbar-filler,
      .CodeMirror-vscrollbar {
        background-color: var(--background-color);
      }

      .CodeMirror-scroll {
        min-height: auto;
      }

      &,
      .CodeEditor,
      .CodeEditor .CodeMirror {
        height: 100%;
        width: 100%;
      }
    }
  }

  &_Flow {
    :global {
      .CodeMirror-sizer {
        margin-left: 0.25em !important;
      }

      .CodeMirror-gutters {
        display: none;
      }
    }
  }
}
