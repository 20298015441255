/* stylelint-disable length-zero-no-unit */
@import '@core/styles/vars';

.Banner {
  --pad: var(--sm);

  align-items: center;
  border: 0;
  border-radius: 7.5px;
  bottom: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05), inset 0px -1px 2px rgba(0, 0, 0, 0.2),
    inset 0px 1px 1px rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  justify-content: center;
  left: 0;
  max-width: 100vw;
  padding: var(--pad);
  text-align: center;
  width: fit-content;
  z-index: $z-modal;

  &_md {
    --pad: var(--md);
  }

  &_sm {
    --pad: var(--sm);
  }

  &_xs {
    --pad: var(--xs);
  }

  &_secondary {
    background: var(--slate);
    color: var(--white);
  }

  &_primary {
    background: var(--blue);
    color: var(--white);
  }

  &_warn {
    background: var(--yellow);
    color: var(--yellow10);
  }

  &_alert {
    background: var(--red);
    color: white;
  }

  &_fixed {
    border-radius: 0;
    box-shadow: none;
    width: 100%;
  }

  &_dismissible {
    box-sizing: border-box;
    padding-right: 3.5em;
    position: relative;
  }

  &-Dismiss {
    cursor: pointer;
    position: absolute;
    right: 0.5em;
  }

  &[href]:hover,
  &[href]:focus,
  &[href]:active {
    background: darken(black, 25%);
    color: var(--white);
    cursor: pointer;
  }

  i {
    font-size: 17px;
    margin-right: 7px;
  }

  a:not([class^='Button']) {
    color: var(--white);
    cursor: pointer;
    margin-left: 5px;
    text-decoration: underline;

    &:hover,
    &:active,
    &:focus {
      color: var(--white);
      text-decoration: none;
    }
  }

  + .Banner {
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
}
