@import '@core/styles/vars';

.LogsLimitPopover {
  $block: &;

  align-items: flex-start;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-menu-light);
  color: var(--color-text-default);
  font-weight: var(--font-weight-bold);
  gap: var(--sm);
  max-width: 330px;
  position: fixed;
  right: var(--sm);
  top: var(--sm);
  transition:
    opacity var(--transition-fast) var(--transition-timing),
    transform var(--transition-fast) var(--transition-timing);
  z-index: 99999;

  &-superhub {
    background-color: rgba(0, 0, 0, 0.9);
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: var(--box-shadow-menu-dark);
   color: rgb(255, 255, 255);
    right: 75px;
    top: 105px;

    @media screen and (max-width: 1450px) {
      right: 40px;
    }

    @media screen and (max-width: 800px) {
      top: 115px;
    }

    #{$block}-icon {
      background: rgba(255, 255, 255, 0.1);
      color: var(--gray70) !important;
    }

    #{$block}-description {
      color: var(--gray80);
    }

    #{$block}-close-icon {
      color: var(--gray80);
    }

    #{$block}-header-title {
      color: rgb(255, 255, 255);
    }
  }

  &-icon {
    align-items: center;
    background: var(--gray90);
    border-radius: var(--border-radius-lg);
    color: var(--gray40) !important;
    display: flex;
    flex-shrink: 0;
    height: $button-sm;
    justify-content: center;
    padding: 1px;
    width: $button-sm;
  }

  &-button {
    margin-top: var(--md);
  }

  &-content {
    font-size: 14px;
    padding: var(--sm);

    @media (prefers-reduced-motion: no-preference) {
      transition:
        max-height var(--transition-slow) ease-in-out,
        box-shadow var(--transition-slow) ease-in-out;
    }
  }

  &-increase-limit {
    margin-top: 5px;
  }

  &-description {
    color: var(--gray40);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    width: 85%;
    word-wrap: normal;
  }

  &-close-icon {
    color: var(--gray40);
    cursor: pointer;
    height: 14px;
    position: absolute;
    right: var(--sm);
    top: var(--sm);
    width: 14px;
  }

  &-header {
    margin-bottom: 0;
    width: 100%;

    &_expanded {
      margin-bottom: var(--sm);
    }

    &-title {
      color: var(--color-text-minimum);
      font-size: 14px;
      margin: 0;
    }

    &-totals {
      color: var(--color-text-default);
      font-size: 12px;
      margin-left: auto;
    }

    &-icon {
      color: var(--color-text-minimum);
    }
  }
}
