@import '~@core/styles/vars';

.Spinner {
  --spinner-color: var(--gray70);

  $root: &;

  align-items: center;
  display: flex;
  justify-content: center;

  &_sm {
    font-size: var(--icon-sm);
  }

  &_md {
    font-size: var(--icon-md);
  }

  &_lg {
    font-size: var(--icon-lg);
  }

  &_xl {
    font-size: calc(var(--icon-md) * 2);
  }

  &_xxl {
    font-size: calc(var(--icon-md) * 3);
  }

  &_overlay {
    --spinner-color: var(--gray80);

    background: rgba(var(--color-bg-page-rgb), 0.75);
    height: 100%; 
    left:0;
    position: absolute;
    top:0;
    width: 100%;
    z-index: 99;

    #{$root}-icon {
      left: 50%;
      pointer-events: none;
      position: fixed;
      top: 50%;
    }
  }

  &-icon {
    animation: spin 1s infinite linear;
    color: var(--spinner-color);
    fill: none;
    height: 1em;
    overflow: visible;
    width: 1em;

    @keyframes spin {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(1turn);
      }
    }

    @media (prefers-reduced-motion) {
      display: none;
    }
  }

  // Falls back to a 'Loading...' text label when user prefers reduced motion
  &-label {
    display: none;

    @media (prefers-reduced-motion) {
      display: block;
    }
  }
}
