@import '~@core/styles/vars.compat';

@mixin HeadingLevels {
  @for $i from 1 through 6 {
    &#{$i} {
      font-size: ms(6 - $i);
    }
  }
}

.Title {
  @include HeadingLevels;

  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0;

  &_bold {
    font-weight: 800;
  }
}
