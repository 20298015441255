@import '@core/styles/vars';

.CardHeadingLink {
  color: var(--color-text-default);
  display: inline-block;
  text-decoration: none;

  &,
  &:hover,
  &:focus {
    color: var(--color-text-default);
  }

  h2 {
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    margin: 0;
  }

  &-badge {
    margin-left: var(--xs);
    vertical-align: middle;
  }

  i {
    color: var(--color-text-minimum);
    display: inline-block;
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    margin-left: var(--xs);
    transition: all 0.2s;
  }

  &:hover i,
  &:focus i {
    color: var(--color-text-default);
    transform: translate(2px, 0);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba($blue, 0.25);
    outline: none;
  }
}
