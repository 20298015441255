@import '~@core/styles/vars.compat';

.Recipe {
  display: flex;
  max-width: 350px;
  min-width: 300px;
  user-select: none;

  &-Settings {
    background-color: var(--gray100);
    cursor: pointer;
    font-size: 16px;
    padding: $xs $sm;
  }

  &-Tile {
    align-items: center;
    display: flex;
    padding-left: 10px;

    &-Avatar {
      border-radius: var(--border-radius-lg, 7.5px);
      flex: 0 0 auto;
      margin-right: 15px;

      > span {
        font-size: 30px;
      }
    }

    &-Text {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      text-align: left;

      &-Title {
        color: var(--graphite);
        display: flex;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.1;
      }

      &-Action {
        color: var(--minimumGray) !important;
        cursor: pointer;
        font-size: 12px;
        text-decoration: none !important;

        &-Arrow {
          color: inherit;
          margin-left: 1px;
          position: relative;
          top: 1px;
        }

        &:hover {
          color: var(--blue, blue) !important;
        }
      }
    }
  }
}
