.Collapsible {
  $block: &;

  overflow: hidden;
  transition: height var(--transition-slow) var(--transition-timing);

  &-content {
    transition: visibility 0s linear var(--transition-slow);
    visibility: hidden;

    &_opened {
      transition: none;
      visibility: visible;
    }
  }

  &-summary {
    background: none;
    border: 0;
    color: currentcolor;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    padding: 0;

    &-icon {
      flex: 0 0 auto;
      font-size: calc(1em * 1.25);
      transform: rotate(0deg);
      transition: transform var(--transition-fast) linear;
    }

    &_opened {
      #{$block}-summary-icon {
        transform: rotate(90deg);
      }
    }

    &_empty {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
