@import '~@core/styles/vars.compat';

.LoadingScreen {
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 90vh;
  width: 100%;

  i {
    margin: auto;
  }
}
