@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.APIKeyDropdown {
  // When there's only one key to render, we'll mirror the style of secondary button
  &-one-key {
    @include dark-mode {
      color: var(--color-text-muted);
    }

    color: var(--graphite);
    font-size: 14px;
    font-weight: var(--font-weight-bold);
    height: 30px;

    :global(.Icon) {
      opacity: 0.6;
    }
  }

  &-menu {
    max-height: 500px;
    overflow-y: auto;
  }

  &-menuItem-key {
    font-size: 14px;
    width: 100%;

    &_active {
      width: 80%;
    }
  }

  &-menuHeader,
  &-menuItem-label,
  &-company {
    font-size: 12px;
  }

  &-menuItem-label {
    color: var(--gray40);
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
