@import '@core/styles/vars';

.LogDetailPopover {
  --LogDetailPopoverBg: var(--gray10);

  backdrop-filter: blur(var(--sm));
  background: var(--LogDetailPopoverBg);
  border-radius: var(--border-radius-lg);
  color-scheme: dark;
  height: 100%;
  max-height: 500px;
  overflow: hidden;
  width: 450px;

  &_expanded {
    animation: expand-overflow 1s both;
    max-height: 90vh;
    transition: max-height var(--transition-slow) ease-in-out;
  }

  @keyframes expand-overflow {
    0% {
      overflow: hidden;
    }

    100% {
      // Only apply overflow after animation is complete to prevent scrollbar flash
      overflow: auto;
    }
  }

  &-collapse {
    height: auto;
    position:relative; 
    
    &-overlay {
      align-items: flex-end;
      background: linear-gradient(
        rgba(var(--gray10-rgb), 10%),
        rgba(var(--gray10-rgb),  70%),
        rgba(var(--gray10-rgb), 100%)
      );
      bottom: 0;
      display:flex;
      height: 60px;
      justify-content: center;
      padding-bottom: var(--sm);
      position: absolute;
      width:100%;
      z-index: 99;
    }

    &-open {
      background: none;
      height: auto;
      position: static;
    }

    &-height {
      height: 54px;
    }

    &-bttn {
      background: var(--LogDetailPopoverBg);

      &:hover {
        background: var(--LogDetailPopoverBg);
      }
    }
  }

  &-loading,
  &-error {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    min-height: 500px;
    width: 100%;
  }

  &-error {
    color: var(--white);
    font-size: 14px;
    text-align: center;
  }

  &-copy-to-clipboard {
    border: 0;
    color: var(--gray80);

    &:not(:disabled):hover,
    &:not(:disabled):focus-visible {
      color: var(--gray90);
    }
  }

  &-header {
    height: 100%;
    position: relative;

    &-top {
      padding: var(--sm) var(--md);
    }

    &-metadata {
      color: var(--white);
      font-weight: var(--font-weight);

      strong {
        font-weight: var(--font-weight-bold);
      }

      &-id {
        color: var(--gray60);
      }
    }

    &-status {
      font-size: 12px;
    }

    &-title {
      font-size: 13px;
      margin-top: 0;
    }

    &-subheading {
      color: var(--gray80);
      display: flex;
      font-size: 12px;
      font-weight: var(--font-weight-normal);
      gap: var(--xs);
      overflow: auto;
      white-space: nowrap;

      code {
        font-family: unset;
        padding: 0;
      }
    }

    &-button {
      bottom: var(--sm);
      position: absolute;
      right: var(--sm);

      &:focus {
        color: var(--gray90);
      }
    }
  }

  &-close-btn {
    position: absolute;
    right: var(--sm);
    top: var(--sm);
  }

  &-tabs-list {
    border-color: var(--gray30);
    justify-content: left;
    padding-left: var(--md);

    :global {
      // Override default Tabs styling for more of a portfolio look
      // Note: this assumes only 2 tabs for now
      .Tabs-listItem {
        border: 1px solid var(--gray30);
        color: rgba(white, 0.5);
        font-size: 13px;
        height: $button-sm;
        margin-bottom: -1px;

        &:first-child {
          border-radius: var(--border-radius) 0 0 0;
          border-right: 0;
        }

        &:last-child {
          border-radius: 0 var(--border-radius) 0 0;
        }

        &_active {
          border-bottom-color: var(--LogDetailPopoverBg);
          box-shadow: none !important;
          color: white !important;
        }

        &:hover {
          color: rgba(white, 0.5);
        }

        &:focus {
          box-shadow: 0 0 0 3px rgba(white, 0.25);
        }
      }
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  &-content {
    overflow: auto;
    width: 100%;

    > div {
      border: 1px solid rgba(0, 0, 0, 0.1);

      :global {
        .CodeMirror-gutters {
          background-color: var(--LogDetailPopoverBg);
        }

        .CodeMirror-scroll {
          min-height: unset;
          padding-right: 50px;
        }

        .CodeSnippet {
          background-color: inherit;
          border: unset;
          margin: 0;
        }
      }
    }

    > div:first-child {
      border: 0;

      &,
      button {
        color: var(--gray80);
      }
    }
  }

  &-body-header {
    align-items: center;
    display: flex;
    min-height: 40px;
    padding: var(--xs) var(--sm);
  }

  &-body-heading {
    align-items: center;
    color: var(--gray80);
    display: flex;
    font-size: 12px;
    font-weight: var(--font-weight);
    margin: 0;
    text-transform: uppercase;

    &-badge {
      margin-left: var(--xs);
    }
  }

  &-key-value-list {
    color: white;
    display: grid;
    font-size: 12px;
    grid-template-columns: 40% 60%;
    line-height: 1.4;
    margin: 0;
    overflow-x: auto;
    padding-bottom: 1em;
    row-gap: 2px;
    width: 100%;

    &-name-with-tooltip:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &-muted {
      color: var(--gray70);
    }

    dt {
      font-weight: var(--font-weight-normal);
      justify-content: end;
      padding-left: var(--sm);
      white-space: nowrap;
    }

    dd {
      font-family: var(--font-family-mono);
      font-size: 11px;
      margin: 0;
      padding: 0 var(--sm);
      white-space: nowrap;
    }

    dt,
    dd {
      align-items: center;
      box-sizing: border-box;
      display: inline-flex;
    }
  }

  &-headers {
    &-filter {
      --Input-prefix-width: 30px;

      width: 150px;

      :global(.Input) {
        background-color: inherit;
        border-color: var(--gray30);
        color: white;
      }

      &-no-results {
        color: var(--gray70);
        font-size: 12px;
        padding: var(--sm);
      }
    }

    &-tooltip-menu {
      :global {
        .Menu-Header,
        .Menu-Item-description {
          color: white !important;
        }
      }

      &-description {
        a {
          color: inherit;
          text-decoration: underline;

          &:hover,
          &:focus,
          &:active {
            text-decoration: none;
          }
        }
      }
    }
  }

  &-copiedItem {
    i {
      margin-right: var(--xs);
    }
  }
}
