/* stylelint-disable-next-line scss/at-import-partial-extension */
@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.Tabs {
  --Tabs-border-color: #{darken(get(grays, -1), 4%)};
  --Tabs-color: var(--gray40);
  --Tabs-color-hover: var(--gray20);
  --Tabs-color-active: var(--project-color-primary, #{get(color 'blue')});

  @include dark-mode {
    --Tabs-border-color: #{rgba(white, 0.1)};
    --Tabs-color: var(--gray80);
    --Tabs-color-hover: var(--gray90);
  }

  width: 100%;

  &-list {
    border-bottom: 1px solid var(--Tabs-border-color, get(grays, 2));
    box-sizing: border-box;
    color: var(--Tabs-color);
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    &Item {
      align-items: center;
      box-sizing: border-box;
      color: inherit;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: var(--font-weight);
      height: 40px;
      padding: 0 10px 2px;
      text-align: center;
      text-decoration: none;
      transition:
        box-shadow var(--transition-timing) var(--transition-slow),
        color var(--transition-timing) var(--transition-slow);
      user-select: none;

      &:hover,
      &:focus {
        color: var(--Tabs-color-hover);
      }

      &:focus {
        outline: 0;
      }

      &_active {
        box-shadow: inset 0 -2px 0 currentcolor !important;
        color: var(--Tabs-color-active) !important;

        .ThemeContext_dark & {
          @include dark-mode {
            color: var(--color-text-default) !important;
          }
        }

        .ThemeContext_light:not(.ThemeContext_line) & {
          @include dark-mode {
            color: var(--Tabs-color-active) !important;
          }

          color: var(--project-color-inverse) !important;
        }
      }
    }
  }

  &-content {
    min-width: 32em;
  }
}
