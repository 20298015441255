@import '~@core/styles/vars.compat';
@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

// Variables
:global {
  .rm-LanguagePicker {
    --LanguageButton-bg: transparent;
    --LanguageButton-button-color: var(--color-text-muted);
    --LanguageButton-button-border: var(--color-input-border);
    --LanguageButton-button-border-hover: var(--color-input-border-hover);
    --LanguageButton-button-border-active: var(--color-input-border-active);
    --LanguageButton-button-border-focus: var(--color-input-border-focus);
    --LanguageButton-font-size: 11px;
    --LanguageButton-width: 77px;
  }

  .rm-LanguageButton,
  .rm-LanguageButton-more {
    background: var(--LanguageButton-bg);
    border: var(--border-width) solid transparent;
    border-radius: var(--border-radius-lg);
    color: var(--LanguageButton-button-color);
    font-weight: var(--font-weight-bold);
    transition: box-shadow var(--transition-fast) var(--transition-timing);

    &:hover {
      border-color: var(--LanguageButton-button-border-hover);
    }

    &:active,
    &:focus {
      border-color: var(--LanguageButton-button-border-active);
    }

    &:focus {
      box-shadow: 0 0 0 3px var(--LanguageButton-button-border-focus);
    }

    &_active,
    &_active:hover,
    &_active:active {
      border-color: var(--LanguageButton-button-border);
    }

    &_active:focus {
      border-color: var(--LanguageButton-button-border-active);
      box-shadow: 0 0 0 3px var(--LanguageButton-button-border-focus);
    }
  }

  .rm-LanguageButton {
    font-size: var(--LanguageButton-font-size);
    font-weight: var(--font-weight);
    padding-left: 0;
    padding-right: 0;
    white-space: nowrap;
  }

  .rm-LanguageButton-more {
    color: var(--color-text-minimum);
    font-size: var(--icon-lg);
  }

  .rm-LanguageButtonBtnGroup-more {
    border: var(--LanguageButton-button-border);
  }

  .rm-LanguageButtonBtnGroup {
    &_active {
      color: var(--blue);
    }
  }
}

.LanguagePicker {
  align-items: center;
  display: inline-flex;
  width: 100%;

  &-languages {
    align-items: center;
    display: inline-flex;
    flex-wrap: wrap;
    gap: var(--xs);
    width: 100%;
  }

  &-button,
  &-more {
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-family: var(--font-family);
    padding: var(--sm);
    width: var(--LanguageButton-width);

    &:focus {
      outline: 0;
    }

    &-icon {
      font-size: var(--icon-md);
      margin-bottom: $xs;
    }
  }

  &-button-btn-group {
    border-color: var(--LanguageButton-button-border);
    font-size: 13px;
    gap: var(--xs);
    min-width: var(--LanguageButton-width);
    padding: 0 10px;

    &-icon {
      font-size: var(--icon-md);
      margin-bottom: 0;
    }
  }

  &-more {
    align-items: center;
    display: inline-flex;
    height: 30px;
    justify-content: center;
    margin-left: auto;
    padding: 0;
    width: 30px;
  }

  &-MenuItem {
    align-items: center;
    display: flex;
    font-size: 14px;

    &-icon {
      font-size: var(--icon-md);
      margin-right: $sm;
    }

    // More specificity to override Menu
    &-icon.LanguagePicker-MenuItem-icon {
      opacity: 1;
    }
  }

  &-Dropdown {
    display: inline-block;

    &-button:global(.Button) {
      --button-font-size: 12px;

      color: var(--LanguageButton-button-color);

      & > i:first-child {
        margin-right: 2px;
        opacity: 1;
      }

      &:hover {
        border-color: var(--LanguageButton-button-border-hover);
        color: var(--LanguageButton-button-color);
      }

      &:active,
      &:focus {
        border-color: var(--LanguageButton-button-border-active);
      }

      &:focus {
        box-shadow: 0 0 0 3px var(--LanguageButton-button-border-focus);
      }
    }

    &-icon {
      font-size: var(--icon-md);
    }
  }

  &-Minimal {
    --LanguageButton-width: 60px;

    @include dark-mode {
      --LanguageButton-bg: rgba(var(--green100-rgb), .1);
      --LanguageButton-button-border: rgba(var(--green100-rgb), .2);
      --LanguageButton-button-border-hover: rgba(18, 202, 147, 0.80);
    }

    .LanguagePicker-languages {
      gap: var(--sm);

      .LanguagePicker-button {
        height: var(--LanguageButton-width); 
        justify-content: center;
      }

      .LanguagePicker-button-icon {
        font-size: var(--icon-lg); 
        margin:0;
      }

      .LanguagePicker-more:hover {
        background:var(--LanguageButton-bg);
        border-color: var(--LanguageButton-button-border-hover); 
        color: var(--LanguageButton-button-color);
      }
    }
  }

  &-languageIcon-aws,
  &-languageIcon-json {
    > svg {
      margin-right: 0;
    }
  }
}
