@import '~@core/styles/vars.compat';

$flexers: (
  col: (
    flow: nowrap column,
  ),
  row: (
    flow: nowrap row,
  ),
);
$attrs: (
  align-items: flex-start flex-end center baseline stretch,
  justify-content: center space-between space-around flex-end flex-start stretch,
  text-align: right center left,
);

@mixin modify {
  @each $prop, $vals in $attrs {
    $index: str-index($prop, '-');
    $key: $prop;
    @if $index {
      $key: str-slice($prop, 0, $index - 1);
    }
    @each $val in $vals {
      $index: str-index($val, '-');
      $name: $val;
      @if $index {
        $name: str-slice($val, $index + 1);
      }
      &-#{$key}\=#{$name},
      &[#{$key}='#{$name}'] {
        #{$prop}: unquote($val);
      }
    }
  }
}

.Flex {
  --flex-gap: 1em;

  gap: var(--flex-gap);

  & {
    @include modify;

    display: flex;
    text-align: left;
  }

  &[inline] {
    display: inline-flex;
  }

  &[wrap] {
    flex-wrap: wrap;
  }

  @each $class, $vals in $flexers {
    &_#{$class} {
      @each $k, $v in $vals {
        flex-#{$k}: $v;
      }
    }
  }

  &_row {
    &,
    > [flex='inner'],
    > .Flex_inner {
      > [shift~='start'],
      > .Flex_shift\=start {
        margin-left: auto !important;
      }

      > [shift~='end'],
      > .Flex_shift\=end {
        margin-right: auto !important;
      }
    }
  }

  &_start {
    justify-content: flex-start !important;
  }

  &_col {
    &,
    > [flex='inner'],
    > .Flex_inner {
      > [shift~='start'],
      > .Flex_shift\=start {
        margin-top: auto !important;
      }

      > [shift~='end'],
      > .Flex_shift\=end {
        margin-bottom: auto !important;
      }
    }
  }

  [flex='block'] {
    flex: 1;
  }

  > .Flex_inner,
  > [flex='inner'] {
    align-items: inherit;
    display: inherit;
    flex-flow: inherit;
    flex-direction: inherit;
    flex-wrap: inherit;
    justify-content: inherit;
    text-align: inherit;
  }

  &,
  > .Flex_inner,
  > [flex='inner'] {
    &[text='start'],
    &[text='left'] {
      text-align: left;
    }

    &[text='center'] {
      text-align: center;
    }

    &[text='end'],
    &[text='right'] {
      text-align: right;
    }
  }
}
