@import '@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.PageNav {
  --PageNav-gap: 2px; // match hubs
  --PageNav-padding: 0;

  $dash-footer-height: 35px;

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family);
  height: 100%;
  overflow: auto;
  position: relative;

  &-content {
    flex: 1 1 auto;
    margin: 0;
    padding-bottom: var(--PageNav-padding);
    padding-left: var(--PageNav-padding);
    padding-right: var(--PageNav-padding);
  }

  &-children {
    flex: 1 1 auto;
    margin: 0;
    padding: var(--PageNav-gap) 0;
  }

  &-footer {
    @include dark-mode {
      background: rgba(var(--gray0-rgb), 0.01);
    }

    $footer-offset: 25px;

    backdrop-filter: blur(var(--md));
    background: rgba(var(--white-rgb), 0.01);
    border-top: 1px solid var(--color-border-default);
    bottom: 0;
    box-sizing: border-box;
    flex: 0 0 auto;
    height: var(--SuperHubEditor-footer, $dash-footer-height);
    padding: $sm;
    position: sticky;
  }

  &-toggle-row {
    color: $gray30;
    cursor: pointer;
    flex: 1 1 auto;
    font-size: 12px;
    padding: $xs 0;

    > span {
      margin-right: $xs;
    }
  }
}
