@import '~@core/styles/vars.compat';

.Param {
  &-select {
    width: 100%;
  }

  &-fileInput {
    display: flex;
    justify-content: flex-end;

    &-button {
      align-items: center;
      cursor: pointer;
      display: inline-flex;
      justify-content: center;
      max-width: 30px;
    }

    &-input {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }

  &-fileInfo {
    margin: 5px 0 0;

    &-name {
      font-weight: var(--font-weight-bold);
    }

    &-description {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 1.1;
      text-align: right;
    }
  }
}

.InputDropdown {
  width: 100%;
}

.InputTooltip {
  /* RM-2922 Increase specificity to ensure these styles are not sometimes overridden by .rm-Tooltip */
  &[role='tooltip'] {
    min-width: 200px;
  }

  &-description {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
  }

  &-validation {
    &-icon {
      flex: 0;
      font-size: $icon-md;
      margin-right: $xs;
    }

    &-key {
      flex: 0 0;
      font-size: 13px;
      margin-right: $xs;
      white-space: nowrap;
    }

    &-value {
      font-size: 13px;
    }
  }
}
