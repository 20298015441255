@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.MetricsGraph {
  --MetricsGraph-padding: var(--md);
  --color-skeleton: var(--gray80);

  @include dark-mode {
    --color-skeleton: var(--gray30);
  }

  font-family: var(--font-family);
  height: 100%;
  overflow: hidden;
  padding: 0;
  width: 100%;

  &-main-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    height: 100%;
  }

  &-main {
    box-sizing: border-box;
    display: grid;
    grid-template-rows: auto 1fr;
    min-width: 0;
    padding: var(--MetricsGraph-padding);
    width: 100%;
  }

  &-aside {
    border-left: 1px solid var(--color-border-default);
    box-sizing: border-box;
    height: 0;
    min-height: 100%;
    overflow-y: auto;
    padding: 15px $sm 15px 15px;
    width: 200px;

    &-heading {
      align-items: center;
      border-bottom: 1px solid var(--color-border-default);
      display: flex;
      flex: 0 0 20px;
      font-size: 12px;
      font-weight: var(--font-weight);
      margin-bottom: $xs;
      margin-top: 0;
      padding-bottom: $sm;
      text-transform: uppercase;
    }

    &-tooltip {
      width: 100%;
    }
  }

  &-group {
    display: grid;
    grid-template-columns: minmax(50%, 1fr) minmax(0, min-content);
    width: 100%;

    &-toggle {
      display: flex;
      flex: 0 0 30px;
      width: 100%;

      span {
        color: var(--color-text-default);
        display: inline-block;
        font-size: 12px;
        font-weight: var(--font-weight);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-badge {
      background: rgba(black, 0.05);
      font-weight: var(--font-weight-normal);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &-heading {
    align-items: center;
    color: var(--color-text-default);
    display: flex;
    font-size: 24px;
    font-weight: var(--font-weight-bold);
    gap: var(--sm);
    margin: 0 0 $xs;
  }

  &-subheading {
    align-items: center;
    color: var(--color-text-minimum);
    display: flex;
    font-size: 16px;
    font-weight: var(--font-weight);
    gap: $xs;
  }

  &-dropdown {
    margin-right: -8px;
    margin-top: -8px;
  }

  &-chart {
    min-width: 0;
  }

  &-header + .MetricsGraph-chart {
    padding-top: $md;
  }

  &-footer {
    background-color: var(--lightGray, var(--light-gray));
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05);
    padding: $xs $md;
  }

  &_condensed &-heading {
    font-size: 16px;
  }

  &_condensed &-subheading {
    font-size: 14px;
  }

  &_disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.GraphTooltip {
  padding: 0;
  pointer-events: none;
  position: absolute;
  transform: translate(calc(-100% - #{$md}), -$md); // left align
  transition: all var(--transition-fast) var(--transition-timing);
  width: 300px;
  z-index: 1001; // Needs to be higher than z-index of #main in public/css/dash.styl

  &-divider {
    border-top: 1px solid var(--color-border-default);
    margin: 0;
  }
}

.GraphTooltipItem {
  display: grid;
  font-size: 13px;
  grid-template-columns: 1fr auto;
  padding: $sm;

  &_condensed {
    grid-template-columns: 3fr 1fr;
    padding: 0 $sm $sm;
  }

  &_strong &-label,
  &_strong &-value {
    color: var(--color-text-default);
    font-weight: var(--font-weight-bold);
  }

  &-label,
  &-value {
    align-items: center;
    display: flex;
    gap: $sm;
    justify-content: flex-start;
    min-width: 0;
  }

  &-label {
    color: var(--color-text-muted);
    font-weight: var(--font-weight);

    & > *:first-child {
      flex: 0 0 auto;
    }

    & > *:last-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  &-value {
    color: var(--color-text-minimum);
    font-weight: var(--font-weight-normal);
    justify-content: end;
    text-align: right;
  }
}
