@import '~@core/styles/vars.compat';

.Dropdown {
  &-toggle {
    user-select: none;
    width: 100%;
  }

  &-content {
    margin: -$xs;
    max-width: 42em;
    padding: $xs;
    width: #{'max(calc(100vw - 20px), max-content)'};
  }

  &_fullWidth {
    position: relative;

    &,
    [data-tippy-root],
    .tippy-box,
    .tippy-content,
    .tippy-content > div,
    .Dropdown-content {
      max-width: unset;
      width: 100%;
      width: stretch;
    }
  }
}
