.ErrorBadge {
  background: var(--red40);
  border-radius: var(--border-radius);
  box-sizing: content-box;
  color: var(--white);
  flex: 0 0 auto;
  font-size: 10px;
  margin-left: var(--xs);
  padding: 3px;
}
