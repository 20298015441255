.Orb {
  --orb-rgb: var(--green-rgb);
  --orb-size: 30;
  --orb-alpha: 0.2;

  // generated from https://larsenwork.com/easing-gradients/
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(var(--orb-rgb), var(--orb-alpha)) 0%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.987)) 8.1%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.951)) 15.5%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.896)) 22.5%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.825)) 29%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.741)) 35.3%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.648)) 41.2%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.55)) 47.1%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.45)) 52.9%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.352)) 58.8%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.259)) 64.7%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.175)) 71%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.104)) 77.5%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.049)) 84.5%,
    rgba(var(--orb-rgb), calc(var(--orb-alpha) * 0.013)) 91.9%,
    rgba(var(--orb-rgb), 0) 100%
  );
  height: var(--orb-size);
  width: var(--orb-size);
}
