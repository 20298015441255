/* stylelint-disable-next-line scss/at-import-partial-extension */
@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.Box {
  --Box-background-flat: var(--gray100);
  --Box-background: var(--box-background, 255, 255, 255);
  --Box-border: var(--color-border-default);
  --Box-card-shadow: 0 1px 2px #{rgba(black, 0.05)}, 0 2px 5px #{rgba(black, 0.02)};
  --Box-pop-shadow: 0 5px 10px #{rgba(black, 0.05)}, 0 2px 6px #{rgba(black, 0.025)}, 0 1px 3px #{rgba(black, 0.025)};

  @mixin box-dark-mode {
    --Box-background: var(--gray15-rgb);
    --Box-background-flat: var(--gray15);
    --Box-pop-shadow: 0 5px 10px #{rgba(black, 0.1)}, 0 2px 6px #{rgba(black, 0.1)}, 0 1px 3px #{rgba(black, 0.05)},
      inset 0 -1px 1px #{rgba(black, 0.1)};
    --Box-card-shadow: 0 1px 2px #{rgba(black, 0.1)}, 0 2px 5px #{rgba(black, 0.1)};
  }

  @include dark-mode {
    @include box-dark-mode;

    &_dark-on-black {
      --Box-background: #{rgba(white, 0.05)};
      --Box-background-flat: #{rgba(white, 0.05)};
      --Box-border: #{rgba(white, 0.15)};
      --Box-pop-shadow: none;
      --Box-card-shadow: none;

      background: var(--Box-background);
      border-radius: var(--border-radius-lg);
      color: white;
    }
  }

  background: rgba(var(--Box-background), 1);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  overflow: hidden;
  padding: ms(0);

  // via Box prop
  &_dark {
    --Box-border: #{rgba(white, 0.05)};

    @include box-dark-mode;

    color: white;
  }

  &_flat {
    background: var(--Box-background-flat);
  }

  &_rule,
  &_pop,
  &_card {
    border: 1px solid var(--Box-border);
  }

  &_pop,
  &_card {
    backdrop-filter: blur(var(--md));
    background: rgba(var(--Box-background), 0.85);
  }

  &_pop {
    box-shadow: var(--Box-pop-shadow);
  }

  &_dark-on-black {
    --Box-background: #{rgba(white, 0.05)};
    --Box-background-flat: #{rgba(white, 0.05)};
    --Box-border: #{rgba(white, 0.15)};
    --Box-pop-shadow: none;
    --Box-card-shadow: none;

    background: var(--Box-background);
    border-radius: var(--border-radius-lg);
    color: white;
  }

  &_card {
    box-shadow: var(--Box-card-shadow);
  }

  & > &_inner {
    $offset: -1 * ms(0);
    $offset: calc(#{$offset} - 1px);

    border-radius: 0;
    margin-left: $offset;
    margin-right: $offset;

    &:first-child {
      margin-top: $offset;
    }

    &:last-child {
      margin-bottom: $offset;
    }
  }
}
