@import '~@core/styles/vars.compat';

$unit: 4px;

.IconMenu {
  z-index: var(--z-dropdown);

  &:global(.Menu) {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    gap: 1px;
    justify-content: center;
    min-width: 120px;
    padding: var(--xs);
    position: fixed;
    top: 0;
    transition:
      opacity var(--transition-fast) var(--transition-fast) ease-in-out,
      visibility var(--transition-fast) var(--transition-fast) ease-in-out;
  }

  &-closed {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .Button {
    &:global(.Button) {
      border: 0;
      border-radius: var(--border-radius);
      box-shadow: none;
      cursor: pointer;
      font-size: var(--icon-md);
      height: var(--button-sm);
      padding: 0 7px;
      width: var(--button-sm);
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:hover:enabled {
      background-color: var(--gray90);
    }

    i {
      font-size: var(--icon-md);
    }

    &:active:enabled,
    &:focus-visible:enabled,
    &-active {
      color: var(--blue);
    }

    &-active:hover:enabled {
      color: var(--blue);
    }
  }

  .Divider {
    border-left: 1px solid var(--color-border-default);
    height: var(--icon-md);
    margin: auto $unit;
  }
}
