@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.CodeInput {
  --CodeInput-background: var(--color-input-background);
  --CodeInput-color: var(--color-input-text);
  --CodeInput-border: var(--color-input-border);
  --CodeInput-border-hover: var(--color-input-border-hover);
  --CodeInput-border-active: var(--color-input-border-active);
  --CodeInput-border-focus: var(--color-input-border-focus);
  --CodeInput-disabled-bg: var(--color-input-disabled-bg);
  --CodeInput-disabled-color: var(--color-input-disabled-color);
  --CodeInput-placeholder: var(--color-input-placeholder);

  @include dark-mode {
    --CodeInput-disabled-bg: var(--gray30);
    --CodeInput-disabled-color: var(--gray60);

    color-scheme: dark;
  }

  background: var(--CodeInput-background);
  border: var(--border-width) solid var(--CodeInput-border);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  color: var(--CodeInput-color);
  cursor: text;
  font-family: inherit;
  font-weight: var(--font-weight-normal);
  line-height: 1.5;
  max-width: 100%;
  overflow: auto;
  padding-top: 15px;
  resize: vertical;
  transition: box-shadow var(--transition-slow) var(--transition-timing);
  width: 100%;

  &:hover {
    border-color: var(--CodeInput-border-hover);
  }

  &:focus-within {
    border-color: var(--CodeInput-border-active);
    box-shadow: 0 0 0 3px var(--CodeInput-border-focus);
    outline: none;
  }

  &:disabled {
    background: var(--CodeInput-disabled-bg);
    color: var(--CodeInput-disabled-color);
    cursor: not-allowed;
    -webkit-text-fill-color: var(--CodeInput-disabled-color); // fix color in Safari
  }

  &_sm {
    font-size: 13px;
    min-height: 80px;
  }

  &_md {
    font-size: 15px;
    min-height: 90px;
  }

  &_lg {
    font-size: 17px;
    min-height: 100px;
  }

  &-code-snippet {
    height: 100%;
  }

  :global {
    .CodeSnippet {
      height: 100%;
      margin: 0;
      min-height: 0;
      overflow: auto;
      white-space: pre;
    }

    .CodeEditor {
      height: 100%;
      padding-right: 1em; // one-char padding between last char and right edge

      .CodeMirror {
        height: 100%;
      }

      .CodeMirror,
      .cm-s-neo.CodeMirror {
        font-size: inherit;
        line-height: inherit;

        &,
        .CodeMirror-gutters {
          background-color: var(--CodeInput-background) !important;
        }

        .cm-lineNumber {
          background: var(--CodeInput-background);
        }
      }
    }
  }
}
