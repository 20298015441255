@import '@core/styles/vars';

.DragDropPreview {
  $block: &;
  $init-scale: 0.6;
  $duration-hide: 200ms;
  $duration-show: 80ms;

  left: 0;
  max-width: 200px;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity $duration-hide linear, transform $duration-hide ease-out, visibility 0s linear $duration-hide;
  visibility: hidden;
  z-index: var(--z-banner);

  &-content {
    background: var(--gray100);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-status);
    color: var(--blue);
    font-size: 12px;
    padding: var(--xs) var(--sm);
    transform: scale($init-scale);
    transform-origin: top left;
    transition: transform 0ms ease-out $duration-hide;

    svg {
      color: var(--blue);
    }
  }

  &-preview {
    &-title {
      color: var(--gray50);
    }

    &-content {
      padding: $xs 0;
    }

    &-label {
      flex: 1 1 auto;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &_dragging {
    opacity: 1;
    transition: none;
    visibility: visible;

    #{$block} {
      &-content {
        transform: scale(1);
        transition: transform $duration-show ease-out;
      }
    }
  }

  &_dropped {
    #{$block} {
      &-content {
        transform: scale(0);
        transform-origin: top left;
        transition: transform $duration-show ease-in;
      }
    }
  }

  &-body_dragging * {
    cursor: grabbing;
    user-select: none;
  }
}
