@import '~@readme/mdx/dist/main';
@import '@core/styles/vars';
@import '@core/styles/mixins/ngz';
@include toc;

.content-toc {
  box-sizing: border-box;
  flex: 0 0 var(--hub-toc-width);
  padding: 30px $md 30px $lg;

  .tocHeader {
    align-items: center;
    display: flex;
  }

  a {
    padding-left: 23px; // align to headings
  }

  .icon {
    color: #999;
    font-size: 16px;
    text-align: center;
    width: 23px;
  }

  @media (max-width: $ipad-pro-10-landscape) {
    display: none;
    flex: 0 0 auto;
  }
}
