/* stylelint-disable no-descending-specificity */
@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.reference-redesign {
  .disabled-warning {
    color: $red;
    display: block;
    font-size: 13px;
    padding: 0 20px 40px;
    text-align: center;

    .icon {
      color: $red;
      margin-right: $xs;
    }

    a {
      background: $red;
      border-radius: $xs;
      color: $white;
      display: block;
      margin: 10px 10px 0;
      padding: 7px 15px;
      text-align: center;

      &:hover {
        background-color: darken($red, 20%);
      }
    }
  }

  .hub-sidebar-parent {
    left: auto;
    top: 0;
    z-index: 10;

    &.static {
      position: absolute;
    }
  }

  .subpages {
    display: flex;
    flex-direction: column;

    &:last-child {
      margin-bottom: $xs;
    }

    &:empty {
      display: none;
    }
  }

  .external {
    font-weight: var(--font-weight-bold);
    margin: 0 0 0 auto;
    order: 1;
  }

  .readme-logo {
    align-items: center;
    color: var(--Sidebar-logo);
    display: flex;
    font-family: var(--font-family);
    font-size: 14px;
    gap: var(--xs);
    margin-left: 15px;
    margin-top: auto;
    padding-bottom: $sm;
    padding-top: $lg;

    a {
      display: flex;
    }

    .icon {
      font-size: 14px;
    }
  }

  .rm-Sidebar {
    --Sidebar-link-color: var(--color-link-primary-darken-5, var(--blue));
    --Sidebar-link-background: #{var(--color-link-background, rgba($blue, 0.09))};

    .ThemeContext_dark & {
      @include dark-mode {
        --Sidebar-link-color: var(--color-link-text);
        --Sidebar-link-background: var(--Sidebar-link-hover-background);
      }
    }
  }

  // chatgpt
  .rm-OwlbotAI {
    margin: 0 15px 15px;
  }
}
