.PaginatedSteps {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 240px;

  &-footer {
    align-items: center;
    border-top: 1px solid var(--color-border-default);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    margin-top: var(--sm);
    padding-top: var(--sm);

    > *:last-child {
      text-align: right;
    }
  }

  &-progress {
    color: var(--color-text-muted);
    font-size: 12px;
  }
}
