.ContentContainer {
  box-sizing: border-box;
  padding: 0 50px;
  width: 100%;

  &_md {
    max-width: var(--dash-container-md);
  }

  &_lg {
    max-width: var(--dash-container-lg);
  }

  &_xl {
    max-width: var(--dash-container-xl);
  }

  &_centered {
    margin-left: auto;
    margin-right: auto;
  }
}
