@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';


.JsxFlowBlock {
  background-color: transparent;

  :global .CodeMirror {
    pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
      padding: 0;
    }

    .CodeMirror-sizer {
      margin-left: 0 !important;
    }
  }
}


