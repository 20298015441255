@import '@core/styles/vars';

.PageNavItemVersion {
  $block: &;

  border-radius: var(--border-radius);
  padding: 0 var(--PageNav-padding);
  transition: background 60ms linear;

  &:hover {
    background: rgba(0, 0, 0, 0.05);

    #{$block}-link {
      color: var(--color-text-minimum-hover);
    }
  }

  &-link {
    background: none;
    border: 0;
    color: var(--color-text-minimum);
    cursor: pointer;
    flex: 1 1 0;
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    line-height: calc(4 / 3);
    min-width: 0;
    padding: $xs 0;
    text-decoration: none;
    transition: color var(--transition-fast) var(--transition-timing);
    user-select: none;
  }

  &-timestamp {
    flex: 0 0 auto;
  }

  &-author {
    flex: 1 1 auto;
    margin-left: var(--xs);
    overflow: hidden;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &_active {
    background: rgba(var(--blue-rgb), 0.1);

    #{$block}-link {
      color: var(--blue);
    }
  }
}
