@import '~@core/styles/vars.compat';

.DashTable {
  min-width: 600px;

  &-header,
  &-filters,
  &-title {
    align-items: center;
    color: var(--color-text-minimum);
    display: flex;
  }

  &-header {
    display: flex;
    height: 50px;
    justify-content: space-between;
    padding-left: $sm;
    padding-right: $sm;
    position: relative;
  }

  &-title {
    &-heading {
      color: var(--gray20);
      font-size: 16px;
      font-weight: var(--font-weight);
      margin-bottom: 0;
      margin-top: 0;
    }

    &-count {
      color: var(--gray60);
      font-size: 12px;
      font-weight: var(--font-weight-normal);
      margin-left: $xs;
    }

    span {
      margin-right: $xs;
    }

    span + div {
      padding-top: 3px;
    }
  }

  &-filters {
    gap: $xs;

    &-input,
    &-button {
      + .DashUsersTable-filters-input,
      + .DashUsersTable-filters-button {
        margin-left: $xs;
      }
    }

    &-input {
      border-color: transparent;
      max-width: 150px;
    }
  }

  &-table {
    border-top: 1px solid rgba(black, 0.1);
    table-layout: fixed;
    width: 100%;

    &-row {
      color: var(--gray20);
      font-size: 14px;
      font-weight: var(--font-weight);
      height: 50px;

      td {
        border-bottom: 1px solid rgba(black, 0.1);
        padding-left: 15px;

        &:first-child {
          padding-left: $sm;
        }

        &:last-child {
          padding-right: $sm;
        }
      }

      // Adds a bounding border to the last row of the table
      &_bounded {
        tr:last-child {
          td {
            border-bottom: 1px solid var(--color-border-default);
          }
        }
      }
    }

    &-small {
      align-items: center;
      color: var(--gray40);
      display: flex;
      font-size: 12px;
      font-weight: var(--font-weight-normal);
    }
  }

  &-menu {
    &-button {
      justify-content: space-between;
      width: 100%;
    }

    &-item {
      padding-bottom: 3px;
      padding-top: 3px;

      &-description {
        color: $minimum-gray;
        font-size: 12px;
        font-weight: var(--font-weight-normal);

        &-red {
          color: get(color 'red');
          font-size: 12px;
          font-weight: var(--font-weight-normal);
        }
      }

      :global(.Toggle) {
        margin-bottom: 0;
      }
    }

    &-check {
      color: var(--gray60);
      margin-left: $sm;
      opacity: 0;
      visibility: hidden;

      &_active {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @at-root {
    @keyframes spinner {
      0% {
        transform: rotate(0deg) scale(1);
      }

      100% {
        transform: rotate(360deg) scale(1);
      }
    }
  }
}
