@import '@core/styles/vars';

.Pagination {
  font-size: 14px;
  margin-bottom: $sm;
  margin-top: $sm;

  &-dropdown {
    margin-bottom: 0;
    position: relative;

    &:focus-within {
      border-color: $blue;
      box-shadow: 0 0 0 3px rgba($blue, 0.25);
    }

    &_disabled,
    &_disabled:hover {
      background: none;
      color: lighten($slate, 40);
      cursor: not-allowed;
    }

    &-label {
      font-weight: 400;
    }

    &-icon {
      margin-left: 7px;
    }

    &-Select {
      height: 100%;
      left: 0;
      margin: 0;
      opacity: 0;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &-children {
    margin-right: auto;
  }
}
