@import '@core/styles/vars.compat';

.Emoji {
  &_Container {
    border-radius: 3px;
    margin: -1px -1px -3px;
    padding: 1px 1px 3px;
    transition: none;
  }
}
