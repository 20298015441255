.Graphic {
  --Graphic-size: inherit;

  font-size: var(--Graphic-size);

  &-wrapper {
    align-items: center;
    display: inline-flex;
    justify-content: center;
  }

  &-empty,
  &-img,
  &-svg {
    display: inline-block;
    height: 1em;
    width: 1em;
  }
}
