@mixin rainbow() {
  @supports (-webkit-text-fill-color: transparent) or (-moz-text-fill-color: transparent) or
    (text-fill-color: transparent) {
    animation: shimmer 3s infinite linear;
    background-clip: text;
    background-image: linear-gradient(
      135deg,
      var(--pink),
      var(--yellow),
      var(--green),
      var(--pink),
      var(--yellow),
      var(--green)
    );
    background-position: 0%;
    background-size: 300% 100%;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;

    @keyframes shimmer {
      0% {
        background-position: 0 0;
      }

      100% {
        background-position: 100% 0;
      }
    }
  }
}
