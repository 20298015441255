/* stylelint-disable no-descending-specificity */

@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

@mixin filter-children() {
  // Filter out elements we don't want to apply a default border/background styling to.
  // i.e. exclude the filled button variant, and inputs with :invalid state
  /* stylelint-disable-next-line selector-not-notation */
  &:is(:not(:global(.Button), :global(.Input_touched):not(:placeholder-shown):invalid)),
  &:is(:global(.Button[class*='_ghost']), :global(.Button[class*='_text']), :global(.Button[class*='_outline'])) {
    @content;
  }
}

.InputGroup {
  box-sizing: border-box;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  height: var(--InputGroup-size);
  position: relative;
  width: 100%;

  > * {
    margin: 0;
    min-width: 0;
    z-index: 1;

    // Apply default input styling to filtered children
    @include filter-children {
      background-color: var(--color-input-background);
      border: var(--border-width) solid var(--color-input-border);

      &:is(input, button, select) {
        &:hover {
          border-color: var(--color-input-border-hover);
        }

        &:active,
        &:focus,
        &:focus-within {
          border-color: var(--color-input-border-active);
          box-shadow: 0 0 0 3px var(--color-input-border-focus);
          outline: none;
        }
      }
    }

    // So borders of filled Button borders don't get overlapped by anything else
    // (unless it's focused/active/invalid)
    &:global(.Select),
    &:global(.Button) {
      z-index: 2;
    }

    &:hover {
      z-index: 3;
    }

    // Elements in focused/active state should appear above everything else
    &:active,
    &:focus {
      z-index: 5;
    }

    &:not(:first-child) {
      margin-left: calc(var(--border-width) * -1);
    }

    &:not(:first-child):not(:last-child) {
      border-radius: 0 !important;
    }

    &:first-child {
      border-radius: var(--border-radius) 0 0 var(--border-radius) !important;
    }

    &:last-child {
      border-radius: 0 var(--border-radius) var(--border-radius) 0 !important;
    }

    &:only-child {
      border-radius: var(--border-radius) !important;
    }

    &:global(.Input) {
      --Input-disabled-bg: var(--color-input-background);
      --Input-disabled-color: #{rgba($gray60, 0.4)};

      // So inputs with error states don't get overlapped by anything else
      // (unless it's focused/active)
      &:invalid {
        z-index: 4;
      }
    }
  }

  &_no-separators > * {
    // Remove borders on all elements, but allow borders focus/active
    // states for unfiltered children to be used
    @include filter-children {
      &,
      &:is(:not(:focus):not(:active)):hover {
        background-color: transparent;
        border: var(--border-width) solid transparent;
      }
    }
  }

  // Drop in a fall-back background/border for elements that have
  // transparent backgrounds &/or borders. This is a pseudo element
  // positioned behind the child elements in order for the height to
  // stay the same between the seperator & non-seperator variants
  &::before {
    background-color: var(--color-input-background);
    border-radius: var(--border-radius);
    box-sizing: border-box;
    content: '';
    display: block;
    height: var(--InputGroup-size);
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
  }

  &_no-separators::before {
    border: var(--border-width) solid var(--color-input-border);
  }
}
