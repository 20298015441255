@import '@core/styles/vars';

.Callout {
  border-left-width: 3.5px;
  font-size: 15px;
  padding: 20px !important;

  // all child elements
  > * {
    margin-left: 20px !important;
  }

  &-heading {
    color: var(--title) !important;
    font-size: var(--markdown-title-size, 18.75px);
    line-height: var(--markdown-line-height, 1.25);

    // enforce header styling
    > :not(.Callout-icon) {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
    }

    p {
      margin: 0 !important;
      padding: 0;
    }
  }

  &-icon {
    float: left;
    font-size: 18px;
    margin-left: -18px;

    &-menu {
      backdrop-filter: blur(10px);
      background: rgba(white, 0.9);
      border: 1px solid rgba(black, 0.1);
      border-radius: var(--border-radius);
      box-shadow: 0 5px 10px rgba(black, 0.05), 0 2px 6px rgba(black, 0.025), 0 1px 3px rgba(black, 0.025);
      display: flex;
      flex-direction: row;
      padding: 3px;

      .icon-smile {
        color: var(--gray50);
        font-weight: bold;
      }

      &-divider {
        border-left: 1px solid var(--gray50);
        height: 22px;
        margin: 5px 3px;
      }

      &-item {
        align-items: center;
        background-color: transparent;
        border: 0;
        border-radius: var(--border-radius);
        color: var(--graphite, #4f5a66);
        cursor: pointer;
        display: inline-flex;
        font-size: 18px;
        height: 25px;
        justify-content: center;
        margin: 3px;
        padding: 10px;
        width: 25px;

        &.selected {
          margin: 0;
        }

        &:hover,
        &:focus {
          background-color: var(--ivory);
        }

        &:active {
          background: var(--chalk);
        }

        &:focus {
          box-shadow: 0 0 0 2px rgba(147, 158, 174, 0.25);
          outline: none;
        }
      }
    }
  }

  strong {
    color: inherit;
  }

  &_blank_title h3 p::before {
    color: var(--graphite, #4f5a66);
    content: 'Enter an optional title';
    opacity: 0.5;
    position: absolute;
  }

  &_blank_body > p:nth-child(2)::before {
    color: var(--graphite, #4f5a66);
    content: 'Enter an optional body';
    opacity: 0.5;
    position: absolute;
  }

  &-EmojiPicker {
    background-color: white;
    max-height: 325px;
    overflow-y: auto;
    padding: 5px;
    width: 325px;

    &-Toggle {
      background-color: unset;
      border: 0;
      cursor: pointer;
    }
  }
}
