@import '@core/styles/vars.compat';
@import '@core/styles/mixins/dark-mode';

.Settings {
  /* extra specificity to override dash styles */
  &-input[type='file'] {
    display: none;
  }

  &-Button {
    width: 100%;
  }

  &-Menu {
    --SettingsMenu-background: var(--gray100);

    @include dark-mode {
      --SettingsMenu-background: var(--gray15);
    }

    background-color: var(--SettingsMenu-background);

    &:global(.Menu) {
      max-width: fit-content;
      min-width: 250px;
    }
  }

  &-Validation {
    color: var(--red);
    font-size: 12px;
    padding: var(--sm) 0;
  }

  &-URLUpload,
  &-AltTextInput {
    width: inherit;
  }

  &-Disclaimer {
    color: var(--color-text-minimum);
    font-size: 12px;
  }

  &-Error {
    color: var(--red);
    font-size: 12px;
  }
}

.Image {
  &Toolbar {
    border-radius: var(--border-radius);

    &-Divider {
      border-left: 1px solid var(--color-border-default);
      margin: auto 4px;
      max-height: var(--icon-md);
    }

    &-ImageBorder {
      align-items: center;
      display: flex;

      > *:first-child {
        padding-left: var(--sm);
      }

      > *:last-child {
        margin: 0;
        padding: 0 var(--xs);
      }
    }

    &Menu {
      --ImageMenu-background: var(--gray90);

      @include dark-mode {
        --ImageMenu-background: var(--gray20);
      }

      background-color: var(--ImageMenu-background);

      &:global(.Menu) {
        align-items: center;
        color: var(--color-text-muted);
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-weight: var(--font-weight);
        justify-content: center;
        max-width: 350px;
        min-width: auto;
        padding: var(--xs);
        white-space: nowrap;

        > * {
          height: 30px;
        }
      }
    }
  }

  &Upload {
    --ImageUpload-background: var(--gray90);

    @include dark-mode {
      --ImageUpload-background: var(--gray20);
    }

    background-color: var(--ImageUpload-background);
  }
}
