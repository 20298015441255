@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

@mixin collapseChildContent {
  > :first-child {
    margin-top: 0 !important;
  }

  > :last-child {
    margin-bottom: 0 !important;
  }
}

@mixin focusTrap($props...) {
  // Notes:
  // This focus trap works by using :focus-within to detect when focus leaves the modal
  // with a transitionend event listener. We add a very short transition to transition-origin
  // (which shouldn’t be noticeable to the user) to trigger that event and focus() on the
  // modal when that happens.
  transition:
    opacity var(--transition-slow, 0.3s) var(--transition-timing, ease-in-out),
    transform var(--transition-slow, 0.3s) var(--transition-timing, ease-in-out),
    transform-origin 0.01s;

  &:not(:focus-within) {
    transform-origin: 49%;
  }
}

// Variables
.Modal {
  --Modal-bg: var(--color-bg-page);
  --Modal-bg-secondary: var(--gray100);
  --Modal-border: var(--color-border-default);
  --Modal-color: var(--color-text-default);
  --Modal-shadow: inset 0 1px 1px #{rgba(white, 0.5)}, 0 5px 10px #{rgba(black, 0.05)}, 0 2px 6px #{rgba(black, 0.025)},
    0 1px 3px #{rgba(black, 0.025)};

  @include dark-mode {
    --Modal-bg-secondary: var(--gray10);
    --Modal-shadow: 0 5px 10px #{rgba(black, 0.1)}, 0 2px 6px #{rgba(black, 0.1)}, 0 1px 3px #{rgba(black, 0.05)},
      inset 0 -1px 1px #{rgba(black, 0.1)};
  }

  @at-root :global(#{&}Wrapper) {
    --Modal-backdrop: #{rgba(black, 0.1)};

    @include dark-mode {
      --Modal-backdrop: #{rgba(black, 0.3)};
    }
  }
}

// Styles
.Modal {
  $root: #{&}; // root class reference
  $is-closed: ':not(#{$root}_open)'; // @todo: revert a bunch of hacky shit in this file after UI migration is complete

  & {
    @include collapseChildContent;
    @include focusTrap;

    background-color: var(--Modal-bg);
    border-radius: var(--border-radius-lg, 7.5px);
    box-shadow:
      var(--Modal-shadow),
      0 0 0 1px var(--Modal-border);
    box-sizing: border-box;
    color: var(--Modal-color);
    display: flex;
    flex-direction: column;
    margin: auto;
    max-height: 100%;
    opacity: 1;
    overflow: hidden;
    transform: none;
    transform-origin: 50%;
    z-index: $z-modal;

    #{$is-closed} > & {
      opacity: 0;
      pointer-events: none;
      transform: scale(0.98);
    }

    &:focus {
      outline: 0;
    }
  }

  &_sm,
  &_md,
  &_lg,
  &_xl {
    width: 100%;
  }

  &_md,
  &_lg,
  &_xl {
    .Modal-Body,
    .Modal-Footer {
      background: var(--Modal-bg-secondary);
    }

    .Modal-Footer {
      padding: var(--md);
    }
  }

  &_sm {
    max-width: 320px;

    @media (max-width: 360px) {
      margin: 20px;
      max-height: calc(100vh - 40px);
      width: calc(100vw - 40px);
    }

    .Modal-Footer {
      padding: var(--sm);

      :global(.Button) {
        flex: 1 1 50%;
      }
    }
  }

  &_md {
    max-width: 480px;
  }

  &_lg {
    max-width: 680px;
  }

  &_xl {
    max-width: 900px;
  }

  &_md,
  &_lg,
  &_xl {
    @media (max-width: 520px) {
      margin: 20px;
      max-height: calc(100vh - 40px);
      width: calc(100vw - 40px);
    }
  }

  &-Header,
  &-Body {
    padding: var(--md);
  }

  &-Header,
  &-Footer {
    flex: 0 0 auto;
  }

  &-Header {
    border-bottom: 1px solid var(--Modal-border);

    // reset title
    :global(.Title) {
      margin-top: 0;
    }
  }

  &-Body {
    flex: 1 1 auto;
    overflow-y: auto;
  }

  &-Footer {
    border-top: 1px solid var(--Modal-border);
  }

  &-Header + &-Footer {
    border-top: none;
  }

  &-FocusLock {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
  }

  @at-root :global(#{&}Wrapper) {
    backdrop-filter: blur(var(--xs));
    background: var(--Modal-backdrop);
    box-sizing: border-box;
    height: 100vh;
    left: 0;
    opacity: 1;
    overflow: hidden auto;
    padding: var(--md);
    position: fixed;
    top: 0;
    transition: opacity var(--transition-slow) var(--transition-timing);
    width: 100vw;
    z-index: 10003; // toolbar is 10002, banners are 10001

    &#{$is-closed} {
      opacity: 0;
      pointer-events: none;
    }

    &#{$root}_open {
      opacity: 1 !important;
      pointer-events: auto !important;
    }
  }

  @at-root :global(#{&}Wrapper_noDismiss:active) .Modal:not(:active) {
    animation: shake 0.15s 2 var(--transition-timing);

    @keyframes shake {
      0% {
        transform: translateX(2px);
      }

      50% {
        transform: translateX(-2px);
      }

      100% {
        transform: none;
      }
    }
  }

  @at-root :global(#{&}Wrapper_verticalCenter) {
    align-items: center;
    display: flex;
  }

  @at-root :global(#{&}Wrapper_priority) {
    z-index: $z-modal + 1;
  }
}
