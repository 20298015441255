@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

// Vars
.Toggle {
  --Toggle-background: var(--white);
  --Toggle-display-background: var(--gray70);
  --Toggle-border: var(--color-input-border);
  --Toggle-border-hover: var(--color-input-border-hover);
  --Toggle-border-active: var(--color-input-border-active);
  --Toggle-border-focus: var(--color-input-border-focus);
  --Toggle-color: var(--color-text-default);
  --Toggle-disabled-bg: var(--gray80);
  --Toggle-checked-color: var(--blue);

  // Keeping this for backwards compatibility
  --toggle-checked-color: var(--Toggle-checked-color);

  @include dark-mode {
    --Toggle-background: var(--gray20);
    --Toggle-display-background: var(--Toggle-background);
    --Toggle-disabled-bg: var(--gray30);

    color-scheme: light dark;
  }
}

// Styles
.ToggleWrapper {
  align-items: center;
  display: flex;
}

.Toggle {
  $b: &;
  $size: 20px;
  $edgeWidth: 1px;
  $edge: $edgeWidth solid var(--Toggle-border);

  align-items: center;
  column-gap: var(--sm);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  margin: 0;
  position: relative;
  user-select: none;

  &:hover .Toggle-display {
    border-color: var(--Toggle-border-hover);
  }

  &_sm .Toggle-display {
    $size: 16px;
  }

  &-input {
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + .Toggle-display,
    #{$b}_checkbox &:indeterminate + .Toggle-display {
      background: var(--Toggle-checked-color);
      border-color: var(--Toggle-checked-color);
    }

    &:active,
    &:focus {
      + .Toggle-display {
        border-color: var(--Toggle-border-active);
        box-shadow: 0 0 0 3px var(--Toggle-border-focus);
      }

      &:checked + .Toggle-display {
        border-color: var(--Toggle-checked-color);
        box-shadow: 0 0 0 3px var(--Toggle-border-focus);
      }
    }

    &:disabled + .Toggle-display,
    &:disabled:active + .Toggle-display {
      background: var(--Toggle-disabled-bg);
      border-color: transparent;
      box-shadow: none;
      cursor: not-allowed;
    }

    &:disabled ~ .Toggle-label-container {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:invalid + .Toggle-display {
      border-color: $red;
    }

    &:invalid:active + .Toggle-display,
    &:invalid:focus + .Toggle-display {
      border-color: $red;
      box-shadow: 0 0 0 3px rgba($red, 0.4);
    }

    &:invalid:checked + .Toggle-display {
      background: red;
    }
  }

  &-display {
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
    transition: box-shadow var(--transition-slow) var(--transition-timing);

    .Toggle-display-icon {
      color: #fff;
      font-size: 12px;
      grid-area: 1 / 1 / 2 / 2;
      opacity: 0;
      pointer-events: none;
      transform: scale(0.5);
      transition:
        opacity var(--transition-slow) var(--transition-timing),
        transform var(--transition-slow) var(--transition-timing);
    }
  }

  &-label-container {
    width: 100%;
  }

  &-label {
    align-items: center;
    color: var(--Toggle-color);
    display: flex;
    flex: 1 1 auto;
    font-weight: var(--font-weight);

    a {
      color: var(--blue);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    &_muted {
      color: var(--color-text-muted);

      strong {
        color: var(--Toggle-color);
      }
    }
  }

  &_description {
    display: inline-grid;
    grid-template-columns: auto 1fr;
    row-gap: 2px;
  }

  &-description {
    color: var(--color-text-muted);
    font-size: 12px;
    grid-column: 2;
    line-height: 1.4;
  }

  &_checkbox .Toggle-display,
  &_radio .Toggle-display {
    background: var(--Toggle-background);
    border: $edge;
    height: $size;
    width: $size;

    &:active {
      border-color: $blue;
    }
  }

  &_toggle,
  &_radio {
    .Toggle-display {
      &::after {
        background-color: white;
        border: 0;
        content: '';
        display: block;
        transition: transform var(--transition-slow) var(--transition-timing);
      }

      &-icon {
        display: none;
      }
    }
  }

  &_checkbox {
    .Toggle-display {
      border-radius: var(--border-radius);

      &-icon {
        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
      }
    }

    .Toggle-input:checked + .Toggle-display .icon-check-heavy,
    .Toggle-input:indeterminate + .Toggle-display .icon-minus-heavy {
      opacity: 1;
      transform: scale(1);
      will-change: transform;
    }

    .Toggle-label_sm {
      font-size: 14px;
    }
  }

  &_toggle {
    $edgeWidth: 2px;

    // toggle only
    $toggleOffset: 5px;
    $toggleSm: 14px;
    $toggleSmOffset: 2px; // gets us to 20px to match checkbox + radio size

    .Toggle-display {
      @include dark-mode {
        border: $edge;
      }

      background: var(--Toggle-display-background);
      border: 1px solid transparent;
      display: inline-flex;
      justify-content: flex-start;
      padding: $edgeWidth;

      &::after {
        border-radius: $size;
        box-shadow:
          0 1px 3px rgba(black, 0.15),
          0 2px 5px rgba(black, 0.1);
      }
    }

    // States
    .Toggle-input {
      // unchecked focus
      &:not(:checked):focus + .Toggle-display {
        box-shadow: 0 0 0 3px rgba(black, 0.1);
      }

      // unchecked invalid focus
      &:not(:checked):invalid:focus + .Toggle-display {
        box-shadow: 0 0 0 3px rgba($red, 0.4);
      }

      // disabled state
      &:disabled + .Toggle-display {
        @include dark-mode {
          opacity: 0.5;
        }

        background: var(--Toggle-disabled-bg);
        cursor: not-allowed;
      }

      // checked and disabled
      &:disabled:checked + .Toggle-display {
        background: var(--Toggle-checked-color);
        opacity: 0.5;
      }

      // invalid state
      &:invalid + .Toggle-display {
        background: $red;
      }

      // checked states need extra specificificity in dark mode
      &:checked + .Toggle-display {
        @include dark-mode {
          background: var(--Toggle-checked-color);
          border-color: var(--Toggle-checked-color);
        }
      }

      .Toggle-display {
        @include dark-mode {
          border: transparent;
        }
      }
    }

    &.Toggle_red-green {
      --Toggle-display-background: var(--red);
      --Toggle-checked-color: var(--green);
      --Toggle-border: transparent;
      --Toggle-border-hover: transparent;
      --Toggle-border-active: transparent;
      --Toggle-border-focus: transparent;

      .Toggle-input {
        // checked focus
        &:focus + .Toggle-display {
          box-shadow: 0 0 0 3px rgba(var(--green-rgb), 0.4);
        }

        // unchecked focus
        &:not(:checked):focus + .Toggle-display {
          box-shadow: 0 0 0 3px rgba(var(--red-rgb), 0.4);
        }
      }
    }

    &_lg {
      .Toggle-display {
        border-radius: $size;
        width: $size * 2 + $toggleOffset;

        &::after {
          border-radius: $size;
          height: $size;
          width: $size;
        }
      }

      .Toggle-input:checked + .Toggle-display::after {
        transform: translateX($size + $toggleOffset - ($edgeWidth * 3));
      }
    }

    &_md,
    &_sm {
      .Toggle-display {
        border-radius: $toggleSm;
        width: $toggleSm * 2 + $toggleSmOffset;

        &::after {
          border-radius: $toggleSm - $toggleSmOffset;
          height: $toggleSm - $toggleSmOffset;
          width: $toggleSm - $toggleSmOffset;
        }
      }

      .Toggle-input:checked + .Toggle-display::after {
        transform: translateX($toggleSm * 2 + $toggleSmOffset - $toggleSm - $toggleSmOffset - $edgeWidth);
      }
    }
  }

  &_radio {
    .Toggle-input:checked + .Toggle-display::after {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    }

    .Toggle-input:disabled:not(:checked) + .Toggle-display::after {
      background: var(--Toggle-disabled-bg);
    }

    .Toggle-display {
      border-radius: $size;

      &::after {
        border-radius: $size * 0.5;
        height: $size * 0.5;
        width: $size * 0.5;
      }
    }
  }

  &_reverse {
    flex-direction: row-reverse;
  }
}
