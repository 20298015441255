.ButtonGroup {
  align-items: center;
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius);
  display: flex;
  justify-content: center;
  vertical-align: middle;
  width: fit-content;


  [class*='Button'] {
    border-radius: 0;
    margin: -1px;
    position: relative;
    z-index: 0;

    &:hover,
    &:focus,
    &:active {
      z-index: 1 !important;
    }

    &:not(:last-child) {
      margin: -1px 0 -1px -1px;
    }

    &:first-child {
      border-radius: var(--border-radius);
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    &:last-child {
      border-radius: var(--border-radius);
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }

  &_dropdown {
    [class*='Dropdown'] {
      margin: -1px 0 -1px -1px;

      // Dropdown button requires special handling separate from other buttons
      [class*='Button'] {
        border-radius: var(--border-radius);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        margin: auto;
        position: relative;
        z-index: 0;
      }
    }
  }
}
