@import '@core/styles/vars.compat';
@import '@core/styles/mixins/dark-mode';

.Settings {
  &-Button {
    width: 100%;
  }

  &-Header {
    gap: unset;
  }

  &-Label {
    padding: $sm 0;
  }

  &-Menu {
    &:global(.Menu) {
      max-width: fit-content;
      min-width: 250px;
    }
  }

  &-Validation {
    color: var(--red);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
  }

  &-Column {
    flex-direction: column;
  }
}

.Embed {
  &Toolbar {
    border: 1px solid rgba(black, 0.1);
    border-radius: var(--border-radius);

    &-Divider {
      border-left: 1px solid var(--color-border-default);
      margin: auto 4px;
      max-height: var(--icon-md);
    }
  }

  &-toggle {
    --Embed-toggle-background: var(--white);

    @include dark-mode {
      --Embed-toggle-background: var(--gray20);
    }

    &,
    &:hover {
      background-color: var(--Embed-toggle-background);
    }
  }
}

.Embed-Input {
  width: inherit;
}
