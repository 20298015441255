@import '~@core/styles/vars';

.HTTPStatus {
  --HTTPStatus-font: var(--font-family-mono);

  align-items: center;
  display: inline-flex;
  font-family: var(--HTTPStatus-font);
  padding: 5px 3px;

  &#{&} {
    &_5 .HTTPStatus-chit,
    &_4 .HTTPStatus-chit {
      background: $red;
    }

    &_3 .HTTPStatus-chit {
      background: $yellow;
    }

    &_2 .HTTPStatus-chit {
      background: $green;
    }

    &_1 .HTTPStatus-chit {
      background: $light-gray;
    }
  }

  &-chit {
    background: $light-gray;
    border: 1px solid rgba(black, 0.15);
    border-radius: $sm;
    box-shadow: var(--box-shadow-status);
    height: $sm;
    width: $sm;
  }

  &-status {
    margin-left: 0.3em;
  }
}
