@import '~@core/styles/vars';

.InfoPop {
  &-tooltip {
    text-transform: none;
  }

  &-helpIcon {
    color: var(--color-text-minimum, var(--minimum-gray));
    cursor: help;
    font-size: 12px;
    margin-left: $xs;

    + :global(.InputGroup-badge) {
      margin-left: $xs;
    }
  }
}
