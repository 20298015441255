@import '@core/styles/vars';

.AnnouncementBanner {
  display: flex;
  gap: 2rem;

  @media (max-width: $ipad-mini-portrait) {
    height: 50%;
    width: 100%;
  }

  @media (max-width: 600px) {
    height: 25%;
    width: 100%;
  }

  &-sidebar {
    animation: brighten-background 5s infinite;
    background: linear-gradient(
      -180deg,
      black 0%,
      black 60%,
      rgba(var(--purple30-rgb), .75) 100%
    );
    background-color: black;
    display: flex;
    flex-basis: 300px;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    min-width: 300px;
    position: relative;

    @media (max-width: $ipad-mini-portrait) {
      flex-basis: 100%;
      min-width: unset;
      width: 100%;
    }
  }

  &-sidebar-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;

    @media (max-width: $ipad-mini-portrait) {
      padding: var(--sm);
    }

    @media (max-width: 600px) {
      justify-content: flex-start;
    }
  }

  &-sidebar-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: center;

    @media (max-width: $ipad-mini-portrait) {
      bottom: 5em;
      flex-direction: row;
      height: 200px;
      position: relative;

    }
  }

  &-component {
    backdrop-filter: blur(5px);
    background: rgba(132,112,190, .35);
    border-radius: var(--sm);
    color: var(--purple90);
    display: flex;
    flex-direction: column;
    height: fit-content;
    min-width: 220px;
    z-index: 1;

    &-image {
      height: 86%;

    }

    &-section {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
  
    &-row {
      align-items: center;
      display: flex;
      flex-direction: row;
      font-size: 14px;
      gap: 4px;
      margin: 0;

      &-spacing {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }

    &-header {
      color: var(--purple60);
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }

    &-subheader {
      font-size: 11px;
      margin: 0;
    }

    &-number {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
    }

    @media (max-width: $ipad-mini-portrait) {
      left: 10em;
      position: relative;
    }

    @media (max-width: 600px) {
      display: none;
      height: 25%;
      width: 100%;
    }
  }

  &-code {
    align-items: flex-start;
    color: var(--gray60);
    display: flex;
    flex-direction: column;
    font-family: var(--font-family-mono);
    font-size: 13px;
    left: -2px;
    margin-bottom: 55px;
    mask-image: linear-gradient(to bottom, 
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, .6) 40%,
      rgba(0, 0, 0, .2) 100%
    );
    overflow: hidden;
    position: absolute;
    
    div {
      line-height: 1.5;
      padding-left: var(--sm);
      white-space: nowrap;
    }

    &-line {
      opacity: .5;
      padding-left: var(--sm);
      position: relative;
      
      &::before {
        background: rgba(255, 255, 255, 0.2);
        bottom: 0;
        content: '';
        display: block;
        height: 100%;
        left: 16px;
        position: absolute;
        top: 0;
        width: 2px;
      }
    }

    &-first {
      &::before {
        display: none;
        opacity: .5;
      }
    }

    &-keyword {
      color: var(--green70);
    }

    &-variable {
      color: rgba(223, 136, 213);
    }

    &-styles {
      color: var(--green-60);
    }

    &-divs {
      color: var(--blue60);
    }

    &-highlight {
      background: rgba(255, 255, 255, 0.2);
      color: var(--white);
      opacity: 1;
      
      &-cursor {
        animation: blink 1s step-end infinite;
        display: inline-block;
      }

      @keyframes blink {
        0%, 100% {
          opacity: 1;
        }

        50% {
          opacity: 0;
        }
      }
    }

    @media (max-width: $ipad-mini-portrait) {
      bottom: 1em;
      height: 95%;
      margin: 0;
      position: relative;
      width: 500px;
    }

    @media (max-width: 600px) {
      display: none;
      height: 25%;
      width: 100%;
    }
  }

  &-sidebar-date {
    align-items: left;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 20px;
    position: absolute;

    @media (max-width: $ipad-mini-portrait) {
      bottom: 20px;
      flex-direction: column;
    }
  }

  &-sidebar-title {
    animation: gradient-slide 10s linear infinite;
    background: linear-gradient(
      90deg,
      rgba(var(--purple70-rgb), 0.8),
      rgba(var(--purple80-rgb), 0.82) 35%,
      rgba(var(--purple90-rgb), 0.85) 43%,
      rgba(var(--purple100-rgb), 1) 50%,
      rgba(var(--purple90-rgb), 0.85) 57%,
      rgba(var(--purple80-rgb), 0.82) 65%,
      rgba(var(--purple70-rgb), 0.8)
    );
    background-clip: text;
    background-size: 300% 100%;
    color: transparent;
    filter: saturate(1.25);
    font-family: var(--font-family-mono);
    font-size: 20px;
    font-weight: 800;
    line-height: 28px;
    margin-bottom: var(--sm);
    position: relative;
  }

  @keyframes gradient-slide {
    0% {
      background-position: -100% 0;
    }

    100% {
      background-position: 200% 0;
    }
  }

  &-sidebar-body {
    animation: gradient-slide 10s linear infinite;
    background: linear-gradient(
      90deg,
      rgba(var(--purple70-rgb), 0.8),
      rgba(var(--purple80-rgb), 0.82) 35%,
      rgba(var(--purple90-rgb), 0.85) 43%,
      rgba(var(--purple100-rgb), 0.9) 50%,
      rgba(var(--purple90-rgb), 0.85) 57%,
      rgba(var(--purple80-rgb), 0.82) 65%,
      rgba(var(--purple70-rgb), 0.8)
    );
    background-clip: text;
    background-size: 300% 100%;
    color: transparent;
    filter: saturate(1.25);
    font-family: var(--font-family-mono);
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    margin: 0;
    padding-bottom: 15px;
  }

  &-sidebar-feature {
    animation: gitSlide 1s cubic-bezier(0.16, 1, 0.3, 1);
    min-height: 1.5em;
    will-change: transform, opacity;
  }

  &-sidebar-icon {
    margin-right: 0.5rem;
  }

  &-sidebar-group {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  &-sidebar-button {
    align-items: center;
    background: rgba(255, 255, 255, 0.10);
    border: 1px solid var(--Border, rgba(255, 255, 255, 0.10));
    border-radius: var(--md);
    color: var(--purple100);
    display: flex;
    flex-direction: row;
    font-size: 13px;
    gap: 3px;
    padding: 3px 8px;
    text-decoration: none;
    width: fit-content;
  }

  a:hover {
    color: var(--purple80);
  }

  &-sidebar-secondary {
    align-items: center;
    color: var(--purple100);
    display: flex;
    flex-direction: row;
    font-size: 13px;
    gap: 3px;
    height: 18px;
    padding: 3px 0;
    text-decoration: none;
    width: fit-content;
    
    &:hover {
      text-decoration: underline;
    }
  }
}

@property --angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}

@property --glowColor {
  inherits: false;
  initial-value: var(--purple);
  syntax: "<color>";
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

.syncing-text {
  color: var(--text-color-minimum);
  opacity: 0.5;
}

@property --title-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: "<angle>";
}

@property --title-glow {
  inherits: false;
  initial-value: var(--purple70);
  syntax: "<color>";
}

@keyframes brighten-background {
  0%, 100% {
    filter: saturate(1);
  }

  50% {
    filter: saturate(.5);
  }
}

@property --angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}

