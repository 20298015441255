@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Select {
  --Select-background: var(--color-input-background);
  --Select-border: var(--color-input-border);
  --Select-border-hover: var(--color-input-border-hover);
  --Select-border-active: var(--color-input-border-active);
  --Select-border-focus: var(--color-input-border-focus);
  --Select-chevron: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.625 5L8.60711 1.25L4.58923 5' stroke='rgba(0,0,0,.25)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.58923 10L8.60711 13.75L12.625 10' stroke='rgba(0,0,0,.25)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  --Select-color: var(--color-input-text);
  --Select-disabled-bg: var(--lightGray);
  --Select-disabled-color: var(--minimumGray);

  @include dark-mode {
    --Select-chevron: url("data:image/svg+xml,%3Csvg width='17' height='15' viewBox='0 0 17 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.625 5L8.60711 1.25L4.58923 5' stroke='rgba(255,255,255,.25)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.58923 10L8.60711 13.75L12.625 10' stroke='rgba(255,255,255,.25)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    --Select-disabled-bg: var(--gray30);
    --Select-disabled-color: var(--gray60);
  }

  /* autoprefixer doesn’t seem to catching this one */
  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--Select-background);
  background-image: var(--Select-chevron); /* this is chevron-up-down */
  background-position: calc(100% - 5px) 50%;
  background-repeat: no-repeat;
  border: var(--border-width) solid var(--Select-border);
  border-radius: var(--border-radius);
  color: var(--Select-color);
  color-scheme: light dark;
  cursor: pointer;
  font-family: inherit;
  font-weight: var(--font-weight-normal);
  justify-content: center;
  padding-right: 30px;
  transition: box-shadow var(--transition-slow) var(--transition-timing);

  /* this fixes a bug in Windows Chrome where the options and optgroups would appear as different colors */
  option,
  optgroup {
    color: inherit;

    &:disabled {
      color: var(--Select-disabled-color);
    }
  }

  &_transparent {
    background-color: transparent;
    border-color: transparent;
  }

  &:disabled {
    background-color: var(--Select-disabled-bg);
    color: var(--Select-disabled-color);
    cursor: not-allowed;
  }

  &:hover {
    border-color: var(--Select-border-hover);
  }

  &:active,
  &:focus {
    border-color: var(--Select-border-active);
    box-shadow: 0 0 0 3px var(--Select-border-focus);
    outline: none;
  }

  &_invalid,
  &:invalid {
    border-color: $red;

    &:hover,
    &:active,
    &:focus {
      border-color: $red;
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($red, 0.4);
    }
  }

  &_xs {
    font-size: 13px;
    height: calc(var(--button-xs) + var(--xs)); 
    padding-left: $xs;
  }

  &_sm {
    font-size: 14px;
    height: var(--button-sm);
    padding-left: $sm;
  }

  &_md {
    font-size: 16px;
    height: var(--button-md);
    padding-left: $sm;
  }

  &_lg {
    font-size: 18px;
    height: var(--button-lg);
    padding-left: $sm;
  }

  &[multiple],
  &_multiple {
    background-image: none;
    height: auto;
    padding: $xs 0;

    option {
      color: var(--color-input-text);

      &:checked {
        background: rgba(black, 0.05);

        /* stylelint-disable-next-line order/order */
        @include dark-mode {
          background: rgba(white, 0.15);
        }
      }
    }

    &.Select_sm option {
      padding: $xs $sm;
    }

    &.Select_md option {
      padding: $xs 15px;
    }

    &.Select_lg option {
      padding: $xs $md;
    }
  }
}
