@import '@core/styles/vars';

.ToggleTable {
  :global(.ModalWrapper) & {
    border-radius: var(--border-radius);
    box-shadow: 0 0 0 1px rgba(black, 0.1); // rounded border
    overflow: hidden;
  }

  thead {
    color: var(--minimumGray, var(--minimum-gray));

    tr {
      border-bottom: 0;
    }

    th {
      font-size: 10px;
      font-weight: var(--font-weight-normal) !important;
      padding: $xs;
    }
  }

  th,
  td {
    padding: 0;
  }

  &-header {
    color: var(--gray20);
    font-size: 14px;
    font-weight: var(--font-weight);
    padding: $sm;
  }

  &-toggle {
    :global(.Toggle) {
      margin: auto;
    }
  }
}
