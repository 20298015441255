@import '~@core/styles/vars.compat';

.MenuDropdown {
  border: none;
  border-radius: var(--sm);
  margin: var(--xs) 0;
  padding: 0;
  position: fixed;
  transition: opacity var(--transition-slow) var(--transition-timing);
  visibility: visible;
  z-index: var(--z-dropdown);

  &-delay {
    transition: opacity var(--transition-slow) var(--transition-timing);
  }

  &-closed {
    opacity: 0;
    pointer-events: none;
    transition:
      visibility 0s var(--transition-slow),
      opacity var(--transition-slow) var(--transition-timing);
    visibility: hidden;
  }

  &-Header {
    border-bottom: 1px solid var(--color-border-default);
    gap: var(--xs);
    margin-bottom: var(--xs);
    padding: var(--xs) var(--sm) var(--sm);
  }

  &-Footer {
    border-top: 1px solid var(--color-border-default);
    margin-top: var(--sm);

    i {
      flex: inherit;
      margin: var(--xs) 8px var(--xs) 0;
      width: auto;
    }
  }
}
