/* stylelint-disable no-descending-specificity */
@import '~@core/styles/vars.compat';

.ColorPicker {
  $root: #{&};

  appearance: none;
  border: 0;
  display: inline-flex;
  height: 100%;
  padding: 0;
  position: relative;

  &_lg #{$root}-swatch {
    height: 40px;
    width: 40px;
  }

  &_md #{$root}-swatch {
    height: 30px;
    width: 30px;
  }

  &_sm #{$root}-swatch {
    height: 20px;
    width: 20px;
  }

  &-button {
    justify-content: flex-start;
    padding: 4px;
  }

  &-swatch {
    border-radius: 3px;
    margin-right: ms(-4);
    overflow: hidden;

    &-circle {
      border-radius: 50%;

      // include 1px buffer to prevent clipping
      height: calc(1em + 1px) !important;
      margin: auto ms(-4) auto ms(-8);
      width: 1em !important;
    }
  }

  &-input {
    cursor: pointer;
  }

  &-value {
    align-items: center;
    display: flex;
    font-weight: var(--font-weight-normal);
    padding-right: ms(-4);

    &-placeholder {
      color: var(--gray60);
    }
  }
}
