@import '~@core/styles/vars.compat';

.Profile {
  align-items: center;
  color: var(--gray20);
  display: flex;
  font-size: 14px;
  font-weight: var(--font-weight);
  min-height: 50px;

  &:first-child {
    padding-left: $sm;
  }

  &-avatar {
    min-width: 30px;
  }

  &-content {
    overflow-wrap: anywhere;
    padding-left: $sm;
  }

  &-title {
    align-items: center;
    display: flex;
  }

  &-title-supplement {
    padding-left: $xs;
  }

  &-small {
    align-items: center;
    color: var(--gray40);
    display: flex;
    font-size: 12px;
    font-weight: var(--font-weight-normal);
  }
}
