@import '~@core/styles/vars.compat';

.DashEditor {
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  &-Notify {
    max-width: 550px;
    padding: $xs 0 $xs $md;
  }
}

.Raw {
  resize: none;

  &_Container {
    display: grid;
    margin-bottom: var(--sm);

    &::after {
      content: attr(data-value) ' ';
      visibility: hidden;
    }
  }
}

.Raw,
.Raw_Container::after {
  background-color: var(--color-input-background);
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  color: var(--markdown-text);
  font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter', 'DejaVu Sans Mono',
    'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;
  grid-area: 1 / 1 / 2 / 2;
  line-height: 1.5em;
  max-width: 1000px;
  outline: 0 none;
  overflow: hidden;
  padding: 20px;
  position: relative;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.HTMLMode_Snippet {
  :global(.CodeMirror) {
    max-width: 1000px;
  }

  :global(.CodeSnippet .CodeMirror-gutters) {
    background-color: var(--color-bg-page);
  }
}

[ng-class] .HTMLMode_Snippet {
  :global(.CodeMirror-gutters) {
    background-color: #f5f5f5;
  }
}

.HTMLMode_Hint {
  color: var(--color-text-minimum);
  font-weight: var(--font-weight-normal);
}

.ErrorModal {
  &__Container {
    height: 100vh;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100vw;
  }

  &__Header {
    margin-bottom: auto;
  }

  &__Icon {
    color: var(--red);
  }
}
