@import '~@core/styles/vars.compat';

.Log {
  font-size: 15px;
  margin-top: 4px;
  padding: 5px 10px;

  &-Status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20px;
    min-width: 20px;

    &-Icon {
      border-radius: 9px;
      height: 9px;
      width: 9px;

      &_changelog {
        align-items: center;
        background: get(color 'blue');
        border-radius: 6px;
        box-sizing: border-box;
        color: white;
        display: flex;
        font-size: 9px;
        height: 20px;
        justify-content: center;
        padding-top: 2px;
        position: relative;
        right: 4px;
        top: 5px;
        width: 20px;
      }

      &_deployed {
        background: get(color 'shale');
        border-radius: 6px;
        box-sizing: border-box;
        color: white;
        display: flex;
        font-size: 13px;
        height: 20px;
        padding: 4px;
        position: relative;
        right: 4px;
        top: 5px;
        width: 20px;
      }

      &_create {
        background: get(color 'green');
      }

      &_delete {
        background: get(color 'red');
      }

      &_edit {
        background: get(color 'yellow');
      }
    }
  }

  &-Info {
    margin-left: 7px;
  }

  &-Label {
    font-weight: 600;
    text-transform: capitalize;
  }

  &-Link,
  &-Text {
    color: currentcolor;
    margin-left: 5px;
  }

  &-Link {
    text-decoration: none;

    &-ExternalIcon {
      margin-left: 5px;
      opacity: 0.5;
      transform: rotate(45deg);
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: underline;
    }
  }

  &-Details {
    align-items: center;
    color: get(color 'graphite');
    display: flex;
    font-size: 12px;
    margin-top: 3px;
  }

  &_Owlet {
    &-Type {
      align-items: center;
      color: get(color 'shale');
      display: inline-flex;

      &-Icon {
        fill: currentcolor;
        height: 13px;
        margin-right: 5px;
        opacity: 0.5;
        width: 13px;
      }
    }
  }
}
