.DashHeader {
  background: var(--color-input-background);
  border-bottom: 1px solid var(--color-input-border);
  position: sticky;
  top: 0;
  z-index: 999;

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(10px);
    background: rgba(white, 0.9);
  }

  &-Inner {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 15px 0;
    width: 100%;
    min-height: 59px;
  }

  &-Title {
    display: flex;
    margin-top: 0;

    &-Icon {
      align-items: center;
      color: var(--color-text-minimum-icon);
      display: inline-flex;
      margin-right: 10px;
    }
  }

  &-Description {
    color: var(--color-text-minimum);
    font-size: 13px;
    margin: 0 0 0 34px;
  }

  &-Children {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: var(--sm);
    justify-content: flex-end;
  }

  &_transparent {
    background: transparent;
    border-bottom: 0;
    position: static;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      backdrop-filter: none;
      background: transparent;
    }
  }

  &_transparent &-Inner {
    border-bottom: 1px solid var(--color-input-border);
  }
}
