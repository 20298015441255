@import '~@core/styles/vars';

.Feed {
  box-sizing: border-box;
  height: 100%;
  max-height: stretch;
  overflow: hidden auto;
  position: relative;
  width: 100%;

  &_loading {
    overflow: hidden;
  }

  &-title {
    color: var(--slate);
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    line-height: $button-sm;
    margin: 0;
  }

  &-dropdown {
    margin-right: -8px;
  }

  &-main {
    display: grid;
    gap: $sm;
    grid-template-rows: auto 1fr;
    min-height: calc(100% - 40px);
    padding: $sm 15px;
  }

  &-content,
  &-content_loading {
    min-height: 0;
  }

  &-content_loading {
    align-items: center;
    color: var(--minimumGray, var(--minimum-gray));
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
  }

  &-content &-UserEvent {
    padding-bottom: 1em;
  }

  &-footer {
    background: rgba(white, 0.975);
    bottom: 0;
    box-shadow: 0 -1px 0 rgba(black, 0.1);
    left: 0;
    padding: $sm;
    position: sticky;
    right: 0;
    text-align: right;

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      backdrop-filter: blur(10px);
      background: rgba(white, 0.75);
    }
  }

  &-link {
    background: transparent;
    border: none;
    color: var(--blue);
    font-size: 14px;
    font-weight: var(--font-weight);
    text-decoration: none;

    &:hover,
    &:active {
      color: var(--blue40);
    }

    &:focus {
      outline: 0;
      text-decoration: underline;
    }
  }

  &-UserEvent {
    max-width: calc(100% - 30px);
    width: 100%;

    &-avatar {
      flex: 0 0 auto;
    }

    &-tooltip {
      align-items: center;
      color: var(--gray80);
      display: flex;
      flex-direction: column;
      font-weight: var(--font-weight);

      strong {
        color: white;
      }
    }
  }

  &-Comment {
    &_link {
      border-radius: 15px;
      transition: box-shadow var(--transition-slow) var(--transition-timing);

      &:focus {
        box-shadow:
          0 0 0 1px $blue,
          0 0 0 4px rgba($blue, 0.25);
        outline: 0;
      }
    }

    &-avatar {
      margin-bottom: -$xs;
    }

    &-external {
      margin-left: $xs;
      opacity: 0.5;
    }
  }

  &-EmptyState {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    min-height: 100px;

    &-icon {
      color: var(--blue);
      font-size: $icon-lg;
      margin-bottom: $sm;
    }

    &-title {
      color: var(--gray20);
      font-size: 16px;
      font-weight: var(--font-weight-bold);
      margin: 0;
    }

    &-description {
      color: var(--minimumGray, var(--minimum-gray));
      font-size: 14px;
      margin: $xs 0 $sm;
      max-width: 30ch;
      text-align: center;
    }
  }
}
