@import '~@core/styles/vars.compat';

.CodeSettings {
  display: flex;
  flex-direction: column;

  &-Languages {
    border-bottom: 1px solid rgba(black, 0.05);
    border-top: 1px solid rgba(black, 0.05);
    margin-bottom: var(--xs);
    margin-top: var(--xs);
    max-height: calc(20px * 8);
    max-width: initial;
    overflow-y: auto;
    padding-bottom: var(--xs);
    padding-top: var(--xs);
  }
}
