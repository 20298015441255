.LinkEditor {
  &-link {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.Menu-Item) {
    [class*='icon-'] {
      margin-right: 0;
    }
  }
}
