@import '~@core/styles/vars.compat';

.EmojiMenu {
  margin-left: $xs;
  margin-top: 0.5em;
  padding: 0;

  &-Container {
    border-radius: 6px !important;
    padding: 0 !important;
  }

  &-Menu {
    border: 0;
    box-shadow: none;

    &:global(.Menu) {
      max-height: calc(30px * 5);
      overflow: hidden auto;
      padding: 0;
      width: 300px;
    }
  }
}

.EmojiMenuItem {
  padding: 0 $xs !important;
  user-select: none;

  &-Container {
    display: flex;

    &-emoji {
      font-style: normal;
      height: 20px;
      margin: $xs;
      width: 20px;
    }

    &-name {
      margin: $xs;
      overflow: hidden;
      padding-right: $xs;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    background: rgba($blue, 0.15) !important;
  }
}
