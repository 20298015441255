.Decoration {
  color: var(--gray80);

  &_Link {
    color: var(--blue80);
  }
}

.EditorMenu {
  &-list {
    $menu-item-height: 27px;

    max-height: calc(#{$menu-item-height} * 8.5);
    overflow-y: auto;

    &:first-child {
      margin-top: calc(var(--xs) * -1);
      padding-top: var(--xs);
    }

    &:last-child {
      margin-bottom: calc(var(--xs) * -1);
      padding-bottom: var(--xs);
    }
  }

  &-label {
    color: var(--color-text-muted);
    font-weight: var(--font-weight); /* override dash styles */
  }
}
