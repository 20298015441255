@import '~@core/styles/mixins/dark-mode';

.CodeSnippet {
  margin: 0.5em 0;

  .CodeEditor .CodeMirror {
    background: inherit;
    box-sizing: border-box;
    color: #bebcd7;
    font-family: var(--font-family-mono);
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    font-weight: var(--font-weight-normal);
    height: auto;
    width: 100%;

    .CodeMirror-linenumber,
    .cm-lineNumber {
      color: #bebcd7;
      min-width: 30px; /* enough for 9999 line numbers */
      padding-left: 0;
    }

    .cm-lineNumber {
      padding-right: 1em;
      position: relative;
    }

    .cm-linerow {
      text-indent: 0;
    }

    .cm-attribute {
      color: #d7b2f0;
    }
  }

  .rm-PlaygroundResponse & {
    .CodeEditor .CodeMirror {
      background: transparent;
      color: var(--color-text-muted);
      line-height: 1.4375; /* match neo theme */
      overflow: hidden;
    }

    .CodeMirror-gutters {
      background: var(--APIResponse-bg);
      border-right: 10px solid transparent; /* match neo theme */
    }
  }

  .CodeMirror-hscrollbar {
    @include dark-mode {
      color-scheme: dark;
    }
  }

  .CodeMirror-scrollbar-filler {
    background: transparent;
  }
}
