@import '~@core/styles/vars';

.SAMLSetupForm {
  &-label {
    margin-bottom: $sm;
  }

  &-instructions {
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 20px;
    grid-template-columns: max-content 300px;
    margin: 0 auto;
    width: fit-content;

    ul {
      padding-inline-start: $md;
    }

    > p {
      text-align: right;
    }
  }

  &-helper {
    color: var(--minimumGray);
    font-size: 12px;
    font-weight: var(--font-weight-normal);
  }

  &-container {
    background: var(--white);
    border: 1px solid rgba(black, 0.1);
    border-radius: var(--border-radius-lg);

    &_empty {
      background: transparent;
      color: var(--gray60);
      font-size: 14px;
      font-weight: var(--font-weight);
      padding: $md;
      text-align: center;
    }
  }

  &-textarea {
    height: 200px;
  }

  &-input,
  &-textarea {
    width: 100%;
  }
}
