@import '@core/styles/vars';

.PipVideo {
  bottom: var(--sm);
  position: fixed;
  right: var(--sm);
  z-index: var(--z-modal);

  &-video {
    background: black;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-menu-dark);
    overflow: hidden;
  }

  &-close {
    backdrop-filter: blur(var(--md));
    position: absolute;
    right: 0;
    top: -1 * $button-xs - $xs;

    &:global(.Button) {
      background: rgba(var(--color-bg-page-rgb), 0.5);
    }
  }
}
