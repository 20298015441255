@import '~@core/styles/vars.compat';

.Code {
  font-size: 90%;
  white-space: pre-wrap;
}

:global(.markdown-body) {
  .MdxExpression {
    background-color: inherit;
    padding: initial;
    white-space: pre-wrap;
  }

  .MdxJsxTextElement {
    background-color: inherit;
    padding: initial;
    white-space: pre-wrap;
  }

  // styles inline code to match JsxFlowBlock code styling
  code.MdxJsxTextElement {
    color: #9c3328;
    font-size: 12px;
  }
}
