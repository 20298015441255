@import '@core/styles/vars';

.DateLine {
  align-items: center;
  color: var(--color-text-minimum);
  display: flex;

  i {
    font-size: var(--icon-md);
    line-height: initial;
    margin-right: $xs;
    opacity: 0.5;

    > .fa_right::before {
      margin-right: 0;
    }
  }
}
