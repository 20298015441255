@import '@core/styles/vars.compat';
@import '@core/styles/mixins/dark-mode';

.Embed {
  &_Toolbar {
    position: relative;
  }

  &-Empty {
    align-items: center;
    background-color: var(--gray100);
    color: var(--color-text-minimum);
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: var(--xs);
    transition: box-shadow var(--transition-slow) var(--transition-timing);
    width: 100%;

    &:hover {
      box-shadow:
        0 1px 2px #{rgba(black, 0.05)},
        0 2px 5px #{rgba(black, 0.02)};

      /* same as Box card */
    }

    &_message {
      display: flex;
      font-weight: 500;

      i {
        font-size: 18px;
        margin-left: $sm;
      }
    }
  }

  &-Empty-Youtube {
    --EmbedEmptyYoutube-background: var(--gray100);

    @include dark-mode {
      --EmbedEmptyYoutube-background: var(--gray20);
    }

    /* 16:9 aspect ratio */
    aspect-ratio: 16/9;
    background-color: var(--EmbedEmptyYoutube-background);
    color: var(--color-text-minimum);
    cursor: pointer;
    display: flex;
    max-width: 854px;
    padding: var(--sm);
    position: relative;
    transition: box-shadow var(--transition-slow) var(--transition-timing);
    width: 100%;

    &:hover {
      box-shadow:
        0 1px 2px #{rgba(black, 0.05)},
        0 2px 5px #{rgba(black, 0.02)};

      /* same as Box card */
    }

    &_icon {
      inset: 33% 0 0;
      position: absolute;
      width: 30%;
    }
  }
}
