.PageNavItemVersionStatusIcon {
  color: var(--gray60);
  flex: 0 0 auto;
  font-size: var(--icon-md);
  margin-right: var(--xs);
  pointer-events: none;

  &_created {
    color: var(--green);
  }

  &_modified {
    color: var(--yellow);
  }

  &_deleted {
    color: var(--red);
  }
}
