@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.CopyToClipboard {
  @include dark-mode {
    &:not(:disabled):hover,
    &:not(:disabled):focus-visible {
      color: var(--color-text-minimum-hover);
    }
  }

  border: 0;
  color: var(--color-text-default);

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    border: 0;
    color: var(--color-text-minimum-hover);
  }
}
