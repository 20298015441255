.ErrorState {
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  max-width: 320px;
  padding: var(--lg) 0 var(--xl);
  width: 100%;

  &-title {
    color: var(--color-text-default);
    font-size: 16px;
    font-weight: var(--font-weight-bold);
    margin: 0 0 var(--sm);
    text-align: center;
  }

  &-message {
    color: var(--color-text-muted);
    font-size: 14px;
    font-weight: var(--font-weight-normal);
    line-height: 1.4;
    margin: 0;
    text-align: center;

    a:not(:global(.Button)) {
      color: currentcolor;
      text-decoration: underline;

      &:hover,
      &:focus,
      &:active {
        text-decoration: none;
      }
    }
  }

  &-graphic + .ErrorState-title {
    margin-top: var(--md);
  }

  &-graphic + .ErrorState-message {
    margin-top: var(--sm);
  }
}
