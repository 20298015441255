@import '~@core/styles/vars';

.PageMenu {
  &-Container {
    border-radius: 6px !important;
    padding: 0 !important;
  }

  &-Menu {
    border: 0;
    box-shadow: none;

    &:global(.Menu) {
      overflow-x: hidden;
      overflow-y: auto;
      padding: 0;
      width: 285px;
    }
  }
}

.PageMenuItem {
  padding: 0 var(--xs) !important;
  user-select: none;

  &-Container {
    display: flex;
    justify-content: space-between;

    &-name {
      margin: var(--xs);
      overflow: hidden;
      padding-right: var(--sm);
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 130px;
    }

    &-category {
      color: var(--gray50);
      font-weight: 600;
      margin: var(--xs);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover {
    background: rgba(var(--blue-rgb), 0.15) !important;
  }
}
