@import '@core/styles/vars.compat';
@import '@core/styles/mixins/dark-mode';

.Image {
  display: block !important;

  &-Caption {
    color: var(--gray60);
    text-align: center;
  }
}

:global(.markdown-body img).Image_inline {
  display: inline-block !important;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}

.ImagePlaceholderButton {
  --ImagePlaceholderButton-background: var(--white);

  @include dark-mode {
    --ImagePlaceholderButton-background: var(--gray20);
  }

  &,
  &:hover {
    background-color: var(--EmptyBlock-settings-button-background);
  }
}
