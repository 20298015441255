@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.InputGroup {
  --APIAuthInputGroup-bg: var(--gray100);
  --APIAuthInputGroup-button-color: var(--color-text-muted);

  @include dark-mode {
    --APIAuthInputGroup-bg: var(--gray15);
  }

  background: var(--APIAuthInputGroup-bg);
  border: var(--border-width) solid var(--color-border-default);
  border-radius: var(--border-radius);
  display: flex;
  transition: box-shadow var(--transition-fast) var(--transition-timing);

  &:focus-within {
    background: var(--color-input-background);
    border-color: var(--color-input-border-active);
    box-shadow: 0 0 0 3px var(--color-input-border-focus);
  }

  &-button,
  &-input {
    align-items: center;
    background: none;
    border: 0;
    display: inline-flex;
    flex: 0 0 auto;
    font-size: 14px;
    height: var(--lg);
  }

  &-button {
    color: var(--APIAuthInputGroup-button-color);
    cursor: pointer;
    font-size: 12px;
    font-weight: var(--font-weight-bold);
    justify-content: center;
    min-width: 40px;
    padding: 0 $sm;

    &:focus {
      outline: 0;
    }

    &:hover {
      border-color: var(--color-input-border-hover);
    }

    &_active,
    &:active,
    &:focus {
      color: var(--color-input-border-active);
    }

    &:first-child {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
      border-right: 1px solid var(--color-border-default);
    }

    &:disabled {
      background: none;
      cursor: default;

      &:hover,
      &:active,
      &:focus {
        background: none;
      }
    }

    &:last-child:not(:first-child) {
      border-left: 1px solid var(--color-border-default);
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }

    .icon {
      height: 15px;
      margin-left: 5px;
      width: 15px;

      &:only-child {
        margin: 0;
      }
    }
  }

  &-input {
    color: var(--color-input-text);
    flex: 1 1 auto;
    font-family: SFMono-Regular, monospace;
    font-size: 12px;
    font-weight: var(--font-weight-normal);
    padding: 0 $sm;

    &[readonly] {
      text-overflow: ellipsis;
    }

    &::placeholder {
      color: var(--shale);
    }

    &:focus {
      outline: 0;
    }

    &:first-child {
      border-radius: var(--border-radius) 0 0 var(--border-radius);
    }

    &:last-child {
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }
  }
}
