@import '@core/styles/vars';

.PageNavMetaControls {
  flex: 0 0 auto;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  right: var(--PageNav-padding, 0);

  &-control {
    align-items: center;
    background: none;
    border: 0;
    color: currentcolor;
    cursor: pointer;
    display: flex;
    padding: 2px;

    &:hover {
      color: var(--blue);
    }
  }

  // The Dropdown is being controlled manually, so it's button is merely an
  // attachment point. So this is positioning the Dropdown over the actual
  // -control button.
  > :global(.Dropdown) {
    position: absolute;
    top: 100%;
  }

  &-menu-control {
    &[name='delete'] {
      --Menu-item: var(--red);
    }

    &_category-target {
      text-transform: uppercase;
    }
  }

  &[hidden] {
    visibility: hidden;
  }

  &_visible {
    opacity: 1;
    position: unset;
  }
}
