@import '~@core/styles/vars.compat';

.UrlMenu {
  &_Link {
    max-width: 100%;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
  }
}
