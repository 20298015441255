@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.Badge {
  --Badge-bg: var(--gray85);
  --Badge-text: var(--gray20);

  $block: &;
  $badge-height: 16px;

  align-items: center;
  background: var(--Badge-bg);
  border-radius: var(--border-radius);
  box-sizing: border-box;
  color: var(--Badge-text);
  display: inline-flex;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: var(--font-weight-bold);
  height: $badge-height;
  line-height: 1.3;
  padding-left: 6px;
  padding-right: 6px;
  user-select: none;

  &_light#{&}_ghost {
    --Badge-text: var(--color-text-minimum);

    @include dark-mode {
      --Badge-text: var(--color-text-default);
    }
  }

  &_dark {
    --Badge-bg: var(--gray30);
    --Badge-text: var(--white);
  }

  &_dark#{&}_ghost {
    --Badge-text: var(--color-text-default);

    @include dark-mode {
      --Badge-text: var(--color-text-minimum);
    }
  }

  &_alert {
    --Badge-bg: var(--yellow);
    --Badge-text: var(--yellow10);
  }

  &_alert#{&}_ghost {
    --Badge-text: var(--yellow30);

    @include dark-mode {
      --Badge-text: var(--yellow);
    }
  }

  &_success {
    --Badge-bg: var(--green40);
    --Badge-text: var(--white);
  }

  &_failure {
    --Badge-bg: var(--red);
    --Badge-text: var(--white);
  }

  &_callout {
    --Badge-bg: var(--purple30);
    --Badge-text: var(--white);
  }

  &_info {
    --Badge-bg: var(--blue);
    --Badge-text: var(--white);
  }

  &_ghost {
    --Badge-text: var(--Badge-bg);

    background: transparent;
  }

  &_all-caps {
    font-size: 9px;
    text-transform: uppercase;
  }

  &_circular {
    border-radius: 30px;
  }
}
