@import '~@core/styles/vars';

.ImageUploader {
  min-width: 132px;
  position: relative;

  // Input is "hidden" via attribute and should never be visible to users.
  &-input {
    opacity: 0;
    position: absolute;
    width: 0;
  }

  &-preview {
    background-color: var(--color-bg-page);
    border: 1px solid var(--color-border-default);
    border-radius: var(--border-radius-lg);
    font-size: 14px;
    font-weight: var(--font-weight);
    max-width: 250px;
    padding: 0;
  }

  &-preview-control {
    background: none;
    border: 0;
    color: var(--color-text-default);
    cursor: pointer;
    flex: 1 1 auto;
    font-size: 12px;
    font-weight: var(--font-weight);
    height: 40px;
    min-width: 0;
    padding: 0 var(--xs);

    &:first-child {
      padding-left: var(--sm);
    }

    &:last-child {
      padding-right: var(--sm);
    }

    &:hover {
      color: var(--color-text-minimum-hover);
    }

    &_remove {
      flex: 0 0 auto;
      font-weight: var(--font-weight-bold);
      text-transform: uppercase;
    }
  }

  &-preview-image {
    flex: 0 0;
    max-height: 25px;
    max-width: 70px;
  }

  &-preview-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
