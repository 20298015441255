@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.EmojiPicker {
  --color-active: var(--gray80);

  @include dark-mode {
    --color-active: var(--gray30);
  }

  display: flex;
  flex-wrap: wrap;
  font-weight: 400;

  &-Button {
    background: none;
    border: 0;
    border-radius: 3px;
    color: var(--color-text-minimum);

    // min font-size of 26px to center on Chrome on Retina displays
    // https://bugs.chromium.org/p/chromium/issues/detail?id=551420
    font-size: 26px;
    height: 35px;
    margin: 2px;
    padding: 0;
    transition: box-shadow 0.3s cubic-bezier(0.16, 1, 0.3, 1);
    width: 35px;

    > img {
      max-height: 32px;
      max-width: 32px;
      vertical-align: middle;
    }

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background: var(--color-skeleton);
      cursor: pointer;
    }

    &:not(:disabled):active {
      background: var(--color-active);
    }

    &:not(:disabled):focus {
      box-shadow: 0 0 0 3px rgba(147, 158, 174, 0.25);
      outline: none;
    }

    &_none {
      background: var(--color-skeleton);
      font-family: var(--font-family);
      font-size: 12px;
      font-weight: 600;
      vertical-align: top;

      &:active {
        color: var(--white);
      }
    }

    &_active {
      background: var(--blue);
      color: var(--white);
    }
  }

  &-Results {
    margin-top: var(--sm);
  }

  &-Empty {
    color: var(--color-text-minimum);
    font-size: 12px;
    font-weight: 600;
    line-height: 40px;
    margin-left: var(--xs);
  }
}
