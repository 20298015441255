@import '@core/styles/vars';

.RequestAccessMenu {
  width: fit-content;

  &-button {
    i,
    p {
      font-weight: 700;
    }

    &-text {
      margin: auto;
    }

    &-open-menu {
      background-color: var(--gray60);
    }

    &-num-badge {
      align-items: center;
      background: white;
      border-radius: var(--border-radius);
      color: var(--gray60);
      display: flex;
      font-size: 12px;
      height: 16px;
      justify-content: center;

      p {
        margin: 5px;
      }
    }
  }

  &-invitations {
    max-height: 58rem;
    overflow-y: auto;
    padding: 5px 10px;

    &-profile {
      display: flex;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  &-approvals {
    &-container {
      padding: 0;

      &_overflow {
        overflow: visible;
      }

      &-resolution {
        font-size: 14px;
        font-weight: 700;

        &_denied {
          color: var(--red);
        }

        &_approved {
          color: var(--gray40);
        }

        &-icon {
          font-weight: 700;
          margin-left: 5px;
          vertical-align: middle;
        }
      }
    }

    &-description {
      color: var(--gray40);
      font-size: 12px;
      font-weight: var(--font-weight-normal);
    }

    &-dropdown {
      border-radius: 0 var(--border-radius) var(--border-radius) 0;
    }
  }
}
